import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Chip,
  Divider,
  CircularProgress,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Alert
} from '@mui/material';
import { Phone, Mail, Clock, Calendar, Link as LinkIcon } from 'lucide-react';

interface Personnel {
  _id: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];
  skillLevel: string;
  experience: number;
  profileImage?: string;
  bio?: string;
  availability?: {
    from: string | Date;
    to: string | Date;
  };
  fee: string;
}

interface Performance {
  _id: string;
  simpleBandName?: string;
  band?: string | {
    _id: string;
    artistName: string;
  };
  startTime: string;
  staff: Array<{ staffId: string; assignedRole: string; }>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const PerformanceCard: React.FC<{ performance: Performance; staffId: string }> = ({ performance, staffId }) => {
  const navigate = useNavigate();

  const getBandName = (perf: Performance): string => {
    if (perf.simpleBandName) {
      return perf.simpleBandName;
    }
    if (perf.band) {
      if (typeof perf.band === 'string') {
        return 'Loading...';
      }
      return perf.band.artistName;
    }
    return 'Unknown Artist';
  };

  const getAssignedRole = () => {
    const staffMember = performance.staff.find(s => s.staffId === staffId);
    return staffMember?.assignedRole || 'Role not specified';
  };

  return (
    <Card 
      sx={{ 
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: (theme) => theme.shadows[4]
        },
        height: '100%'
      }}
      onClick={() => navigate(`/admin/performances/${performance._id}`)}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {getBandName(performance)}
        </Typography>
        <Typography color="text.secondary">
          {new Date(performance.startTime).toLocaleDateString()}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Role: {getAssignedRole()}
        </Typography>
      </CardContent>
    </Card>
  );
};

const PersonnelDetail: React.FC<{ id: string }> = ({ id }) => {
  const [person, setPerson] = useState<Personnel | null>(null);
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch personnel details
        const personResponse = await window.fetch(
          `${process.env.REACT_APP_API_URL}/api/personnel/${id}`
        );
        const personData = await personResponse.json();
        setPerson(personData);

        // Fetch performances where this person was staff
        const performancesResponse = await window.fetch(
          `${process.env.REACT_APP_API_URL}/api/performances?staffId=${id}`
        );
        const performancesData = await performancesResponse.json();
        setPerformances(performancesData);
        
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Box className="flex justify-center items-center h-64">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!person) {
    return <Alert severity="info">No personnel found.</Alert>;
  }

  const roleColors: Record<string, string> = {
    'Vision Mix Engineer': 'bg-purple-500',
    'Stage Manager': 'bg-red-400',
    'Front of House': 'bg-yellow-500',
    'Production Manager': 'bg-orange-500',
    'Runner': 'bg-green-500',
    'Staff': 'bg-blue-500',
    'Volunteer': 'bg-teal-500',
    'Stage Hand': 'bg-indigo-500'
  };

  const today = new Date();
  const pastPerformances = performances.filter(p => new Date(p.startTime) < today);
  const upcomingPerformances = performances.filter(p => new Date(p.startTime) >= today);

  return (
    <Box className="max-w-7xl mx-auto p-4">
      <Card className="mb-6">
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} className="flex flex-col items-center">
              <Avatar 
                src={person.profileImage} 
                alt={person.name}
                className="w-64 h-64 mb-4 text-7xl"
              />
              <Typography variant="h4" className="text-center mb-2">
                {person.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" className="text-center mb-4">
                {person.skillLevel} • {person.experience} years
              </Typography>
              <Box className="flex gap-2 mb-4">
                <IconButton href={`tel:${person.phone}`} color="primary">
                  <Phone />
                </IconButton>
                <IconButton href={`mailto:${person.email}`} color="primary">
                  <Mail />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box className="mb-6">
                <Typography variant="h6" gutterBottom>
                  Roles & Expertise
                </Typography>
                <Box className="flex flex-wrap gap-2">
                  {person.roles.map((role) => (
                    <Chip
                      key={role}
                      label={role}
                      className={`${roleColors[role] || 'bg-gray-500'} text-white`}
                    />
                  ))}
                </Box>
              </Box>

              {person.bio && (
                <Box className="mb-6">
                  <Typography variant="h6" gutterBottom>
                    Bio
                  </Typography>
                  <Typography variant="body1">
                    {person.bio}
                  </Typography>
                </Box>
              )}

              {person.availability && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Availability
                  </Typography>
                  <Box className="flex items-center gap-2">
                    <Calendar className="w-5 h-5" />
                    <Typography>
                      {new Date(person.availability.from).toLocaleDateString()} 
                      {' '}-{' '}
                      {new Date(person.availability.to).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Paper>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
          <Tab label={`Performance History (${pastPerformances.length})`} />
          <Tab label={`Upcoming Events (${upcomingPerformances.length})`} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          {pastPerformances.length > 0 ? (
            <Grid container spacing={3}>
              {pastPerformances.map((performance) => (
                <Grid item xs={12} md={6} key={performance._id}>
                  <PerformanceCard 
                    performance={performance}
                    staffId={id}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography color="text.secondary" className="text-center py-8">
              No past performances found
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {upcomingPerformances.length > 0 ? (
            <Grid container spacing={3}>
              {upcomingPerformances.map((performance) => (
                <Grid item xs={12} md={6} key={performance._id}>
                  <PerformanceCard 
                    performance={performance}
                    staffId={id}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography color="text.secondary" className="text-center py-8">
              No upcoming events scheduled
            </Typography>
          )}
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default PersonnelDetail;