import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Chip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/axiosConfig';
import LocationFilter from '../components/LocationFilter';
import type { LocationKey } from '../components/LocationFilter'; // Add this export to LocationFilter


interface NavigatorApplication {
  _id: string;
  name: string;
  email: string;
  instrument: string[];
  status: 'pending' | 'approved' | 'rejected';
  createdAt: string;
  locationDates: string[];
}

type Order = 'asc' | 'desc';

const LOCATIONS = {
  BALLINA: 'Ballina Arts Centre - June-August 2025',
  SOURCE: 'The Source Arts Centre, Thurles - May-June 2025',
  GRIANAN: 'An Grianán Theatre, Letterkenny - June-July 2025',
  COURTHOUSE: 'Courthouse Arts, Tinahely - TBC 2025',
  GARTER: 'Garter Lane, Waterford - TBC 2025',
  RIVERBANK: 'Riverbank Arts Centre, Kildare. - TBC 2025',
  ANY: 'Any of the above! - See Date Options Listed above'
};

export default function NavigatorApplicationList() {
  const [applications, setApplications] = useState<NavigatorApplication[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof NavigatorApplication>('name');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LocationKey | 'all'>('all');


  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      const response = await axiosInstance.get<NavigatorApplication[]>('/api/navigator');
      setApplications(response.data);
    } catch (err: any) {
      setError(err.message || 'An error occurred while fetching applications.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (property: keyof NavigatorApplication) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleStatusChange = async (applicationId: string, newStatus: string) => {
    try {
      await axiosInstance.put(`/api/navigator/${applicationId}/status`, {
        status: newStatus
      });
      setApplications(applications.map(app => 
        app._id === applicationId ? { ...app, status: newStatus as 'pending' | 'approved' | 'rejected' } : app
      ));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeleteClick = (applicationId: string) => {
    setApplicationToDelete(applicationId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (applicationToDelete) {
      try {
        await axiosInstance.delete(`/api/navigator/${applicationToDelete}`);
        setApplications(applications.filter(app => app._id !== applicationToDelete));
      } catch (error) {
        console.error('Error deleting application:', error);
      }
    }
    setDeleteDialogOpen(false);
    setApplicationToDelete(null);
  };

  const filterApplications = (apps: NavigatorApplication[]) => {
    return apps.filter(app => {
      // Status filter
      const statusMatch = selectedStatus === 'all' || app.status === selectedStatus;
      
      // Location filter
      const locationMatch = (selectedLocation as LocationKey | 'all') === 'all' || app.locationDates.some(loc => {
        if (selectedLocation !== 'all' && loc === LOCATIONS[selectedLocation as LocationKey]) {
          return true;
        }
        return loc === LOCATIONS.ANY;
      });
  
      return statusMatch && locationMatch;
    });
  };

  const handleLocationFilter = (location: LocationKey | 'all') => {
    setSelectedLocation(location);
  };

  const sortApplications = (a: NavigatorApplication, b: NavigatorApplication) => {
    if (orderBy === 'locationDates') {
      const aValue = a.locationDates.length;
      const bValue = b.locationDates.length;
      return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
    } else {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const filteredApplications = filterApplications(applications);
  const sortedApplications = filteredApplications.sort(sortApplications);

  return (
    <div>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>Navigator Applications</Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
          <LocationFilter 
            applications={applications}
            onFilterChange={handleLocationFilter}
          />
          </FormControl>

          <FormControl sx={{ minWidth: 200 }}>
            <TextField
              select
              label="Filter by Status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <MenuItem value="all">All Statuses</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </TextField>
          </FormControl>
        </Box>

        <Typography variant="body2" color="textSecondary">
          Showing {sortedApplications.length} of {applications.length} applications
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Instrument(s)</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? order : 'asc'}
                  onClick={() => handleRequestSort('createdAt')}
                >
                  Submitted On
                </TableSortLabel>
              </TableCell>
              <TableCell>Location Preferences</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedApplications.map((application) => (
              <TableRow key={application._id}>
                <TableCell>{application.name}</TableCell>
                <TableCell>{application.email}</TableCell>
                <TableCell>{application.instrument.join(', ')}</TableCell>
                <TableCell>
                  <Select
                    value={application.status}
                    onChange={(e) => handleStatusChange(application._id, e.target.value)}
                    size="small"
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>{new Date(application.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  {application.locationDates.includes(LOCATIONS.ANY) ? (
                    <Chip label="Any Location" color="primary" />
                  ) : (
                    application.locationDates.map((range, index) => (
                      <Chip 
                        key={index} 
                        label={range.split(' - ')[0]}
                        sx={{ m: 0.5 }}
                      />
                    ))
                  )}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      component={Link}
                      to={`/navigator-applications/${application._id}`}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      View
                    </Button>
                    <IconButton
                      onClick={() => handleDeleteClick(application._id)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this application? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}