import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Stack,
  Autocomplete,
  Tooltip
} from '@mui/material';
import { Plus, User, Calendar } from 'lucide-react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface QuickSubtaskAddProps {
  onAdd: (subtask: {
    title: string;
    assignedTo?: string;
    dueDate?: string;
  }) => void;
  personnel: Array<{ id: string; name: string }>;
}

const QuickSubtaskAdd: React.FC<QuickSubtaskAddProps> = ({ onAdd, personnel }) => {
  const [title, setTitle] = useState('');
  const [assignedTo, setAssignedTo] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [showOptional, setShowOptional] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim()) return;

    onAdd({
      title: title.trim(),
      assignedTo: assignedTo || undefined,
      dueDate: dueDate?.toISOString(),
    });

    // Reset form
    setTitle('');
    setAssignedTo(null);
    setDueDate(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            placeholder="Add a subtask..."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ 
              flex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                },
              },
            }}
          />
          
          <Tooltip title="Show/Hide Optional Fields">
            <IconButton 
              size="small"
              onClick={() => setShowOptional(!showOptional)}
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              {showOptional ? <Calendar size={18} /> : <User size={18} />}
            </IconButton>
          </Tooltip>

          <IconButton 
            type="submit" 
            disabled={!title.trim()}
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            <Plus size={18} />
          </IconButton>
        </Stack>

        {showOptional && (
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Autocomplete
              size="small"
              options={personnel}
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => setAssignedTo(newValue?.id || null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Assign to..."
                  size="small"
                  sx={{ minWidth: 200 }}
                />
              )}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
            />
            
            <DateTimePicker
              value={dueDate}
              onChange={(newValue) => setDueDate(newValue)}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }
                }
              }}
            />
          </Stack>
        )}
      </form>
    </LocalizationProvider>
  );
};

export default QuickSubtaskAdd;