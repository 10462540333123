// src/pages/contracts/index.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  Chip,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  ContentCopy as DuplicateIcon,
  MoreVert as MoreIcon
} from '@mui/icons-material';
import { contractServices } from '../../services/contractServices';
import axiosInstance from '../../utils/axiosConfig';

interface Contract {
    _id: string;
    id: string;
    artistName: string;
    contractNumber: string;
    contractPath?: string;
    documentUrl?: string;
    eventDate: Date;
    venue: string;
    address?: string;
    email?: string;
    phone?: string;
    status: 'draft' | 'pending_signature' | 'signed' | 'cancelled';
    createdAt: Date;
    contractData?: {
      [key: string]: any;
    };
  }

const ContractsPage = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedContract, setSelectedContract] = useState<string | null>(null);

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/api/contracts');
      
      console.log('Raw contract data:', response.data); // Debug log
  
      const transformedContracts = response.data.map((contract: any) => {
        console.log('Contract data:', contract.contractData); // Debug individual contract data
        
        return {
          id: contract._id,
          _id: contract._id,
          artistName: contract.artistName || 'Unknown Artist',
          contractNumber: contract.contractNumber,
          contractPath: contract.documentUrl || contract.contractPath || '',
          eventDate: new Date(contract.eventDate),
          venue: contract.venue || 'Unknown Venue',
          status: contract.status || 'draft',
          createdAt: new Date(contract.createdAt),
          contractData: {
            ...contract.contractData,
            Fees: contract.contractData?.Fees || contract.fees,
            Activities_Master: contract.contractData?.['Activities Master'] || contract.contractData?.Activities_Master
          }
        };
      });
  
      console.log('Transformed contracts:', transformedContracts); // Debug transformed data
      setContracts(transformedContracts);
    } catch (error) {
      setError('Failed to load contracts');
      console.error('Error fetching contracts:', error);
    } finally {
      setLoading(false);
    }
  };

  const STATUS_DISPLAY = {
    draft: 'Draft',
    pending_signature: 'Pending Signature',
    signed: 'Signed',
    cancelled: 'Cancelled'
  } as const;

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, contractId: string) => {
    console.log("Opening menu for contract:", contractId); // Debugging

    setAnchorEl(event.currentTarget);
    setSelectedContract(contractId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedContract(null);
  };

  const handleDownload = async (contractId: string) => {
    try {
      const contract = contracts.find(c => c.id === contractId);
      if (!contract) throw new Error('Contract not found');
  
      // Extract timestamp from existing URL if available
      let timestamp;
      if (contract.documentUrl) {
        timestamp = contract.documentUrl.match(/\/(\d+)_/)?.[1];
      }
      
      // Use existing timestamp or create new one
      const fileTimestamp = timestamp || Date.now().toString();
      
      // Construct filename based on actual bucket structure
      const fileName = `${fileTimestamp}_${contract.contractNumber}_${contract.artistName}_contract.docx`;
      const documentUrl = `contracts/${fileName}`;
  
      const blob = await contractServices.downloadContract(documentUrl);
      
      // Use same filename for download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading contract:', error);
      setError('Failed to download contract');
    }
    handleMenuClose();
  };


  const handleEdit = (contractId: string) => {
    if (!contractId) {
      console.error('No contractId provided to handleEdit');
      return;
    }
    
    console.log('Navigating to edit with contractId:', contractId);
    handleMenuClose();
    navigate(`/admin/contracts/edit/${contractId}`);
  };
  
  

  const handleDelete = async (contractId: string) => {
    if (!window.confirm('Are you sure you want to delete this contract? THIS ACTION CANNOT BE UNDONE!')) {
      return;
    }
  
    try {
      // Extract filename from contractPath
      const filename = contractId.split('/').pop(); // This gets just the filename part
      if (!filename) {
        throw new Error('Invalid contract path');
      }
  
      await axiosInstance.delete(`/api/contracts/${filename}`);
      setContracts(prev => prev.filter(contract => contract.id !== contractId));
      handleMenuClose();
    } catch (error) {
      console.error('Error deleting contract:', error);
      setError('Failed to delete contract');
    }
  };

  const getStatusChipProps = (status: Contract['status']) => {
    const statusConfig = {
      draft: { label: 'Draft', color: 'default' as const },
      pending_signature: { label: 'Pending Signature', color: 'warning' as const },
      signed: { label: 'Signed', color: 'success' as const },
      cancelled: { label: 'Cancelled', color: 'error' as const }
    };
    return statusConfig[status];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <div>
            <Typography variant="h4" gutterBottom>
              Contracts
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Manage artist contracts and agreements
            </Typography>
          </div>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/contracts/new')}
          >
            New Contract
          </Button>
        </Box>

        {/* Contract List */}
        <Grid container spacing={3}>
        {contracts.map((contract) => (
  <Grid item xs={12} key={contract.id}>
    <Card sx={{ 
      bgcolor: 'background.paper',
      borderRadius: 1,
      p: 2,
      '&:hover': { bgcolor: 'action.hover' }
    }}>
      <CardContent>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" gutterBottom>{contract.artistName}</Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {contract.contractNumber}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {contract.contractData?.Activities_Master}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Fee: €{contract.contractData?.Fees || '0'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2" gutterBottom>
                Event Date: {contract.eventDate.toLocaleDateString()}
              </Typography>
              <Typography variant="body2">
                Venue: {contract.venue}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Chip
                  label={STATUS_DISPLAY[contract.status]}
                  color={contract.status === 'draft' ? 'default' : 'primary'}
                  size="small"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2">
                  Created: {contract.createdAt.toLocaleDateString()}
                </Typography>
              </Box>
              <Tooltip title="More options">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    if (!contract.id) return;
                    handleMenuOpen(e, contract.id);
                  }}
                >
                  <MoreIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
))}
        </Grid>

        {/* Action Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
<MenuItem onClick={() => {
  console.log('Selected contract ID:', selectedContract);
  const contract = contracts.find(c => c.id === selectedContract);
  if (!contract || !contract._id) {
    console.error('No valid contract found for ID:', selectedContract);
    return;
  }
  console.log('Found contract for edit:', contract);
  handleEdit(contract._id); // Pass the MongoDB _id
}}>
  <EditIcon sx={{ mr: 1 }} fontSize="small" />
  Edit
</MenuItem>
          <MenuItem onClick={() => {
            handleMenuClose();
            navigate(`/admin/contracts/${selectedContract}/duplicate`);
          }}>
            <DuplicateIcon sx={{ mr: 1 }} fontSize="small" />
            Duplicate
          </MenuItem>
          <MenuItem onClick={() => selectedContract && handleDownload(selectedContract)}>
            <DownloadIcon sx={{ mr: 1 }} fontSize="small" />
            Download
          </MenuItem>
          <MenuItem 
            onClick={() => selectedContract && handleDelete(selectedContract)}
            sx={{ color: 'error.main' }}
          >
            <DeleteIcon sx={{ mr: 1 }} fontSize="small" />
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Container>
  );
};

export default ContractsPage;