import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Typography,
  Chip,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Divider
} from '@mui/material';
import { Clock, ClipboardList, ExternalLink } from 'lucide-react';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosConfig';

interface TaskItem {
  _id: string;
  text: string;
  completed: boolean;
  urgent: boolean;
  created: string;
  assignedTo: string;
  createdBy: string;
  dueDate?: string;
  createdAt: string;
  updatedAt: string;
}

interface TaskListState {
  tasks: TaskItem[];
  loading: boolean;
  error: string | null;
}

interface TaskListProps {
  userId: string;
}

const TaskList: React.FC<TaskListProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<TaskListState>({
    tasks: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axiosInstance.get<TaskItem[]>('/api/tasks', {
          params: {
            assignedTo: userId,
            completed: false
          }
        });

        console.log('Tasks response:', response.data);

        setState(prev => ({
          ...prev,
          tasks: response.data,
          loading: false
        }));
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setState(prev => ({
          ...prev,
          error: 'Failed to load tasks',
          loading: false
        }));
      }
    };

    fetchTasks();
  }, [userId]);

  const handleTaskToggle = async (taskId: string) => {
    try {
      await axiosInstance.patch(`/api/tasks/${taskId}/toggle`);
      
      // Refresh tasks after toggle
      const response = await axiosInstance.get<TaskItem[]>('/api/tasks', {
        params: {
          assignedTo: userId,
          completed: false
        }
      });

      setState(prev => ({
        ...prev,
        tasks: response.data
      }));
    } catch (error) {
      console.error('Error toggling task:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to update task'
      }));
    }
  };

  if (state.loading) return <CircularProgress />;
  if (state.error) return <Alert severity="error">{state.error}</Alert>;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<Clock />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Badge badgeContent={state.tasks.length} color="primary">
            <ClipboardList />
          </Badge>
          <Typography>Your Tasks</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {state.tasks.length === 0 ? (
          <Typography color="text.secondary">No tasks assigned to you</Typography>
        ) : (
          <List dense>
            {state.tasks.map((task) => (
              <ListItem
                key={task._id}
                button
                onClick={() => navigate(`/admin/tasks?task=${task._id}`)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={task.completed}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleTaskToggle(task._id);
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={task.text}
                  secondary={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {task.urgent && (
                        <Chip 
                          size="small" 
                          color="error" 
                          label="Urgent" 
                        />
                      )}
                      {task.dueDate && (
                        <Typography variant="caption">
                          Due: {format(new Date(task.dueDate), 'PP')}
                        </Typography>
                      )}
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/admin/tasks?task=${task._id}`);
                    }}
                  >
                    <ExternalLink size={16} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TaskList;