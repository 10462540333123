import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Divider
} from '@mui/material';
import { Ticket } from 'lucide-react';
import { Venue } from '../../../../types/mongodb';
import axiosInstance from '../../../../utils/axiosConfig';

interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

interface TicketingConfig {
  ticketTypes: TicketType[];
  projections: {
    revenue: {
      gross: number;
      net: number;
      vat: number;
    };
  };
}

interface TicketingSectionProps {
  venue: Venue | null;
  performanceId: string;
  ticketConfig: TicketingConfig | null;
  onConfigureTickets: () => void;
  costSummary: {
    totalWithFee: number;
    contingency: number;
  };
}

export const TicketingSection: React.FC<TicketingSectionProps> = ({
  venue,
  performanceId,
  ticketConfig,
  onConfigureTickets,
  costSummary,
}) => {
  const [loading, setLoading] = useState(false);
  const [existingConfig, setExistingConfig] = useState<TicketingConfig | null>(null);

  // Add type guard
  const hasValidTicketTypes = (config: TicketingConfig | null): config is TicketingConfig => {
    return config !== null && 
           Array.isArray(config.ticketTypes) && 
           config.ticketTypes.length > 0;
  };

  useEffect(() => {
    const fetchTicketConfig = async () => {
      if (!performanceId) return;
      
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/ticket-configs/search`, {
          params: {
            performanceId,
            selectionMethod: 'event'
          }
        });
        
        if (response.data) {
          setExistingConfig(response.data);
        }
      } catch (error) {
        console.error('Error fetching ticket config:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketConfig();
  }, [performanceId]);

  const calculateRevenueProjections = () => {
    if (!venue || !hasValidTicketTypes(existingConfig)) return null;

    const capacity = venue.capacity;
    const totalRevenue = existingConfig.ticketTypes.reduce((sum, type) => 
      sum + (type.quantity * type.price), 0);
    const totalTickets = existingConfig.ticketTypes.reduce((sum, type) => 
      sum + type.quantity, 0);
    const avgTicketPrice = totalTickets > 0 ? totalRevenue / totalTickets : 0;

    const scenarios = {
      worst: Math.floor(capacity * 0.3),
      expected: Math.floor(capacity * 0.6),
      best: Math.floor(capacity * 0.9),
    };

    const totalCosts = costSummary.totalWithFee + costSummary.contingency;

    return {
      capacity,
      scenarios: Object.entries(scenarios).reduce((acc, [key, attendance]) => ({
        ...acc,
        [key]: {
          attendance,
          revenue: attendance * avgTicketPrice,
          profit: (attendance * avgTicketPrice) - totalCosts
        }
      }), {} as Record<string, { attendance: number; revenue: number; profit: number }>),
      breakeven: {
        tickets: avgTicketPrice > 0 ? Math.ceil(totalCosts / avgTicketPrice) : 0,
        percentage: avgTicketPrice > 0 ? Math.ceil((totalCosts / avgTicketPrice) / capacity * 100) : 0
      }
    };
  };

  const calculateRevenueBreakdown = (ticketTypes: TicketType[]) => {
    return ticketTypes.reduce((acc, type) => {
      const revenue = type.quantity * type.price;
      return {
        totalRevenue: acc.totalRevenue + revenue,
        totalTickets: acc.totalTickets + type.quantity,
        breakdown: [
          ...acc.breakdown,
          {
            name: type.name,
            revenue,
            tickets: type.quantity,
            price: type.price
          }
        ]
      };
    }, {
      totalRevenue: 0,
      totalTickets: 0,
      breakdown: [] as Array<{
        name: string;
        revenue: number;
        tickets: number;
        price: number;
      }>
    });
  };
  

  if (!venue) {
    return (
      <Box>
        <Typography variant="h6">Ticketing</Typography>
        <Alert severity="info">Please select a venue first to configure tickets</Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6">Ticketing</Typography>
      <Box>
        <Button 
          variant="contained" 
          onClick={onConfigureTickets}
          startIcon={<Ticket />}
          sx={{ mr: 2 }}
        >
          {hasValidTicketTypes(existingConfig) ? 'Edit Ticket Configuration' : 'Configure Tickets'}
        </Button>

        {hasValidTicketTypes(existingConfig) && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                Current Ticket Configuration
              </Typography>
              
              <Box sx={{ mt: 2 }}>
                {existingConfig.ticketTypes.map((type) => (
                  <Box 
                    key={type.name} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      mb: 1 
                    }}
                  >
                    <Typography variant="body2">
                      {type.name}
                    </Typography>
                    <Typography variant="body2">
                      {type.quantity} tickets @ €{type.price}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Divider sx={{ my: 2 }} />
              
              {(() => {
                const revenue = calculateRevenueBreakdown(existingConfig.ticketTypes);
                return (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Revenue Breakdown
                    </Typography>
                    
                    {revenue.breakdown.map(item => (
                      <Box 
                        key={item.name}
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          mb: 0.5 
                        }}
                      >
                        <Typography variant="body2">
                          {item.name} Revenue
                        </Typography>
                        <Typography variant="body2" color="primary">
                          €{item.revenue.toFixed(2)}
                        </Typography>
                      </Box>
                    ))}
                    
                    <Box sx={{ 
                      mt: 2, 
                      pt: 2, 
                      borderTop: '1px solid', 
                      borderColor: 'divider'
                    }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography fontWeight="bold">
                          Total Tickets:
                        </Typography>
                        <Typography fontWeight="bold">
                          {revenue.totalTickets}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography fontWeight="bold">
                          Total Projected Revenue:
                        </Typography>
                        <Typography fontWeight="bold" color="success.main">
                          €{revenue.totalRevenue.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })()}
            </CardContent>
          </Card>
        )}

        {hasValidTicketTypes(existingConfig) && (
          <Box sx={{ 
            bgcolor: 'background.paper', 
            p: 3, 
            borderRadius: 1,
            mt: 3 
          }}>
            <Typography variant="h6" gutterBottom>Revenue Projections</Typography>
            {(() => {
              const projections = calculateRevenueProjections();
              if (!projections) return null;

              return (
                <>
                  <Typography gutterBottom>
                    Venue Capacity: {projections.capacity} people
                  </Typography>
                  
                  <Grid container spacing={2}>
                    {Object.entries(projections.scenarios).map(([scenario, data]) => (
                      <Grid item xs={12} md={4} key={scenario}>
                        <Box sx={{ 
                          p: 2, 
                          border: 1, 
                          borderColor: 'divider',
                          borderRadius: 1
                        }}>
                          <Typography variant="subtitle1" gutterBottom sx={{ textTransform: 'capitalize' }}>
                            {scenario} Case
                          </Typography>
                          <Typography>Attendance: {data.attendance}</Typography>
                          <Typography>Revenue: €{data.revenue.toFixed(2)}</Typography>
                          <Typography sx={{ 
                            color: data.profit >= 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}>
                            {data.profit >= 0 ? 'Profit' : 'Subvention Required'}: €{Math.abs(data.profit).toFixed(2)}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box sx={{ mt: 3, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Break-even Analysis
                    </Typography>
                    <Typography>
                      Need to sell {projections.breakeven.tickets} tickets ({projections.breakeven.percentage}% capacity)
                    </Typography>
                  </Box>
                </>
              );
            })()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TicketingSection;