import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Grid, 
  Typography, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider
} from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Per Diem
import HotelIcon from '@mui/icons-material/Hotel'; // Accommodation
import BuildIcon from '@mui/icons-material/Build'; // Technical
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Equipment
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // Other
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { MicExternalOn, MicNone, Sell, Tungsten } from '@mui/icons-material';

interface EventCalculatorProps {
  onClose: () => void;
  onSubmit: (calculatedCosts: CalculatedCosts) => void;
  defaultCurrency?: string;
  initialData?: CalculatedCosts;  // Add this
}

interface CalculatedCosts {
  // Travel section
  travel: {
    flights: number;
    groundTransport: number;
    other: number;
    total: number;
  };
  
  // Accommodation section
  accommodation: {
    singleRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    twinRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    total: number;
  };
  
  // Per Diem section
  perDiem: {
    dailyRate: number;
    numberOfDays: number;
    total: number;
  };
  
  // Equipment section
  equipment: {
    rental: number;
    shipping: number;
    backline: number;
    total: number;
  };
  
  // Technical section
  technical: {
    soundEngineer: number;
    lightingEngineer: number;
    backline: number;
    other: number;
    total: number;
  };

  additional: {
    marketing: number;
    insurance: number;
    visas: number;
    total: number;
  };

  otherCosts: Array<{ description: string; amount: number; }>;
  
  // Summary
  summary: {
    totalCosts: number;
    contingency: number;
    grandTotal: number;
  };
  
  currency: string;
}


const currencies = {
  EUR: '€',
  GBP: '£',
  USD: '$'
};

const EventCalculator: React.FC<EventCalculatorProps> = ({ 
  onClose, 
  onSubmit, 
  initialData,
  defaultCurrency = 'EUR'
}) => {
  const [currency, setCurrency] = useState(defaultCurrency);
  const [flights, setFlights] = useState({ 
    costPerPerson: initialData?.travel.flights.toString() || '', 
    quantity: '1'
  });
  
  const [accommodation, setAccommodation] = useState({ 
    singleRooms: { 
      quantity: initialData?.accommodation.singleRooms.quantity.toString() || '0', 
      cost: initialData?.accommodation.singleRooms.costPerRoom.toString() || '' 
    },
    doubleRooms: { quantity: '0', cost: '' },
    twinRooms: { 
      quantity: initialData?.accommodation.twinRooms.quantity.toString() || '0', 
      cost: initialData?.accommodation.twinRooms.costPerRoom.toString() || '' 
    },
    nights: initialData?.accommodation.singleRooms.totalNights.toString() || '1'
  });
  
  const [perDiem, setPerDiem] = useState({ 
    rate: initialData?.perDiem.dailyRate.toString() || '', 
    quantity: '1', 
    days: initialData?.perDiem.numberOfDays.toString() || '1'
  });
  
  const [equipment, setEquipment] = useState({ 
    rental: initialData?.equipment.rental.toString() || '',
    shipping: initialData?.equipment.shipping.toString() || '',
    backline: initialData?.equipment.backline.toString() || '',
    quantity: '1',
    days: '1'
  });

  const [technical, setTechnical] = useState({
    backline: initialData?.technical.backline.toString() || '',
    soundEngineer: initialData?.technical.soundEngineer.toString() || '',
    lightingEngineer: initialData?.technical.lightingEngineer.toString() || '',
    other: initialData?.technical.other.toString() || ''
  });

  const [travel, setTravel] = useState({
    flights: initialData?.travel.flights.toString() || '',
    groundTransport: initialData?.travel.groundTransport.toString() || '',
    other: initialData?.travel.other.toString() || ''
  });

  const [marketing, setMarketing] = useState(
    initialData?.additional.marketing.toString() || ''
  );
  
  const [insurance, setInsurance] = useState(
    initialData?.additional.insurance.toString() || ''
  );
  
  const [visas, setVisas] = useState(
    initialData?.additional.visas.toString() || ''
  );

  const [otherCosts, setOtherCosts] = useState(
    initialData?.otherCosts.length ? 
    initialData.otherCosts.map(cost => ({
      description: cost.description,
      amount: cost.amount.toString()
    })) : 
    [{ description: '', amount: '' }]
  );

  const calculateTravelTotal = () => {
    return calculateFlightsTotal() + 
           parseNumber(travel.groundTransport) + 
           parseNumber(travel.other);
  };
  
  const calculateTotalCosts = () => {
    return calculateTravelTotal() +
           calculateAccommodationTotal() +
           calculatePerDiemTotal() +
           calculateEquipmentTotal() +
           calculateTechnicalTotal() +
           calculateOtherCostsTotal() +
           parseNumber(marketing) +
           parseNumber(insurance) +
           parseNumber(visas);
  };
  
  const calculateContingency = () => {
    const totalCosts = calculateTotalCosts();
    return totalCosts * 0.1; // 10% contingency
  };

  const parseNumber = (value: string) => (value === '' ? 0 : parseFloat(value));

  const calculateFlightsTotal = () => 
    parseNumber(flights.costPerPerson) * parseNumber(flights.quantity);

  const calculateAccommodationTotal = () => {
    const singleTotal = parseNumber(accommodation.singleRooms.cost) * 
                       parseNumber(accommodation.singleRooms.quantity);
    const doubleTotal = parseNumber(accommodation.doubleRooms.cost) * 
                       parseNumber(accommodation.doubleRooms.quantity);
    const twinTotal = parseNumber(accommodation.twinRooms.cost) * 
                     parseNumber(accommodation.twinRooms.quantity);
    return (singleTotal + doubleTotal + twinTotal) * parseNumber(accommodation.nights);
  };

  const calculatePerDiemTotal = () =>
    parseNumber(perDiem.rate) * parseNumber(perDiem.quantity) * parseNumber(perDiem.days);

  const calculateEquipmentTotal = () =>
    parseNumber(equipment.rental) * parseNumber(equipment.quantity) * parseNumber(equipment.days);


  const calculateTechnicalTotal = () =>
    parseNumber(technical.backline) +
    parseNumber(technical.soundEngineer) +
    parseNumber(technical.lightingEngineer) +
    parseNumber(technical.other);

  const calculateOtherCostsTotal = () =>
    otherCosts.reduce((sum, cost) => sum + parseNumber(cost.amount), 0);

  const grandTotal =
    calculateFlightsTotal() +
    calculateAccommodationTotal() +
    calculatePerDiemTotal() +
    calculateEquipmentTotal() +
    calculateTechnicalTotal() +
    calculateOtherCostsTotal();

  const handleAddOtherCost = () => {
    setOtherCosts([...otherCosts, { description: '', amount: '' }]);
  };

  const handleOtherCostChange = (index: number, field: string, value: string) => {
    const updatedOtherCosts = otherCosts.map((cost, i) =>
      i === index ? { ...cost, [field]: value } : cost
    );
    setOtherCosts(updatedOtherCosts);
  };

  const formatCurrency = (amount: number) => {
    const currencySymbol = currencies[currency as keyof typeof currencies];
    return `${currencySymbol}${amount.toFixed(2)}`;
  };

  const renderCostField = (
    label: string,
    value: string,
    onChange: (value: string) => void,
    suffix?: string
  ) => {
    const isFilled = value !== '' && value !== '0' && value !== '1'; // Ignore '0' and '1'
  
    return (
      <TextField
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="number"
        fullWidth
        sx={{
          bgcolor: isFilled ? 'rgba(0, 200, 83, 0.1)' : 'transparent', // Green only for meaningful values
          borderRadius: 1,
          '& .MuiInputBase-root': {
            bgcolor: isFilled ? 'rgba(0, 200, 83, 0.15)' : 'transparent',
          },
        }}
        InputProps={{
          endAdornment: suffix ? (
            <Typography color="textSecondary" variant="body2">
              {suffix}
            </Typography>
          ) : undefined,
        }}
      />
    );
  };

  const handleSubmit = () => {
    const calculatedCosts: CalculatedCosts = {
      travel: {
        flights: calculateFlightsTotal(),
        groundTransport: parseNumber(travel.groundTransport),
        other: parseNumber(travel.other),
        total: calculateTravelTotal()
      },
      accommodation: {
        singleRooms: {
          quantity: parseNumber(accommodation.singleRooms.quantity),
          costPerRoom: parseNumber(accommodation.singleRooms.cost),
          totalNights: parseNumber(accommodation.nights)
        },
        twinRooms: {
          quantity: parseNumber(accommodation.twinRooms.quantity),
          costPerRoom: parseNumber(accommodation.twinRooms.cost),
          totalNights: parseNumber(accommodation.nights)
        },
        total: calculateAccommodationTotal()
      },
      perDiem: {
        dailyRate: parseNumber(perDiem.rate),
        numberOfDays: parseNumber(perDiem.days),
        total: calculatePerDiemTotal()
      },
      equipment: {
        rental: parseNumber(equipment.rental),
        shipping: parseNumber(equipment.shipping),
        backline: parseNumber(equipment.backline),
        total: calculateEquipmentTotal()
      },
      technical: {
        soundEngineer: parseNumber(technical.soundEngineer),
        lightingEngineer: parseNumber(technical.lightingEngineer),
        backline: parseNumber(technical.backline),
        other: parseNumber(technical.other),
        total: calculateTechnicalTotal()
      },
      additional: {
        marketing: parseNumber(marketing),
        insurance: parseNumber(insurance),
        visas: parseNumber(visas),
        total: parseNumber(marketing) + parseNumber(insurance) + parseNumber(visas)
      },
        otherCosts: otherCosts.map(cost => ({
        description: cost.description,
        amount: parseNumber(cost.amount)
      })),
      summary: {
        totalCosts: calculateTotalCosts(),
        contingency: calculateContingency(),
        grandTotal: grandTotal
      },
      currency
    };
  
    onSubmit(calculatedCosts);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Event Calculator</Typography>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              label="Currency"
              onChange={(e) => setCurrency(e.target.value)}
            >
              {Object.keys(currencies).map(curr => (
                <MenuItem key={curr} value={curr}>{curr}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {/* Flights */}
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <Box sx={{ display: 'flex', gap: 0.5 }}>
    <FlightIcon fontSize="small" /> 
  </Box>
  Flights
</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {renderCostField('Cost per Person', flights.costPerPerson, 
                    (value) => setFlights(prev => ({ ...prev, costPerPerson: value })))}
                </Grid>
                <Grid item xs={6}>
                  {renderCostField('Quantity', flights.quantity,
                    (value) => setFlights(prev => ({ ...prev, quantity: value })))}
                </Grid>
              </Grid>
              <Typography sx={{ mt: 1 }}>
                Total: {formatCurrency(calculateFlightsTotal())}
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {/* Accommodation */}
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <HotelIcon fontSize="small" />
  Accommodation
</Typography>              
<Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>Single Rooms</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {renderCostField('Quantity', accommodation.singleRooms.quantity,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          singleRooms: { ...prev.singleRooms, quantity: value }
                        })))}
                    </Grid>
                    <Grid item xs={6}>
                      {renderCostField('Cost', accommodation.singleRooms.cost,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          singleRooms: { ...prev.singleRooms, cost: value }
                        })))}
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>Double Rooms</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {renderCostField('Quantity', accommodation.doubleRooms.quantity,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          doubleRooms: { ...prev.doubleRooms, quantity: value }
                        })))}
                    </Grid>
                    <Grid item xs={6}>
                      {renderCostField('Cost', accommodation.doubleRooms.cost,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          doubleRooms: { ...prev.doubleRooms, cost: value }
                        })))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>Twin Rooms</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {renderCostField('Quantity', accommodation.twinRooms.quantity,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          twinRooms: { ...prev.twinRooms, quantity: value }
                        })))}
                    </Grid>
                    <Grid item xs={6}>
                      {renderCostField('Cost', accommodation.twinRooms.cost,
                        (value) => setAccommodation(prev => ({
                          ...prev,
                          twinRooms: { ...prev.twinRooms, cost: value }
                        })))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {renderCostField('Nights', accommodation.nights,
                    (value) => setAccommodation(prev => ({ ...prev, nights: value })))}
                </Grid>
              </Grid>
              <Typography sx={{ mt: 1 }}>
                Total: {formatCurrency(calculateAccommodationTotal())}
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {/* Technical */}
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
    <MicNone fontSize="small" /> / 
    <Tungsten fontSize="small" />
  </Box>
    Technical Requirements
</Typography> 
             <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {renderCostField('Backline', technical.backline,
                    (value) => setTechnical(prev => ({ ...prev, backline: value })))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderCostField('Sound Engineer', technical.soundEngineer,
                    (value) => setTechnical(prev => ({ ...prev, soundEngineer: value })))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderCostField('Lighting Engineer', technical.lightingEngineer,
                    (value) => setTechnical(prev => ({ ...prev, lightingEngineer: value })))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderCostField('Other Technical', technical.other,
                    (value) => setTechnical(prev => ({ ...prev, other: value })))}
                </Grid>
              </Grid>
              <Typography sx={{ mt: 1 }}>
                Total: {formatCurrency(calculateTechnicalTotal())}
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {/* Per Diems */}
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <AttachMoneyIcon fontSize="small" />
  Per Diem
</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  {renderCostField('Daily Rate', perDiem.rate,
                    (value) => setPerDiem(prev => ({ ...prev, rate: value })))}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {renderCostField('Quantity', perDiem.quantity,
                    (value) => setPerDiem(prev => ({ ...prev, quantity: value })))}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {renderCostField('Days', perDiem.days,
                    (value) => setPerDiem(prev => ({ ...prev, days: value })))}
                </Grid>
              </Grid>
              <Typography sx={{ mt: 1 }}>
                Total: {formatCurrency(calculatePerDiemTotal())}
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {/* Equipment */}
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <PrecisionManufacturingIcon fontSize="small" />
  Equipment Rental
</Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
  {renderCostField('Cost per Item', equipment.rental,
    (value) => setEquipment(prev => ({ ...prev, rental: value })))}
</Grid>
                <Grid item xs={12} sm={4}>
                  {renderCostField('Quantity', equipment.quantity,
                    (value) => setEquipment(prev => ({ ...prev, quantity: value })))}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {renderCostField('Days', equipment.days,
                    (value) => setEquipment(prev => ({ ...prev, days: value })))}
                </Grid>
              </Grid>
              <Typography sx={{ mt: 1 }}>
                Total: {formatCurrency(calculateEquipmentTotal())}
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {/* Other Costs */}
            <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 3 }}> {/* Adds spacing below this section */}
            <Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <Sell fontSize="small" />
  Additional Costs
</Typography>
  <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
      {renderCostField('Marketing', marketing,
        (value) => setMarketing(value))}
    </Grid>
    <Grid item xs={12} sm={4}>
      {renderCostField('Insurance', insurance,
        (value) => setInsurance(value))}
    </Grid>
    <Grid item xs={12} sm={4}>
      {renderCostField('Visas', visas,
        (value) => setVisas(value))}
    </Grid>
  </Grid>
</Grid>
<Typography variant="h6" gutterBottom color="#FFD700" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <MoreHorizIcon fontSize="small" />
  Other Costs
</Typography>
              {otherCosts.map((cost, index) => (
                <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                  <Grid item xs={8}>
                    <TextField
                      label="Description"
                      value={cost.description}
                      onChange={(e) => handleOtherCostChange(index, 'description', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Amount"
                      value={cost.amount}
                      onChange={(e) => handleOtherCostChange(index, 'amount', e.target.value)}
                      type="number"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
              <Button
                variant="outlined"
                onClick={handleAddOtherCost}
                sx={{ mt: 1 }}
              >
                Add Other Cost
              </Button>
              <Typography sx={{ mt: 2 }}>
                Total Other Costs: {formatCurrency(calculateOtherCostsTotal())}
              </Typography>
            </Grid>

            {/* Grand Total */}
            <Grid item xs={12}>
              <Box sx={{ 
                mt: 3, 
                p: 2, 
                bgcolor: 'primary.main', 
                color: 'primary.contrastText',
                borderRadius: 1
              }}>
                <Typography variant="h5">
                  Grand Total: {formatCurrency(grandTotal)}
                </Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">Flights: {formatCurrency(calculateFlightsTotal())}</Typography>
                    <Typography variant="body2">Accommodation: {formatCurrency(calculateAccommodationTotal())}</Typography>
                    <Typography variant="body2">Per Diems: {formatCurrency(calculatePerDiemTotal())}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">Equipment: {formatCurrency(calculateEquipmentTotal())}</Typography>
                    <Typography variant="body2">Technical: {formatCurrency(calculateTechnicalTotal())}</Typography>
                    <Typography variant="body2">Other: {formatCurrency(calculateOtherCostsTotal())}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Apply Calculations
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventCalculator;