import React, { useState, useCallback, useEffect } from 'react';
import { 
  Autocomplete, 
  TextField, 
  Grid, 
  CircularProgress,
  Typography,
  Tooltip
} from '@mui/material';
import { Info } from 'lucide-react';
import debounce from 'lodash/debounce';
import axiosInstance from '../../utils/axiosConfig';
import { Strand } from '../../types/Strand';
import { IMC_EXPENSE_CODES } from '../../constants/imcCodes';
import { EventType } from '../../types/EventTypes';

interface InternalUseSectionProps {
  activityValue: string;
  imcCodeValue: string;
  onActivityChange: (value: string) => void;
  onIMCCodeChange: (value: string) => void;
}

// Create a minimal Strand object for initialization
const createInitialStrand = (name: string): Strand => ({
  name,
  _id: '',
  isQuickStrand: false,
  description: '',
  type: 'domestic',
  startDate: new Date(),
  endDate: new Date(),
  eventType: 'concert' as EventType,
  status: 'planned',
  mainImage: '',
  dates: [],
  published: false,
  year: new Date().getFullYear().toString(),
  artsCouncilActivityType: '',
  artsCouncilActivityTarget: '',
  artsCouncilStatus: '',
  overallFinance: {
    totalIncome: 0,
    totalExpenditure: 0
  },
  associations: {
    venues: [],
    performances: [],
    strands: [],
    personnel: [],
    bands: [],
    applications: []
  },
  employmentFigures: {
    artists: 0,
    others: 0,
    associatedEmploy: 0,
    volunteers: 0
  },
  audiences: {
    venuesCaps: 0,
    paying: 0,
    free: 0,
    total: 0,
    ratio: 0
  },
  numberOfProposedEvents: 0
});

const InternalUseSection: React.FC<InternalUseSectionProps> = ({
  activityValue,
  imcCodeValue,
  onActivityChange,
  onIMCCodeChange
}) => {
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loadingStrands, setLoadingStrands] = useState(false);
  const [strandError, setStrandError] = useState<string | null>(null);
  const [selectedStrand, setSelectedStrand] = useState<Strand | null>(() => 
    activityValue ? createInitialStrand(activityValue) : null
  );
  const [selectedIMCCode, setSelectedIMCCode] = useState<{ code: string; label: string } | null>(() => 
    imcCodeValue ? { code: '', label: imcCodeValue } : null
  );

  useEffect(() => {
    if (activityValue && !selectedStrand) {
      setSelectedStrand(createInitialStrand(activityValue));
    }
    if (imcCodeValue && !selectedIMCCode) {
      setSelectedIMCCode({ code: '', label: imcCodeValue });
    }
  }, [activityValue, imcCodeValue]);

  // Format IMC codes for autocomplete
  const imcOptions = Object.values(IMC_EXPENSE_CODES).map(code => ({
    code: code.code,
    label: code.label,
    description: code.description
  }));

  // Debounced strand search
  const searchStrands = useCallback(
    debounce(async (searchTerm: string) => {
      if (!searchTerm || searchTerm.length < 2) return;

      setLoadingStrands(true);
      setStrandError(null);

      try {
        const response = await axiosInstance.get(`/api/strands/search?q=${searchTerm}`);
        setStrands(response.data);
      } catch (error) {
        console.error('Error searching strands:', error);
        setStrandError('Failed to load strands');
        setStrands([]);
      } finally {
        setLoadingStrands(false);
      }
    }, 300),
    []
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" gutterBottom>
          Internal Use
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          fullWidth
          value={selectedStrand}
          onChange={(_, newValue) => {
            setSelectedStrand(newValue);
            onActivityChange(newValue?.name || '');
          }}
          onInputChange={(_, value) => searchStrands(value)}
          options={strands}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return `${option.name} (${option.year})` || '';
          }}
          loading={loadingStrands}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Activity"
              error={!!strandError}
              helperText={strandError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingStrands && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.year} • {option.type} • {option.eventType}
                </Typography>
              </div>
            </li>
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          fullWidth
          value={selectedIMCCode}
          onChange={(_, newValue) => {
            setSelectedIMCCode(newValue);
            onIMCCodeChange(newValue?.label || '');
          }}
          options={imcOptions}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          renderInput={(params) => (
            <TextField
              {...params}
              label="IMC Code"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Tooltip title="Internal accounting code">
                    <Info size={20} className="text-gray-400" />
                  </Tooltip>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <Typography variant="body1">{option.label}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.code}
                  {option.label && ` • ${option.label}`}
                </Typography>
              </div>
            </li>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default InternalUseSection;