import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Typography,
  Chip,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Divider
} from '@mui/material';
import { ListTodo, ExternalLink, Clock } from 'lucide-react';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosConfig';

interface TaskboardSubtask {
  _id: string;
  title: string;
  completed: boolean;
  assignedTo: string | null;
  dueDate: string;
  completedAt?: string;
  completedBy?: string;
  boardId?: string;
  boardTitle?: string;
}

interface Taskboard {
  _id: string;
  title: string;
  description: string;
  status: 'todo' | 'in_progress' | 'blocked' | 'done';
  priority: 'low' | 'medium' | 'high' | 'urgent';
  progress: number;
  category: string;
  dueDate: string;
  estimatedHours?: number;
  subtasks: TaskboardSubtask[];
  assignedTo: string | { _id: string; name: string; email: string; };
  watchers: string[];
  createdBy: { _id: string; name: string; email: string; };
  lastUpdatedBy: string;
  order: number;
}

interface TaskboardApiResponse {
  tasks: Taskboard[];
  pagination: {
    total: number;
    page: number;
    pages: number;
  };
}

interface TaskboardListState {
  taskboards: Taskboard[];
  userSubtasks: TaskboardSubtask[];
  loading: boolean;
  error: string | null;
}

interface TaskboardListProps {
  userId: string;
}

const TaskboardList: React.FC<TaskboardListProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<TaskboardListState>({
    taskboards: [],
    userSubtasks: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchTaskboards = async () => {
      try {
        console.log('Fetching taskboards for user:', userId);
        const response = await axiosInstance.get<TaskboardApiResponse>('/api/taskboards', {
          params: {
            assignedTo: userId,
          }
        });
        
        console.log('Taskboards response:', response.data);

        const taskboardsArray = response.data.tasks;

        // Process subtasks, including those from tasks assigned to the user
        const subtasks: TaskboardSubtask[] = taskboardsArray.flatMap((taskboard: Taskboard) => {
          const isTaskAssigned = typeof taskboard.assignedTo === 'string' 
            ? taskboard.assignedTo === userId
            : taskboard.assignedTo?._id === userId;

          return (taskboard.subtasks || [])
            .filter((subtask: TaskboardSubtask) => {
              const isSubtaskAssigned = subtask.assignedTo === userId;
              return (isSubtaskAssigned || isTaskAssigned) && !subtask.completed;
            })
            .map((subtask: TaskboardSubtask) => ({
              ...subtask,
              boardId: taskboard._id,
              boardTitle: taskboard.title
            }));
        });
        
        setState({
            taskboards: taskboardsArray,  // Don't filter again, backend already did it
            userSubtasks: subtasks,
            loading: false,
            error: null
          });
      } catch (err) {
        console.error('Error fetching taskboards:', err);
        setState(prev => ({
          ...prev,
          error: 'Failed to load taskboards',
          loading: false
        }));
      }
    };

    fetchTaskboards();
  }, [userId]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'todo':
        return 'default';
      case 'in_progress':
        return 'primary';
      case 'blocked':
        return 'error';
      case 'done':
        return 'success';
      default:
        return 'default';
    }
  };

  const formatStatus = (status: string) => {
    return status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  if (state.error) return <Alert severity="error">{state.error}</Alert>;

  const { taskboards, userSubtasks, loading } = state;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<Clock />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Badge 
            badgeContent={taskboards.length + userSubtasks.length} 
            color="primary"
          >
            <ListTodo />
          </Badge>
          <Typography>Your Project Tasks</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Typography color="text.secondary">Loading project tasks...</Typography>
        ) : (
          <>
            {/* Main Tasks */}
            {taskboards.length > 0 && (
              <>
                <Typography variant="subtitle2" gutterBottom>Main Tasks</Typography>
                <List dense>
                  {taskboards.map((board: Taskboard) => (
                    <ListItem
                      key={board._id}
                      button
                      onClick={() => navigate(`/admin/taskboard?task=${board._id}`)}
                    >
                      <ListItemIcon>
                        <Chip 
                          size="small" 
                          label={board.category}
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={board.title}
                        secondary={
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Chip 
                              size="small" 
                              label={formatStatus(board.status)}
                              color={getStatusColor(board.status)}
                            />
                            {board.dueDate && (
                              <Typography variant="caption">
                                Due: {format(new Date(board.dueDate), 'PP')}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/admin/taskboard?task=${board._id}`);
                          }}
                        >
                          <ExternalLink size={16} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {/* Subtasks */}
            {userSubtasks.length > 0 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" gutterBottom>Your Subtasks</Typography>
                <List dense>
                  {userSubtasks.map((subtask: TaskboardSubtask) => (
                    <ListItem
                      key={subtask._id}
                      button
                      onClick={() => navigate(`/admin/taskboard?task=${subtask.boardId}`)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={subtask.completed}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={subtask.title}
                        secondary={
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Typography variant="caption" color="text.secondary">
                              From: {subtask.boardTitle}
                            </Typography>
                            {subtask.dueDate && (
                              <Typography variant="caption">
                                Due: {format(new Date(subtask.dueDate), 'PP')}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {taskboards.length === 0 && userSubtasks.length === 0 && (
              <Typography color="text.secondary">
                No project tasks assigned to you
              </Typography>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TaskboardList;