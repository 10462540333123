// src/utils/scheduleTemplates.ts

export const EVENT_TYPES = {
  TRAVEL: 'TRAVEL',
  VENUE: 'VENUE',
  STAFF: 'STAFF',
  SOUNDCHECK: 'SOUNDCHECK',
  PERFORMANCE: 'PERFORMANCE',
  LOADOUT: 'LOADOUT',
  OTHER: 'OTHER'
} as const;

export interface ScheduleItem {
  id: string;
  time: string;
  type: keyof typeof EVENT_TYPES;
  description: string;
  template?: string;
}

interface TemplateVariables {
  ARTIST: string;
  VENUE: string;
  VENUE_ADDRESS?: string;
  LOCATIONA?: string;
  LOCATIONB?: string;
  AIRLINE?: string;
  FLIGHT_NO?: string;
  BOOKING_REF?: string;
  HOTEL?: string;
  CONTACT_NAME?: string;
  CONTACT_EMAIL?: string;
  CONTACT_PHONE?: string;
  [key: string]: string | undefined;
}

export const getCoolerTemplate = (variables: TemplateVariables): ScheduleItem[] => [
  {
    id: '1',
    time: '15:00',
    type: 'STAFF',
    description: 'Staff Call for Event at The Cooler',
    template: 'Staff Call for Event at {VENUE}'
  },
  {
    id: '2',
    time: '16:30',
    type: 'VENUE',
    description: 'Load In Equipment and Backline',
  },
  {
    id: '3',
    time: '16:45',
    type: 'VENUE',
    description: 'Set up Equipment and Backline'
  },
  {
    id: '4',
    time: '17:00',
    type: 'SOUNDCHECK',
    description: `${variables.ARTIST} arrive at venue for Soundcheck`,
    template: '{ARTIST} arrive at venue for Soundcheck'
  },
  {
    id: '5',
    time: '17:30',
    type: 'SOUNDCHECK',
    description: 'Soundcheck Begins'
  },
  {
    id: '6',
    time: '18:30',
    type: 'SOUNDCHECK',
    description: 'Soundcheck Ends'
  },
  {
    id: '7',
    time: '19:00',
    type: 'STAFF',
    description: 'Front of House Staff Arrives'
  },
  {
    id: '8',
    time: '19:00',
    type: 'STAFF',
    description: 'Security Staff Arrives'
  },
  {
    id: '9',
    time: '19:15',
    type: 'VENUE',
    description: 'Prep Front of House for Doors'
  },
  {
    id: '10',
    time: '19:30',
    type: 'VENUE',
    description: 'Doors Open'
  },
  {
    id: '11',
    time: '19:45',
    type: 'VENUE',
    description: `${variables.ARTIST} to Dressing Room`,
    template: '{ARTIST} to Dressing Room'
  },
  {
    id: '12',
    time: '19:30',
    type: 'VENUE',
    description: 'Begin House Music'
  },
  {
    id: '13',
    time: '20:00',
    type: 'PERFORMANCE',
    description: `${variables.ARTIST} ON Stage - Set 1`,
    template: '{ARTIST} ON Stage - Set 1'
  },
  {
    id: '14',
    time: '20:45',
    type: 'PERFORMANCE',
    description: `${variables.ARTIST} OFF Stage - Set 1`,
    template: '{ARTIST} OFF Stage - Set 1'
  },
  {
    id: '15',
    time: '20:45',
    type: 'PERFORMANCE',
    description: 'Intermission'
  },
  {
    id: '16',
    time: '21:00',
    type: 'PERFORMANCE',
    description: `${variables.ARTIST} ON Stage - Set 2`,
    template: '{ARTIST} ON Stage - Set 2'
  },
  {
    id: '17',
    time: '21:45',
    type: 'PERFORMANCE',
    description: `${variables.ARTIST} OFF Stage - Set 2`,
    template: '{ARTIST} OFF Stage - Set 2'
  },
  {
    id: '18',
    time: '22:00',
    type: 'LOADOUT',
    description: 'Begin Load Out'
  },
  {
    id: '19',
    time: '22:30',
    type: 'VENUE',
    description: 'The Cooler - Venue Curfew',
    template: '{VENUE} - Venue Curfew'
  }
];

export const getDefaultVenueTemplate = (variables: TemplateVariables): ScheduleItem[] => [
  // Similar structure but with more generic venue times and descriptions
  // Can be added later as needed
];

export const getInternationalTemplate = (variables: TemplateVariables): ScheduleItem[] => [
  {
    id: 'travel-1',
    time: '11:05',
    type: 'TRAVEL',
    description: `Touring party depart ${variables.LOCATIONA} for ${variables.LOCATIONB} on ${variables.AIRLINE} flight ${variables.FLIGHT_NO}`,
    template: 'Touring party depart {LOCATIONA} for {LOCATIONB} on {AIRLINE} flight {FLIGHT_NO}'
  },
  ...getCoolerTemplate(variables)
];