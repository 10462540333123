// src/services/contractServices.ts
import axiosInstance from '../utils/axiosConfig';
import { Venue } from '../types/mongodb';
import { BandRecommendation } from '../types/BandRecommendations';
import { FormData } from '../components/contracts/types';

interface ServiceResponse<T> {
  data: T[];
  message?: string;
  status: number;
}

interface GenerateContractParams {
    data: FormData;
    mode?: 'create' | 'edit';
    originalContractId?: string;
  }

interface VenueResponse {
    _id: string;
    name: string;
    location: string;
    rentalCost: number;
    capacity: number;
    createdAt: Date;
    updatedAt: Date;
  }

export const contractServices = {
    getVenues: async (): Promise<Venue[]> => {
        try {
          const response = await axiosInstance.get<VenueResponse[]>('/api/venues');
          // Response is the array directly, not nested in data property
          return response.data.map(venue => ({
            ...venue,
            rentalCost: venue.rentalCost || 0,
            createdAt: venue.createdAt || new Date(),
            updatedAt: venue.updatedAt || new Date()
          }));
        } catch (error) {
          console.error('Error fetching venues:', error);
          return [];
        }
      },

  getArtists: async (searchTerm?: string): Promise<BandRecommendation[]> => {
    try {
      const response = await axiosInstance.get<BandRecommendation[]>(
        `/api/bandrecommendations/search`,
        { 
          params: { 
            q: searchTerm
          } 
        }
      );
      return response.data || [];
    } catch (error) {
      console.error('Error searching artists:', error);
      return [];
    }
  },

  generateContract: async ({ data, mode, originalContractId }: GenerateContractParams): Promise<string> => {
    console.log('Contract Generation Params:', {
      mode,
      originalContractId,
      contractNumber: data.contractNumber
    });

    const requestData = {
      data: {
        ...data,
        'Event Date': data['Event Date']?.toLocaleDateString(),
        'Soundcheck Time': data['Soundcheck Time']?.toLocaleTimeString(),
        'Stage Time': data['Stage Time']?.toLocaleTimeString(),
        'Doors': data['Doors']?.toLocaleTimeString(),
      },
      mode: mode || 'create',
      originalContractId
    };

    console.log('Request to backend:', JSON.stringify(requestData, null, 2));

    try {
      const response = await axiosInstance.post('/api/contracts/generate', requestData);
      console.log('Generation response:', response.data);
      return response.data.url;
    } catch (error) {
      console.error('Contract generation error:', error);
      throw error;
    }
  },

  updateContract: async (contractId: string, formData: FormData): Promise<void> => {
    try {
      // Transform form data back to API format
      const apiData = {
        artistName: formData.Item,
        email: formData.Email,
        phone: formData.Phone,
        eventDate: formData['Event Date']?.toISOString(),
        venue: formData.Venue,
        address: formData.Address,
        soundcheckTime: formData['Soundcheck Time']?.toISOString(),
        soundcheckDuration: formData['Soundcheck Duration'],
        doorsTime: formData.Doors?.toISOString(),
        stageTime: formData['Stage Time']?.toISOString(),
        performanceType: formData['Performance Type'],
        minDuration: formData['Min Duration'],
        fees: formData.Fees,
        paymentType: formData['Payment Type'],
        intlFlights: formData['Int\'l Flights'],
        groundTransport: formData['Ground Transport'],
        accommodationType: formData['Accommodation Type'],
        accommodationQty: formData['Accommodation Qty'],
        pdAmount: formData['PD Amount'],
        pdQuantity: formData['PD Quantity'],
        pdDays: formData['PD Days'],
        activitiesMaster: formData['Activities Master'],
        imcCode: formData['IMC Code'],
        contractPath: formData.contractPath,
      };

      await axiosInstance.put(`/api/contracts/${contractId}`, apiData);
    } catch (error) {
      console.error('Error updating contract:', error);
      throw error;
    }
  },

  downloadContract: async (url: string): Promise<Blob> => {
    try {
      // Extract filename from url
      const filename = url.split('/').pop();
      // Use our proxy endpoint instead of direct GCS url
      const response = await axiosInstance.get(`/api/contracts/download/${filename}`, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      console.error('Contract download error:', error);
      throw error;
    }
  }
};