import React, { useState, useCallback, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  CircularProgress
} from '@mui/material';
import debounce from 'lodash/debounce';
import { BandRecommendation } from '../types/BandRecommendations';
import axiosInstance from '../utils/axiosConfig';

interface SmartBandInputProps {
  value: BandRecommendation | null;
  onChange: (band: BandRecommendation | null) => void;
  onManualInput: (value: string) => void;
  initialValue?: string;
  loading?: boolean;
}

const SmartBandInput: React.FC<SmartBandInputProps> = ({
  value,
  onChange,
  onManualInput,
  initialValue,
  loading = false
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<BandRecommendation[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    console.log('Initial value changed:', initialValue); // Debug log
    if (initialValue) {
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const searchBands = async (searchTerm: string) => {
    if (searchTerm.length < 2) return;
    
    setSearchLoading(true);
    try {
      const response = await axiosInstance.get('/api/performances/bands/search', {
        params: { q: searchTerm }
      });
      setOptions(response.data);
    } catch (error) {
      console.error('Error searching bands:', error);
      setOptions([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(searchBands, 300),
    []
  );

  return (
    <Autocomplete
      fullWidth
      freeSolo
      value={inputValue}
      inputValue={inputValue}
      onChange={(_, newValue) => {
        console.log('onChange value:', newValue); // Debug log
        if (typeof newValue === 'string') {
          setInputValue(newValue);
          onManualInput(newValue);
        } else if (newValue) {
          setInputValue(newValue.artistName);
          onChange(newValue);
        }
      }}
      onInputChange={(_, newInputValue) => {
        console.log('onInputChange value:', newInputValue); // Debug log
        setInputValue(newInputValue);
        onManualInput(newInputValue); // Send manual input on every change
      }}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.artistName;
      }}
      loading={loading || searchLoading}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack spacing={0}>
            <Typography variant="body1">
              {option.artistName}
            </Typography>
            {option.genres && option.genres.length > 0 && (
              <Typography variant="caption" color="text.secondary">
                {option.genres.join(', ')}
              </Typography>
            )}
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Artist/Band Name"
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(loading || searchLoading) && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === 'string') {
          return option.artistName === value;
        }
        return option._id === value._id;
      }}
    />
  );
};

export default SmartBandInput;