import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  SelectChangeEvent
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Calculator } from 'lucide-react';
import { BandRecommendation } from '../../../../types/BandRecommendations';

interface BandSectionProps {
    formData: {
      band: string;
      newBandName: string;
      simpleBandName?: string; // Add this
      startTime: Date | null;
      endTime: Date | null;
      fee: string;
      status: string;
    };
    bandType: 'existing' | 'new';
    bands: BandRecommendation[];
    onBandTypeChange: (value: 'existing' | 'new') => void;
    onFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => void;
    onDateChange: (field: 'startTime' | 'endTime') => (newValue: Date | null) => void;
    onFeeCalculatorOpen: () => void;
    isEdit?: boolean; // Add this
  }

  export const BandSection: React.FC<BandSectionProps> = ({
    formData,
    bandType,
    bands,
    onBandTypeChange,
    onFieldChange,
    onDateChange,
    onFeeCalculatorOpen,
    isEdit = false
  }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Band Information</Typography>
        {!isEdit && ( // Only show radio group if not editing
          <RadioGroup
            row
            value={bandType}
            onChange={(e) => onBandTypeChange(e.target.value as 'existing' | 'new')}
            sx={{ mb: 2, justifyContent: 'space-evenly' }}
          >
            <FormControlLabel 
              value="existing" 
              control={<Radio />} 
              label="Select Existing Band" 
            />
            <FormControlLabel 
              value="new" 
              control={<Radio />} 
              label="Quick Add New Band" 
            />
          </RadioGroup>
        )}


<Box sx={{ mt: 2 }}>
          {isEdit && formData.simpleBandName ? (
            // For editing a simple band name
            <TextField
              fullWidth
              name="simpleBandName"
              label="Band Name"
              value={formData.simpleBandName}
              onChange={onFieldChange}
              disabled={isEdit}
            />
          ) : isEdit && formData.band ? (
            // For editing an existing band
            <FormControl fullWidth>
              <InputLabel>Band</InputLabel>
              <Select
                name="band"
                value={formData.band}
                onChange={onFieldChange}
                disabled={isEdit}
              >
                {bands.map((band) => (
                  <MenuItem key={band._id} value={band._id}>
                    {band.artistName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : bandType === 'existing' ? (
            // Normal existing band selection
            <FormControl fullWidth>
              <InputLabel>Band</InputLabel>
              <Select
                name="band"
                value={formData.band}
                onChange={onFieldChange}
                required={bandType === 'existing'}
              >
                {bands.map((band) => (
                  <MenuItem key={band._id} value={band._id}>
                    {band.artistName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            // Normal new band input
            <TextField
              fullWidth
              name="newBandName"
              label="Band Name"
              value={formData.newBandName}
              onChange={onFieldChange}
              required={bandType === 'new'}
              helperText="You can create a full band profile later"
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={onDateChange('startTime')}
              ampm={false}
              format="dd/MM/yyyy HH:mm"
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Time"
              value={formData.endTime}
              onChange={onDateChange('endTime')}
              ampm={false}
              format="dd/MM/yyyy HH:mm"
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="fee"
              label="Performance Fee (€)"
              type="text"
              inputMode="numeric"
              value={formData.fee}
              onChange={onFieldChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button 
              fullWidth 
              variant="contained"
              onClick={onFeeCalculatorOpen}
              startIcon={<Calculator />}
            >
              Calculate Fee
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={formData.status}
            onChange={onFieldChange}
            required
          >
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BandSection;