  import React, { useState, useEffect } from 'react';
  import { Box, Alert, SelectChangeEvent, Dialog, Button } from '@mui/material';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  import ProgrammingSection from './sections/ProgrammingSection';
  import BandSection from './sections/BandSection';
  import CostsSection from './sections/CostsSection';
  import TicketingSection from './sections/TicketingSection';
  import RevenueProjections from './sections/RevenueProjections';
  import { 
    FormData, 
    PerformanceFormProps, 
    CostSummary, 
    TicketType 
  } from './types';
  import { BandRecommendation } from '../../../types/BandRecommendations';
  import { Venue, Event } from '../../../types/mongodb';
  import { Strand } from '../../../types/Strand';
  import axiosInstance from '../../../utils/axiosConfig';

  // Dialogs
  import TicketConfigDialog from '../TicketConfigDialog';
  import ShowRunnrCalc from '../../ShowRunnrCalc';
  import EventCalculator from '../../EventCalculator';
  import { useNavigate } from 'react-router-dom';

  interface TicketConfig {
    basePrice: number;
    ticketTypes: TicketType[];
    projections: {
      revenue: {
        gross: number;
        net: number;
        vat: number;
      };
    };
  }

  interface CleanSubmissionData {
    band?: string;
    simpleBandName?: string;
    strandId?: string;
    eventId?: string;
    venue?: string;
    startTime: Date | null;
    endTime: Date | null;
    status: 'pending' | 'confirmed' | 'cancelled';
    costs: {
      bandFee: string;
      travel: { flights: number; groundTransport: number; other: number };
      accommodation: {
        singleRooms: { quantity: number; costPerRoom: number; totalNights: number };
        twinRooms: { quantity: number; costPerRoom: number; totalNights: number };
        total: number;
      };
      perDiem: { dailyRate: number; numberOfDays: number; total: number };
      equipment: { rental: number; shipping: number; backline: number };
      technical: { soundEngineer: number; lightingEngineer: number; backline: number; other: number };
      additional: { marketing: number; insurance: number; visas: number };
      otherCosts: Array<{ description: string; amount: number; }>;
      summary: { totalCosts: number; contingency: number; grandTotal: number };
    };
  }

  interface CalculatedCosts {
    travel: { flights: number; groundTransport: number; other: number; total: number };
    accommodation: {
      singleRooms: { quantity: number; costPerRoom: number; totalNights: number };
      twinRooms: { quantity: number; costPerRoom: number; totalNights: number };
      total: number;
    };
    perDiem: { dailyRate: number; numberOfDays: number; total: number };
    equipment: { rental: number; shipping: number; backline: number; total: number };
    technical: { soundEngineer: number; lightingEngineer: number; backline: number; other: number; total: number };
    additional: { marketing: number; insurance: number; visas: number; total: number };
    otherCosts: Array<{ description: string; amount: number; }>;
    summary: { totalCosts: number; contingency: number; grandTotal: number };
    currency: string;
  }

  const PerformanceForm: React.FC<PerformanceFormProps> = ({
    eventId,
    strandId,
    onSubmit,
    initialData,
    isEdit = false,
  }: PerformanceFormProps) => {
    // Core form state
    const [formData, setFormData] = useState<FormData>({
      _id: '',
      band: '',
      newBandName: '',
      simpleBandName: '',
      eventId: eventId || '',
      strandId: strandId || '',
      startTime: null,  // Start with null instead of new Date()
      endTime: null,   
      venue: '',
      status: 'pending',
      fee: '',
      costs: {
        bandFee: 0,  // Use this instead of separate fee field
        travel: {
          flights: 0,
          groundTransport: 0,
          other: 0,
          total: 0
        },
        accommodation: {
          singleRooms: { quantity: 0, costPerRoom: 0, totalNights: 0 },
          twinRooms: { quantity: 0, costPerRoom: 0, totalNights: 0 },
          total: 0
        },
        perDiem: {
          dailyRate: 0,
          numberOfDays: 0,
          total: 0
        },
        equipment: {
          rental: 0,
          shipping: 0,
          backline: 0,
          total: 0
        },
        contingencyRate: 0,
        technical: {
          soundEngineer: 0,
          lightingEngineer: 0,
          backline: 0,
          other: 0,
          total: 0
        },
        additional: {
          marketing: 0,
          insurance: 0,
          visas: 0,
          total: 0
        },
        otherCosts: [],
        summary: {
          totalCosts: 0,
          contingency: 0,
          grandTotal: 0
        },
        currency: 'EUR'
      },
      contingencyRate: '10',
      notes: '',
      ticketConfigId: '',
      ticketing: {
          basePrice: 0,  // Keep separate from bandFee
          isFreePerformance: false,
          tiers: [],
          sales: {
            totalSold: 0,
            totalRevenue: 0
          },
          config: null  // Add this to match the interface
        },
    });

    // UI state
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [bandType, setBandType] = useState<'existing' | 'new'>('existing');
    const navigate = useNavigate();

    // Data state
    const [events, setEvents] = useState<Event[]>([]);
    const [strands, setStrands] = useState<Strand[]>([]);
    const [bands, setBands] = useState<BandRecommendation[]>([]);
    const [venues, setVenues] = useState<Venue[]>([]);
    const [calculatedCosts, setCalculatedCosts] = useState<CalculatedCosts | null>(null);
    const [strandCounts, setStrandCounts] = useState<Record<string, { totalEvents: number; programmedEvents: number; remainingEvents: number }>>({});

    // Dialog states
    const [ticketConfigOpen, setTicketConfigOpen] = useState<boolean>(false);
    const [existingConfigOpen, setExistingConfigOpen] = useState<boolean>(false);
    const [feeCalculatorOpen, setFeeCalculatorOpen] = useState<boolean>(false);
    const [eventCalculatorOpen, setEventCalculatorOpen] = useState<boolean>(false);

    // Load initial data
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const [eventsRes, strandsRes, bandsRes, venuesRes] = await Promise.all([
            axiosInstance.get('/api/events'),
            axiosInstance.get('/api/strands'),
            axiosInstance.get('/api/bandRecommendations'),
            axiosInstance.get('/api/venues')
          ]);

          setEvents(eventsRes.data);
          setStrands(strandsRes.data);
          setBands(bandsRes.data);
          setVenues(venuesRes.data);
          setError(null);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError('Failed to load necessary data');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, []);

    useEffect(() => {
      if (initialData) {
        setFormData((prev) => ({
          ...prev,
          band: initialData.band || '',
          simpleBandName: initialData.simpleBandName || '',
          eventId: initialData.eventId || eventId || '',
          strandId: initialData.strandId || strandId || '',
          startTime: initialData.startTime ? new Date(initialData.startTime) : null,
          endTime: initialData.endTime ? new Date(initialData.endTime) : null,  
          venue: initialData.venue || '',
          fee: initialData.fee || '',
          status: initialData.status || 'pending',
          costs: {
            bandFee: initialData.costs?.bandFee || 0, 
            contingencyRate: parseFloat(initialData.costs?.contingencyRate?.toString() || '10'), // Convert to number
            travel: {
              flights: parseFloat(initialData.costs?.travel?.flights?.toString() || '0'),
              groundTransport: parseFloat(initialData.costs?.travel?.groundTransport?.toString() || '0'),
              other: parseFloat(initialData.costs?.travel?.other?.toString() || '0'),
              total: parseFloat(initialData.costs?.travel?.total?.toString() || '0'),
            },
            accommodation: {
              singleRooms: {
                quantity: parseFloat(initialData.costs?.accommodation?.singleRooms?.quantity?.toString() || '0'),
                costPerRoom: parseFloat(initialData.costs?.accommodation?.singleRooms?.costPerRoom?.toString() || '0'),
                totalNights: parseFloat(initialData.costs?.accommodation?.singleRooms?.totalNights?.toString() || '0'),
              },
              twinRooms: {
                quantity: parseFloat(initialData.costs?.accommodation?.twinRooms?.quantity?.toString() || '0'),
                costPerRoom: parseFloat(initialData.costs?.accommodation?.twinRooms?.costPerRoom?.toString() || '0'),
                totalNights: parseFloat(initialData.costs?.accommodation?.twinRooms?.totalNights?.toString() || '0'),
              },
              total: parseFloat(initialData.costs?.accommodation?.total?.toString() || '0'),
            },
            perDiem: {
              dailyRate: parseFloat(initialData.costs?.perDiem?.dailyRate?.toString() || '0'),
              numberOfDays: parseFloat(initialData.costs?.perDiem?.numberOfDays?.toString() || '0'),
              total: parseFloat(initialData.costs?.perDiem?.total?.toString() || '0'),
            },
            equipment: {
              rental: parseFloat(initialData.costs?.equipment?.rental?.toString() || '0'),
              shipping: parseFloat(initialData.costs?.equipment?.shipping?.toString() || '0'),
              backline: parseFloat(initialData.costs?.equipment?.backline?.toString() || '0'),
              total: parseFloat(initialData.costs?.equipment?.total?.toString() || '0'),
            },
            technical: {
              soundEngineer: parseFloat(initialData.costs?.technical?.soundEngineer?.toString() || '0'),
              lightingEngineer: parseFloat(initialData.costs?.technical?.lightingEngineer?.toString() || '0'),
              backline: parseFloat(initialData.costs?.technical?.backline?.toString() || '0'),
              other: parseFloat(initialData.costs?.technical?.other?.toString() || '0'),
              total: parseFloat(initialData.costs?.technical?.total?.toString() || '0'),
            },
            additional: {
              marketing: parseFloat(initialData.costs?.additional.marketing?.toString() || '0'),
              insurance: parseFloat(initialData.costs?.additional.insurance?.toString() || '0'),
              visas: parseFloat(initialData.costs?.additional.visas?.toString() || '0'),
              total: parseFloat(initialData.costs?.additional?.total?.toString() || '0'),
            },
            otherCosts: initialData.costs?.otherCosts || [],
            summary: {
              totalCosts: parseFloat(initialData.costs?.summary?.totalCosts?.toString() || '0'),
              contingency: parseFloat(initialData.costs?.summary?.contingency?.toString() || '0'),
              grandTotal: parseFloat(initialData.costs?.summary?.grandTotal?.toString() || '0'),
            },
            currency: initialData.costs?.currency || 'EUR',
          },
          ticketing: {
            ...prev.ticketing,
            ...initialData.ticketing,
            basePrice: initialData.ticketing?.basePrice || 0,  
          },
        }));
    
        // ✅ ADD THIS: Ensure calculatedCosts is also set when loading initialData
        if (initialData.costs) {
          setCalculatedCosts(initialData.costs);
        }
      }
    }, [initialData, eventId, strandId]);

    // Fetch strand counts when strands change
    useEffect(() => {
      strands.forEach((strand) => {
        fetchStrandCounts(strand._id);
      });
    }, [strands]);

    const fetchStrandCounts = async (strandId: string | undefined) => {
      if (!strandId) return;
      try {
        const response = await axiosInstance.get(`/api/performances/strand-status/${strandId}`);
        setStrandCounts((prev) => ({
          ...prev,
          [strandId]: response.data,
        }));
      } catch (error) {
        console.error('Failed to fetch strand counts:', error);
      }
    };

    // Handle field changes
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
      const { name, value } = e.target;
      if (name === 'strandId' && value) {
        fetchStrandCounts(value);
      }
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleDateChange = (field: 'startTime' | 'endTime') => (newValue: Date | null) => {
      setFormData((prev) => ({
        ...prev,
        [field]: newValue,
      }));
    };
    
    const handleEventCalculatorSubmit = (calculatedCosts: CalculatedCosts) => {
      setFormData(prev => ({
        ...prev,
        costs: {
          ...prev.costs,
          travel: {
            flights: calculatedCosts.travel.flights,
            groundTransport: calculatedCosts.travel.groundTransport,
            other: calculatedCosts.travel.other,
            total: calculatedCosts.travel.total
          },
          accommodation: calculatedCosts.accommodation,
          perDiem: calculatedCosts.perDiem,
          equipment: calculatedCosts.equipment,
          technical: {
            soundEngineer: calculatedCosts.technical.soundEngineer || 0,
            lightingEngineer: calculatedCosts.technical.lightingEngineer || 0,
            backline: calculatedCosts.technical.backline || 0,
            other: calculatedCosts.technical.other || 0,
            total: calculatedCosts.technical.total || 0
          },
          additional: {
            marketing: calculatedCosts.additional.marketing,
            insurance: calculatedCosts.additional.insurance,
            visas: calculatedCosts.additional.visas,
            total: calculatedCosts.additional.total
          },
          otherCosts: calculatedCosts.otherCosts,
          summary: calculatedCosts.summary,
          currency: calculatedCosts.currency
        }
      }));
      setCalculatedCosts(calculatedCosts);
      setEventCalculatorOpen(false);
    };

    const handleNestedChange = (category: string, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFormData((prev) => ({
        ...prev,
        costs: {
          ...prev.costs,
          [category]:
            typeof prev.costs[category as keyof typeof prev.costs] === 'object'
              ? {
                  ...(prev.costs[category as keyof typeof prev.costs] as Record<string, any>),
                  [field]: value,
                }
              : value,
        },
      }));
    };

    // Handle ticket configuration
    const handleTicketConfigSave = (config: { ticketTypes: TicketType[]; projections: any }) => {
      setFormData((prev) => ({
        ...prev,
        ticketing: {
          ...prev.ticketing,
          config,
        },
      }));
      setTicketConfigOpen(false);
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        const submissionData: Partial<CleanSubmissionData> = {
          ...formData,
          ...(bandType === 'existing' && formData.band
            ? { band: formData.band, simpleBandName: undefined }
            : { band: undefined, simpleBandName: formData.simpleBandName || formData.newBandName }),
          strandId: formData.strandId || undefined,
          eventId: formData.eventId || undefined,
          venue: formData.venue || undefined,
          costs: {
            ...formData.costs,
            bandFee: formData.fee,
          },
        };

        const cleanedData = Object.entries(submissionData).reduce<Partial<CleanSubmissionData>>((acc, [key, value]) => {
          if (value !== '' && value !== undefined && value !== null) {
            acc[key as keyof CleanSubmissionData] = value as any;
          }
          return acc;
        }, {});

        console.log('Submitting data:', cleanedData);
        onSubmit(cleanedData as unknown as FormData);
          } catch (error) {
        console.error('Error submitting form:', error);
        setError('Failed to submit form');
      }
    };

    const costSummary = {
      bandFee: Number(formData.fee),
      totalWithoutFee: formData.costs.summary.totalCosts,
      contingency: formData.costs.summary.contingency,
      totalWithFee: formData.costs.summary.grandTotal,
    };

    const selectedVenue = venues.find((v) => v._id === formData.venue) || null;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            p: 4,
            '& .MuiGrid-item': { mb: 2 },
            '& .MuiFormControl-root': { mb: 2 },
            '& .MuiTypography-h6': { mb: 2, mt: 2 },
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <ProgrammingSection
            formData={formData}
            strands={strands}
            events={events}
            venues={venues}
            strandCounts={strandCounts}
            onFieldChange={handleChange}
          />

          <BandSection
            formData={formData}
            bandType={bandType}
            bands={bands}
            onBandTypeChange={setBandType}
            onFieldChange={handleChange}
            onDateChange={handleDateChange}
            onFeeCalculatorOpen={() => setFeeCalculatorOpen(true)}
            isEdit={isEdit}
          />

          <CostsSection onEventCalculatorOpen={() => setEventCalculatorOpen(true)} calculatedCosts={calculatedCosts} />

          <TicketingSection
            venue={selectedVenue}
            ticketConfig={formData.ticketing?.config}
            performanceId={initialData?._id || ''}
            onConfigureTickets={() => setTicketConfigOpen(true)}
            costSummary={costSummary}
          />

          {formData.ticketConfigId && selectedVenue && (
            <RevenueProjections
              ticketConfigId={formData.ticketConfigId}
              venue={selectedVenue}
              costs={costSummary.totalWithFee}
              contingency={costSummary.contingency}
            />
          )}

          {selectedVenue && (
            <TicketConfigDialog
              open={ticketConfigOpen}
              onClose={() => setTicketConfigOpen(false)}
              venue={selectedVenue}
              onSave={handleTicketConfigSave}
              performanceId={initialData?._id || ''}
              strandId={formData.strandId}
              eventId={formData.eventId}
              existingConfig={
                formData.ticketing?.config
                  ? { ticketTypes: formData.ticketing.config.ticketTypes }
                  : undefined
              }
            />
          )}

          <Dialog open={feeCalculatorOpen} onClose={() => setFeeCalculatorOpen(false)} maxWidth="sm" fullWidth>
            <ShowRunnrCalc
              initialValue={Number(formData.fee) || 0}
              onSubmit={(calculatedFee) => {
                setFormData((prev) => ({
                  ...prev,
                  fee: calculatedFee.toString(),
                }));
                setFeeCalculatorOpen(false);
              }}
              onClose={() => setFeeCalculatorOpen(false)}
            />
          </Dialog>

          {eventCalculatorOpen && (
  <EventCalculator
    onClose={() => setEventCalculatorOpen(false)}
    onSubmit={handleEventCalculatorSubmit}
    defaultCurrency="EUR"
    initialData={calculatedCosts || undefined}  // Fix here
  />
)}

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              {isEdit ? 'Update Performance' : 'Create Performance'}
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    );
  };

  export default PerformanceForm;