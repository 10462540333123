import React, { useEffect } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Breadcrumbs,
  Link 
} from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import TourScheduler from '../components/performance/TourScheduler'; // Import the scheduler component we created

const TourSchedulePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const performanceData = location.state?.scheduleData?.performanceData;
  
  console.log('Page received performance data:', performanceData);
    
  useEffect(() => {
    if (!performanceData) {
      console.warn('No performance data received in page');
    }
  }, [performanceData]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Header Bar */}
      <Paper 
        square 
        elevation={2} 
        sx={{ 
          position: 'sticky', 
          top: 0, 
          zIndex: 1100,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ 
            py: 2, 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2 
          }}>
            <IconButton onClick={handleBack} size="small">
              <ArrowLeft />
            </IconButton>
            
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/admin">
                Admin
              </Link>
              <Link color="inherit" href="/admin/performances">
                Performances
              </Link>
              <Typography color="text.primary">Tour Schedule</Typography>
            </Breadcrumbs>
          </Box>
        </Container>
      </Paper>

      {/* Main Content */}
      <Container maxWidth="xl" sx={{ mt: 3, mb: 8 }}>
        <TourScheduler performanceData={performanceData} />
      </Container>
    </Box>
  );
};

export default TourSchedulePage;