import React, { useEffect } from 'react';
import {
  Box,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  ListSubheader,
  Grid,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Search, X as XIcon, Filter, Clock, User } from 'lucide-react';
import { Personnel } from '../../../../types/Personnel';
import { TaskCategory, TASK_CATEGORIES } from '../types/taskTypes';
export const categoryGroups = {
  'Task Types': [
    { value: 'repair', label: 'Repair' },
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'setup', label: 'Setup' },
    { value: 'improvement', label: 'Improvement' },
    { value: 'general', label: 'General' },
    { value: 'organisation', label: 'Organisation' },
  ],
  'Areas': [
    { value: 'bar_area', label: 'Bar Area' },
    { value: 'stage', label: 'Stage' },
    { value: 'cooler_general', label: 'Cooler General' },
    { value: 'cooler_space', label: 'Cooler Space' },
    { value: 'notice_board', label: 'Notice Board' },
    { value: 'outside_press', label: 'Outside Press' },
    { value: 'presses_storage', label: 'Presses Storage' },
  ],
  'Technical': [
    { value: 'lighting', label: 'Lighting' },
    { value: 'sound', label: 'Sound' },
    { value: 'projectors', label: 'Projectors' },
    { value: 'videography', label: 'Videography' },
    { value: 'heating', label: 'Heating' },
  ],
  'Experience & Operations': [
    { value: 'audience_experience', label: 'Audience Experience' },
    { value: 'throughput', label: 'Throughput' },
    { value: 'rehearsals', label: 'Rehearsals' },
    { value: 'security', label: 'Security' },
  ],
} as const;

export const TASK_STATUSES = {
  todo: 'To Do',
  in_progress: 'In Progress',
  blocked: 'Blocked',
  done: 'Done',
} as const;
export interface TaskFilters {
  search: string;
  assignedTo: string;
  status: string;
  priority: string;
  category: TaskCategory | '';
  estimatedHours: {
    min: number | '';
    max: number | '';
  };
  dueDate: Date | null;
}

interface TaskBoardFiltersProps {
  filters: TaskFilters;
  onFilterChange: (newFilters: TaskFilters) => void;
  onClearFilters: () => void;
  personnel: Personnel[];
  activeFilterCount: number;
  isLoading?: boolean;
}

// Helper function to get category icon and color
const getCategoryDisplay = (categoryValue: string) => {
  if (!categoryValue) return null;
  const category = categoryValue as TaskCategory;
  if (TASK_CATEGORIES[category]) {
    const { icon: Icon, color } = TASK_CATEGORIES[category];
    return { Icon, color };
  }
  return null;
};

const TaskBoardFilters: React.FC<TaskBoardFiltersProps> = ({
  filters,
  onFilterChange,
  onClearFilters,
  personnel,
  activeFilterCount,
  isLoading = false,
}) => {
  const handleFilterChange = (field: keyof TaskFilters, value: any) => {
    onFilterChange({
      ...filters,
      [field]: value,
    });
  };

  const handleEstimatedHoursChange = (type: 'min' | 'max', value: string) => {
    const numValue = value === '' ? '' : Number(value);
    onFilterChange({
      ...filters,
      estimatedHours: {
        ...filters.estimatedHours,
        [type]: numValue,
      },
    });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.debug('Current filters:', filters);
      console.debug('Current personnel:', personnel);
    }
  }, [filters, personnel]);

  const renderPersonnelOption = (person: Personnel) => (
    <MenuItem key={person.id || person._id} value={person.id || person._id}>
      <Stack direction="row" spacing={1} alignItems="center">
        <User size={16} />
        <span>{person.name}</span>
      </Stack>
    </MenuItem>
  );

  return (
    <Box className="bg-white rounded-lg shadow p-4 mb-6">
      <Grid container spacing={2}>
        {/* Search Row */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Search in task titles and descriptions">
              <TextField
                fullWidth
                placeholder="Search tasks..."
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  ),
                  endAdornment: filters.search && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => handleFilterChange('search', '')}
                      >
                        <XIcon size={16} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            {activeFilterCount > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={onClearFilters}
                startIcon={<Filter size={16} />}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Clear Filters ({activeFilterCount})
              </Button>
            )}
          </Stack>
        </Grid>

        {/* Main Filters Row */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Assigned To</InputLabel>
            <Select
              value={filters.assignedTo}
              label="Assigned To"
              onChange={(e) => handleFilterChange('assignedTo', e.target.value)}
              startAdornment={<User size={16} className="ml-2 mr-1" />}
              disabled={isLoading}
            >
              <MenuItem value="">All</MenuItem>
              {personnel.map(renderPersonnelOption)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              label="Status"
              onChange={(e) => handleFilterChange('status', e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {Object.entries(TASK_STATUSES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Priority</InputLabel>
            <Select
              value={filters.priority}
              label="Priority"
              onChange={(e) => handleFilterChange('priority', e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="urgent">
                <Chip size="small" color="error" label="Urgent" />
              </MenuItem>
              <MenuItem value="high">
                <Chip size="small" color="warning" label="High" />
              </MenuItem>
              <MenuItem value="medium">
                <Chip size="small" color="default" label="Medium" />
              </MenuItem>
              <MenuItem value="low">
                <Chip size="small" color="success" label="Low" />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Category</InputLabel>
            <Select
              value={filters.category}
              label="Category"
              onChange={(e) => handleFilterChange('category', e.target.value)}
              renderValue={(selected) => {
                if (!selected) return 'All';
                const categoryDisplay = getCategoryDisplay(selected);
                const allCategories = Object.values(categoryGroups).flat();
                const selectedCategory = allCategories.find(cat => cat.value === selected);
                
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {categoryDisplay && (
                      <categoryDisplay.Icon
                        sx={{ color: categoryDisplay.color, fontSize: 20 }}
                      />
                    )}
                    <Typography>
                      {selectedCategory?.label || selected}
                    </Typography>
                  </Box>
                );
              }}
            >
              <MenuItem value="">All</MenuItem>
              {Object.entries(categoryGroups).map(([groupName, categories]) => (
                <React.Fragment key={groupName}>
                  <ListSubheader>{groupName}</ListSubheader>
                  {categories.map(({ value, label }) => {
                    const categoryDisplay = getCategoryDisplay(value);
                    return (
                      <MenuItem 
                        key={value} 
                        value={value}
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        {categoryDisplay && (
                          <categoryDisplay.Icon
                            sx={{ color: categoryDisplay.color, fontSize: 20 }}
                          />
                        )}
                        <Typography>{label}</Typography>
                      </MenuItem>
                    );
                  })}
                </React.Fragment>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Time-based Filters Row */}
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Due Before"
              value={filters.dueDate}
              onChange={(date) => handleFilterChange('dueDate', date)}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  InputProps: {
                    endAdornment: filters.dueDate && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => handleFilterChange('dueDate', null)}
                        >
                          <XIcon size={16} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            type="number"
            label="Min Hours"
            value={filters.estimatedHours.min}
            onChange={(e) => handleEstimatedHoursChange('min', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Clock size={16} />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            type="number"
            label="Max Hours"
            value={filters.estimatedHours.max}
            onChange={(e) => handleEstimatedHoursChange('max', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Clock size={16} />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskBoardFilters;