// src/types/mongodb.ts

import type { Strand } from '../types/Strand';

export interface BandRecommendation {
    _id?: string;
    recommenderName: string;
    recommenderEmail: string;
    recommenderCountry: string;
    recommendedFee: number;
    isRecommenderApplicant: boolean;
  
    artistName: string;
    maleMembers: number;
    femaleMembers: number;
    totalMembers: number;
    genderRatio: string;
    performances: string[];
  
    performanceLink?: string;
    youtubeEmbeds?: string[];
    spotifyEmbeds?: string[];
    soundcloudEmbeds?: string[];
    bandcampEmbeds?: string[];
  
    instagramHandle?: string;
    twitterHandle?: string;
    facebookPage?: string;
    tikTokHandle?: string;
  
    contactName: string;
    contactEmail: string;
    contactPhone?: string;
  
    recommendationNotes?: string;
    genres: string[];
    similarArtists?: string[];
  
    availability: {
      from: Date;
      to: Date;
      excludedDates?: Date[];
    };
  
    mainImage?: string;
    additionalImages?: string[];
  
    technicalRider?: {
      stageSize?: string;
      audioRequirements?: string;
      lightingRequirements?: string;
      additionalEquipment?: string[];
    };
  
    financialDetails?: {
      minimumFee?: number;
      preferredCurrency?: string;
      accommodationNeeded?: boolean;
      transportationNeeded?: boolean;
    };
  
    status: 'pending' | 'approved' | 'rejected';
    adminNotes?: string;
    selectedEvent?: string;
  
    createdAt: Date;
    updatedAt: Date;
  }
  
  export interface Venue {
    _id: string;
    // Required Basic Information
    name: string;
    capacity: number;
    location: string;
    rentalCost: number;
    
    // Optional Basic Information
    website?: string;
    description?: string;
  
    // Capacity Details
    capacityDetails?: {
      standing: number;
      seating: number;
      wheelchair: number;
      configurations?: Array<{
        name: string;
        capacity: number;
        description?: string;
      }>;
    };
  
    // Box Office
    boxOffice?: {
      inHouse: boolean;
      systemUsed?: string;
      contactInfo?: {
        phone?: string;
        email?: string;
      };
    };
  
    // Artist Facilities
    facilities?: {
      greenRoom: boolean;
      dressingRooms: number;
      catering: boolean;
      wifi: boolean;
      shower: boolean;
      bathroom: boolean;
      parking: boolean;
    };
  
    // Technical Specifications
    technical?: {
      stage?: {
        width: number;
        depth: number;
        height: number;
      };
      sound?: {
        inHousePA: boolean;
        monitors: number;
        microphones: boolean;
        notes?: string;
      };
      lighting?: {
        basic: boolean;
        notes?: string;
      };
    };
  
    // Access
    access?: {
      loadingBay: boolean;
      loadingNotes?: string;
      parking: boolean;
      wheelchairAccess: boolean;
      lift: boolean;
    };
  
    // Front of House
    frontOfHouse?: {
      bar: boolean;
      merchandise?: {
        allowed: boolean;
        commission: number;
      };
      cloakroom: boolean;
    };
  
    // Contact Information
    contactInfo?: {
      name: string;
      email: string;
      phone?: string;
      technical?: {
        name: string;
        email: string;
        phone?: string;
      };
    };
  
    // Metadata
    createdAt: Date;
    updatedAt: Date;
  }
  
  export enum EventType {
    Concert = 'Concert',
    Series = 'Series',
    Commission = 'Commission',
    Showcase = 'Showcase',
    Festival = 'Festival',
    Residency = 'Residency',
    Clinic = 'Clinic',
    Talk = 'Talk',
    Tour = 'Tour',
    Conference = 'Conference',
    Workshop = 'Workshop',
    MasterClass = 'Master Class',
    AlbumLaunch = 'Album Launch',
    Competition = 'Competition',
    OpenMic = 'Open Mic',
    JamSession = 'Jam Session',
    Benefit = 'Benefit',
    AwardsCeremony = 'Awards Ceremony',
    Podcast = 'Podcast',
    LiveRecording = 'Live Recording',
    StreamingEvent = 'Streaming Event',
    Demo = 'Demo',
    Other = 'Other',
    CollaborationEvent = 'Collaboration Event',
    TradeShow = 'Trade Show',
    Single = 'Single'
  }
  
  export interface Event {
    _id: string;
    name: string;
    eventType: EventType;
    description?: string;
    venue?: string | Venue;
    isOnline: boolean;
    zoomLink?: string;
    date?: Date;
    ticketPrice?: number;
    startDate?: Date;
    endDate?: Date;
    recurringDays?: ('Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday')[];
    status: 'planned' | 'confirmed' | 'cancelled';
    focus: 'Audience' | 'Artist' | 'Industry' | 'Other';
    performances: string[] | Performance[];
    isSeries: boolean;
    seriesStartTime?: Date;
    seriesEndTime?: Date;
    seriesFrequency?: 'weekly' | 'biweekly' | 'monthly';
    isFreeEvent: boolean;
    createdAt: Date;
    updatedAt: Date;
  }

  
  export interface Performance {
    _id: string;  // Remove the duplicate 'id' field
    strandId?: string | Strand;  // Using the proper Strand type
    simpleBandName?: string;
    band: string | BandRecommendation;
    event: string | Event;
    startTime: string;  // Change from Date to string for API compatibility
    endTime: string;    // Change from Date to string for API compatibility
    isArchived: boolean;
    archiveDate?: string;  // Add this field
    archiveReason?: 'pastEvent' | 'cancelled' | 'redundant' | 'other';  // Add this field
    archiveNotes?: string;  // Add this field
    status: 'confirmed' | 'pending' | 'cancelled';
    
    revenue: {
      ticketPrice: number;
      expectedOccupancy: number;
      sponsorship: number;
      merchandiseSales: number;
      otherRevenue: number;
      expectedCapacity?: number; // New field for strand-based performances
    };
    
    costs: {
      contingencyRate: number;
      bandFee: number;
      travel: {
        flights: number;
        groundTransport: number;
        other: number;
      };
      accommodation: {
        singleRooms: number;
        twinRooms: number;
        costPerRoom: number;
        totalNights: number;
      };
      perDiem: {
        dailyRate: number;
        numberOfDays: number;
      };
      technical: {
        soundEngineer: number;
        lightingEngineer: number;
        backline: number;
        other: number;
        total: number;
      },
      equipment: {
        rental: number;
        shipping: number;
      };
      personnel: {
        [staffId: string]: number;
      };

     marketing: number;
    insurance: number;
    visas: number;
      other: number;
    };
    staff: (string | Staff)[];
    contingencyRate: number;
    notes?: string;
    createdAt: Date;
    updatedAt: Date;
  }
  
  export interface Staff {
    _id: string;
    name: string;
    role: string;
    assignedRole: string;
    fee: number;
  }
  export type Personnel = Staff;

  export interface CostBreakdown {
    baseCost: number;
    contingencyAmount: number;
    totalCost: number;
  }
  
  export interface RevenueBreakdown {
    ticketRevenue: number;
    sponsorshipRevenue: number;
    merchandiseRevenue: number;
    otherRevenue: number;
    totalRevenue: number;
  }
  
  export interface PerformanceWithMethods extends Performance {
    calculateTotalCost(): CostBreakdown;
    calculateTotalRevenue(): RevenueBreakdown;
    calculateProfitLoss(): number;
  }
  
  export interface FinancialCalculations {
    calculatePotentialRevenue: (ticketPrice: number, venueCapacity: number, expectedOccupancy: number) => number;
    calculateBandFee: (potentialRevenue: number, feePercentage: number) => number;
    calculateProfit: (potentialRevenue: number, bandFee: number, venueCost: number) => number;
  }
  
  export interface PersonnelAssignment {
    _id: string;
    performanceId: string;
    staffId: string;
    role: string;
    fee: number;
  }
  
  export interface TourSchedule {
    _id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    performances: string[] | Performance[];
    band: string | BandRecommendation;
    status: 'planned' | 'ongoing' | 'completed' | 'cancelled';
    notes?: string;
    createdAt: Date;
    updatedAt: Date;
  }
  
  export interface FinancialReport {
    _id: string;
    performanceId: string;
    totalRevenue: number;
    totalCosts: number;
    profit: number;
    breakdown: {
      revenue: RevenueBreakdown;
      costs: CostBreakdown;
    };
    generatedAt: Date;
  }
  
  export interface User {
    _id: string;
    username: string;
    email: string;
    password: string;
    role: 'admin' | 'guest' | 'judge' | 'artist' | 'domain_user';
    createdAt: Date;
    updatedAt: Date;
  }
  
  export interface AuditLog {
    _id: string;
    userId: string;
    action: string;
    entityType: 'performance' | 'event' | 'band' | 'venue' | 'staff';
    entityId: string;
    changes: object;
    timestamp: Date;
  }