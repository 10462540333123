import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Chip,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  LinearProgress,
  Divider,
  alpha
} from '@mui/material';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSameMonth,
  format,
  isThursday,
  addMonths,
  subMonths,
  isSameDay,
  eachWeekOfInterval,
  addDays
} from 'date-fns';
import { ChevronLeft, ChevronRight, Edit2, Trash2, Plus, ArrowRight } from 'lucide-react';
import axiosInstance from '../utils/axiosConfig';
import { Programming } from '../types/ConcertTypes';
import PerformanceConfigDialog from './PerformanceConfigDialog';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


interface CalendarDay {
  date: Date;
  isCurrentMonth: boolean;
  hasEvent: boolean;
  event?: Programming;
}

interface ScheduleViewProps {
    onEdit: (program: Programming) => void;
    onDelete: (program: Programming) => void;
    onAddNew: (date: Date) => void;  // Add this prop
    onCreatePerformance: (program: Programming) => void;  // New prop

  }

const STATUS_COLORS = {
  BOOKED: '#66bb6a',
  PROVISIONAL: '#FFB300',
  CHECKING: '#42a5f5',
  AVAILABLE: '#ef5350'
} as const;

const CALENDAR_COLORS = {
  BOOKED: alpha('#66bb6a', 0.15),
  PROVISIONAL: alpha('#FFB300', 0.2),
  CHECKING: alpha('#42a5f5', 0.15),
  AVAILABLE: alpha('#ef5350', 0.15)
} as const;

const ScheduleView: React.FC<ScheduleViewProps> = ({ onEdit, onDelete, onAddNew, onCreatePerformance }) => {  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [events, setEvents] = useState<Programming[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Programming | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedThursday, setExpandedThursday] = useState<string | null>(null);
  const navigate = useNavigate();
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
const [selectedProgramming, setSelectedProgramming] = useState<Programming | null>(null);


  useEffect(() => {
    fetchEvents();
  }, [currentDate]);

// Remove the misleading error message and fix button logic
const fetchEvents = async () => {
  try {
    setLoading(true);
    const response = await axiosInstance.get('/api/programmings/month', {
      params: {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
    });

    // Initialize all events with hasPerformance: false
    const eventsWithPerformanceStatus = await Promise.all(
      response.data.map(async (event: Programming) => {
        try {
          const perfResponse = await axiosInstance.get(`/api/performances/by-programming/${event._id}`);
          // Only set hasPerformance: true if we get a valid performance back
          return {
            ...event,
            hasPerformance: !!(perfResponse.data?.data?._id)
          };
        } catch {
          // If the API call fails, assume no performance exists
          return { ...event, hasPerformance: false };
        }
      })
    );

    setEvents(eventsWithPerformanceStatus);
    setError(null); // Clear any existing errors
  } catch (err) {
    // Only set error if the main programming fetch fails
    console.error('Error fetching programming data:', err);
    setError('Unable to load programming data');
  } finally {
    setLoading(false);
  }
};


  const getThursdaysInMonth = (): Date[] => {
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    const weeks = eachWeekOfInterval({ start, end });
    return weeks.map(week => {
      let thursday = addDays(week, 4);
      return thursday;
    }).filter(date => isSameMonth(date, currentDate));
  };

  const getCalendarDays = (): CalendarDay[][] => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);
    const start = startOfWeek(monthStart);
    const end = endOfWeek(monthEnd);
    
    const days = eachDayOfInterval({ start, end }).map(date => {
      const event = events.find(e => e.date && isSameDay(new Date(e.date), date));
      return {
        date,
        isCurrentMonth: isSameMonth(date, currentDate),
        hasEvent: !!event,
        event
      };
    });

    // Split days into weeks
    const weeks: CalendarDay[][] = [];
    let week: CalendarDay[] = [];

    days.forEach((day) => {
      week.push(day);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    });

    return weeks;
  };

  const handlePreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const handleDateClick = (day: CalendarDay) => {
    if (!isThursday(day.date)) return;
    setSelectedDate(day.date);
    setSelectedEvent(day.event || null);
  };

  const getStatusColor = (status: string | undefined): string => {
    if (!status) return STATUS_COLORS.AVAILABLE;
    return STATUS_COLORS[status as keyof typeof STATUS_COLORS] || STATUS_COLORS.AVAILABLE;
  };

  const getCalendarColor = (status: string | undefined): string => {
    if (!status) return CALENDAR_COLORS.AVAILABLE;
    return CALENDAR_COLORS[status as keyof typeof CALENDAR_COLORS] || CALENDAR_COLORS.AVAILABLE;
  };

  const renderCalendarHeader = () => (
    <>
        {/* Quick Month Links */}
        <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        mb: 3,
        overflowX: 'auto',
        gap: 1
      }}
    >
      {[
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ].map((month, index) => (
        <Button
          key={month}
          size="small"
          variant={currentDate.getMonth() === index ? "contained" : "text"}
          onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), index, 1))}
          sx={{ 
            minWidth: '40px',
            px: 1,
            color: currentDate.getMonth() === index ? 'white' : 'text.secondary'
          }}
        >
          {month}
        </Button>
      ))}
    </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ color: 'text.primary' }}>
          {format(currentDate, 'MMMM yyyy')}
        </Typography>
        <Box>
          <IconButton onClick={handlePreviousMonth} sx={{ color: 'text.primary' }}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth} sx={{ color: 'text.primary' }}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Grid container spacing={1} sx={{ mb: 1 }}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <Grid item xs key={day}>
            <Typography
              align="center"
              sx={{ 
                fontWeight: 'bold', 
                color: 'text.secondary',
                fontSize: '0.875rem'
              }}
            >
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );

  const renderCalendarGrid = () => (
    <Box>
      {getCalendarDays().map((week, weekIndex) => (
        <Grid container spacing={1} key={weekIndex} sx={{ mb: 1 }}>
          {week.map((day, dayIndex) => (
            <Grid item xs key={`${weekIndex}-${dayIndex}`}>
              <Paper
                sx={{
                  p: 1,
                  height: 80,
                  bgcolor: !day.isCurrentMonth ? alpha('#1e1e1e', 0.5) :
                          day.event ? getCalendarColor(day.event.status) : 
                          isThursday(day.date) ? CALENDAR_COLORS.AVAILABLE : 'background.paper',
                  opacity: !day.isCurrentMonth ? 0.5 : 1,
                  cursor: isThursday(day.date) ? 'pointer' : 'default',
                  border: selectedDate && isSameDay(day.date, selectedDate) ? 
                         '2px solid #42a5f5' : 'none',
                  '&:hover': isThursday(day.date) ? {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s'
                  } : {},
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  color: 'text.primary'
                }}
                onClick={() => handleDateClick(day)}
              >
                <Typography align="center" sx={{ color: 'inherit' }}>
                  {format(day.date, 'd')}
                </Typography>
                {day.event && (
  <Tooltip title={`${day.event.artistName} - ${day.event.status}`}>
    <Box sx={{ mt: 0.5 }}>
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'inherit',
          fontWeight: 500
        }}
      >
        {day.event.artistName}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'inherit',
          fontSize: '0.7rem'
        }}
      >
        {day.event.genre}
      </Typography>
    </Box>
  </Tooltip>
)}
              </Paper>
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );

  const renderAllThursdays = () => {
    const thursdays = getThursdaysInMonth();
    
    return (
      <Box sx={{ mt: 4 }}>
    <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
      Thursdays in {format(currentDate, 'MMMM')}
    </Typography>
        <Grid container spacing={2}>
          {thursdays.map((thursday) => {
            const event = events.find(e => e.date && isSameDay(new Date(e.date), thursday));
            const dateKey = format(thursday, 'yyyy-MM-dd');
            const isExpanded = expandedThursday === dateKey;
            
            return (
              <Grid item xs={12} key={dateKey}>
                <Paper
                  onClick={() => setExpandedThursday(isExpanded ? null : dateKey)}
                  sx={{
                    p: 2,
                    bgcolor: event ? getCalendarColor(event.status) : CALENDAR_COLORS.AVAILABLE,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: alpha(event ? getCalendarColor(event.status) : CALENDAR_COLORS.AVAILABLE, 0.8)
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {format(thursday, 'EEEE, MMMM do')}
                    </Typography>
                    
                    {event ? (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                          {event.artistName}
                        </Typography>
                        <Chip
                          label={event.status}
                          size="small"
                          sx={{
                            bgcolor: getStatusColor(event.status),
                            color: event.status === 'PROVISIONAL' ? 'text.primary' : 'white'
                          }}
                        />
                        <IconButton size="small" onClick={(e) => { e.stopPropagation(); onEdit(event); }}>
                          <Edit2 size={16} />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          color="error" 
                          onClick={(e) => { e.stopPropagation(); onDelete(event); }}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                        {event.status === 'BOOKED' && (
  <Button
    size="small"
    variant="contained"
    color="primary"
    startIcon={<ArrowRight />}
    onClick={async (e) => {
      e.stopPropagation();
      try {
        const response = await axiosInstance.get(`/api/performances/by-programming/${event._id}`);
        
        // Check if we have a valid performance in the response
        if (response.data?.data?._id) {
          navigate(`/admin/performances/${response.data.data._id}`);
        } else {
          // No performance exists yet, show create dialog
          setSelectedProgramming(event);
          setConfigDialogOpen(true);
        }
      } catch {
        // Any error means we should create a new performance
        setSelectedProgramming(event);
        setConfigDialogOpen(true);
      }
    }}
    sx={{
      ml: 1,
      bgcolor: event.hasPerformance ? alpha('#4CAF50', 0.9) : alpha('#1976D2', 0.9),
      '&:hover': {
        bgcolor: event.hasPerformance ? alpha('#4CAF50', 1) : alpha('#1976D2', 1),
      }
    }}
  >
    {event.hasPerformance ? 'View Performance' : 'Create Performance'}
  </Button>
)}
 </Box>
 ) : (
<Button
  size="small"
  variant="contained"
  startIcon={<Plus size={16} />}
  onClick={(e) => { 
    e.stopPropagation(); 
    onAddNew(thursday);  // This will handle both setting the date and navigation
  }}
>
  Add Programming
</Button>
                    )}
                  </Box>
  
                  {isExpanded && event && (
                    <Box sx={{ mt: 2, pt: 2, borderTop: 1, borderColor: 'divider' }}>
                      <Grid container spacing={3}>

                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Genre</Typography>
                          <Typography variant="body1" color="text.primary">{event.genre}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Gender</Typography>
                          <Typography variant="body1" color="text.primary">{event.ensembleMakeup}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Primary Contact</Typography>
                          <Typography variant="body1" color="text.primary">{event.primaryContact}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Email</Typography>
                          <Typography variant="body1" color="text.primary">{event.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Phone</Typography>
                          <Typography variant="body1" color="text.primary">{event.phone}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Expected Draw</Typography>
                          <Typography variant="body1" color="text.primary">{event.expectedDraw}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Price</Typography>
                          <Typography variant="body1" color="text.primary">€{event.pricePoint}</Typography>
                        </Grid>
                        {event.notes && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary">Notes</Typography>
                            <Typography variant="body2" color="text.primary">{event.notes}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const renderDetailsPanel = () => (
    <Card sx={{ mt: 3, bgcolor: 'background.paper' }}>
      <CardContent>
        {selectedDate ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                {format(selectedDate, 'EEEE, MMMM do, yyyy')}
              </Typography>
              {selectedEvent ? (
                <Box>
                  <IconButton size="small" onClick={() => onEdit(selectedEvent)}>
                    <Edit2 size={18} />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    color="error" 
                    onClick={() => onDelete(selectedEvent)}
                  >
                    <Trash2 size={18} />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  startIcon={<Plus size={18} />}
                  variant="contained"
                  size="small"
                >
                  Add Programming
                </Button>
              )}
            </Box>

            {selectedEvent ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">Artist/Band</Typography>
                  <Typography variant="body1" color="text.primary">{selectedEvent.artistName}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">Genre</Typography>
                  <Typography variant="body1" color="text.primary">{selectedEvent.genre}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Primary Contact</Typography>
                          <Typography variant="body1" color="text.primary">{selectedEvent.primaryContact}</Typography>
                        </Grid>
                          <Typography variant="subtitle2" color="text.secondary">Email</Typography>
                          <Typography variant="body1" color="text.primary">{selectedEvent.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" color="text.secondary">Phone</Typography>
                          <Typography variant="body1" color="text.primary">{selectedEvent.phone}</Typography>
                        </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">Ensemble</Typography>
                  <Typography variant="body1" color="text.primary">{selectedEvent.ensembleMakeup}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">Expected Draw</Typography>
                  <Typography variant="body1" color="text.primary">{selectedEvent.expectedDraw}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Chip
                      label={selectedEvent.status}
                      sx={{
                        bgcolor: getStatusColor(selectedEvent.status),
                        color: selectedEvent.status === 'PROVISIONAL' ? 'text.primary' : 'white'
                      }}
                    />
                    <Typography variant="h6" color="text.primary">€{selectedEvent.pricePoint}</Typography>
                  </Box>
                </Grid>
                {selectedEvent.notes && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary">Notes</Typography>
                    <Typography variant="body2" color="text.primary">{selectedEvent.notes}</Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography color="text.secondary" align="center">
                No programming scheduled for this date
              </Typography>
            )}
          </>
        ) : (
          <Typography color="text.secondary" align="center">
            Select a Thursday to view or add programming
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3, bgcolor: 'background.paper' }}>
        {loading && <LinearProgress sx={{ mb: 2 }} />}
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        {renderCalendarHeader()}
        {renderCalendarGrid()}
        <Divider sx={{ my: 4 }} />
        {renderAllThursdays()}
        {renderDetailsPanel()}

              {/* Performance Config Dialog */}
              <PerformanceConfigDialog
          open={configDialogOpen}
          onClose={() => {
            setConfigDialogOpen(false);
            setSelectedProgramming(null);
          }}
          programming={selectedProgramming}
          onConfirm={async (configData) => {
            try {
              const response = await axiosInstance.post(
                `/api/programmings/${selectedProgramming?._id}/create-performance`,
                configData
              );
              // Update local state to show performance has been created
              setEvents(prevEvents => 
                prevEvents.map(e => 
                  e._id === selectedProgramming?._id ? { ...e, hasPerformance: true } : e
                )
              );
              // Close dialog
              setConfigDialogOpen(false);
              setSelectedProgramming(null);
              // Navigate to the new performance
              navigate(`/admin/performances/${response.data._id}`);
            } catch (error) {
              console.error('Error creating performance:', error);
              setError('Failed to create performance');
            }
          }}
        />
      </Paper>
    </Box>
  );
};

export default ScheduleView;