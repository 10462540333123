import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Stack,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  Snackbar,
  Alert,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  CalendarToday,
  Save,
  Preview,
  EuroSymbol,
  FlightTakeoff,
  DirectionsCar,
  Hotel,
  RestaurantMenu
} from '@mui/icons-material';
import { contractServices } from '../../services/contractServices';
import { Venue } from '../../types/mongodb';
import { BandActivityStatus, BandRecommendation } from '../../types/BandRecommendations';
import debounce from 'lodash/debounce';
import { DocumentService } from '../../services/DocumentService';
import ContractPreview from './ContractGenerator/ContractPreview';
import InternalUseSection from './InternalUseSection';



interface IMCContractFormProps {
    onSubmit?: () => void;
    initialData?: FormData;
    mode?: 'create' | 'edit';
    contractPath?: string;  // Add this
  }

interface FormData {
  id?: string;  // Add ID to allow identifying the contract in MongoDD
  originalContractId?: string;  // Add originalContractId
  contractNumber?: string;  // Add contractNumber
  performanceId?: string;  // Add performanceId
  mode?: 'create' | 'edit';  // Add mode
  Contact: string;
  Item: string;
  Email: string;
  Phone: string;
  'Event Date': Date | null;
  Venue: string;
  Address: string;
  'Soundcheck Time': Date | null;
  'Soundcheck Duration': string;
  Doors: Date | null;
  'Stage Time': Date | null;
  'Performance Type': string;
  'Min Duration': string;
  Fees: string;
  'Payment Type': string;
  'Int\'l Flights': string;
  'Ground Transport': string;
  'Accommodation Type': string;
  'Accommodation Qty': string;
  'PD Amount': string;
  'PD Quantity': string;
  'PD Days': string;
  'Activities Master': string;
  'IMC Code': string;
  contractPath?: string;  // Add contractPath
}

const PERFORMANCE_TYPES = [
  'Full Concert',
  'Support',
  'Workshop',
  'Masterclass',
  'Other'
];

const ACCOMMODATION_TYPES = [
  'Single Room',
  'Twin Room',
  'Double Room',
  'Not Required'
];

const IMCContractForm: React.FC<IMCContractFormProps> = ({ 
    onSubmit, 
    initialData,
    mode = 'create',
    contractPath 
  }) => {
    const location = useLocation();

    useEffect(() => {
        if (initialData) {
          console.log('Form initialized with data:', initialData);
        }
      }, [initialData]);
    const [formData, setFormData] = useState<FormData>(() => {
      if (initialData) {
        console.log('Setting initial form data:', initialData);
        return {
          ...initialData,
          'Event Date': initialData['Event Date'] ? new Date(initialData['Event Date']) : null,
          'Soundcheck Time': initialData['Soundcheck Time'] ? new Date(initialData['Soundcheck Time']) : null,
          Doors: initialData.Doors ? new Date(initialData.Doors) : null,
          'Stage Time': initialData['Stage Time'] ? new Date(initialData['Stage Time']) : null,
        };
      }
      // Default empty form data (create mode)
      return {
        Contact: '',
        Item: '',
        Email: '',
        Phone: '',
        'Event Date': null,
        Venue: '',
        Address: '',
        'Soundcheck Time': null,
        'Soundcheck Duration': '',
        Doors: null,
        'Stage Time': null,
        'Performance Type': '',
        'Min Duration': '',
        Fees: '',
        'Payment Type': '',
        'Int\'l Flights': '',
        'Ground Transport': '',
        'Accommodation Type': '',
        'Accommodation Qty': '',
        'PD Amount': '',
        'PD Quantity': '',
        'PD Days': '',
        'Activities Master': '',
        'IMC Code': '',
      };
    });
  const [venues, setVenues] = useState<Venue[]>([]);
  const [artists, setArtists] = useState<BandRecommendation[]>([]);
  const [loading, setLoading] = useState({
    venues: false,
    artists: false
  });
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [selectedArtist, setSelectedArtist] = useState<BandRecommendation | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  useEffect(() => {
    if (initialData) {
      // Set selected venue if it exists in the venues list
      const venue = venues.find(v => v.name === initialData.Venue);
      if (venue) {
        setSelectedVenue(venue);
      }
  
      // Set selected artist with all required BandRecommendation properties
      if (initialData.Item) {
        const defaultArtist: BandRecommendation = {
          artistName: initialData.Item,
          recommenderName: '',
          recommenderEmail: '',
          recommenderCountry: '',
          recommendedFee: 0,
          isRecommenderApplicant: false,
          maleMembers: 0,
          femaleMembers: 0,
          totalMembers: 0,
          genderRatio: '',
          performances: [],
          instrumentation: [],
          about: '',
          activityStatus: BandActivityStatus.UNKNOWN,
          activityStatusDate: new Date(),
          statusHistory: [],
          performanceLink: '',
          youtubeEmbeds: [],
          spotifyEmbeds: [],
          soundcloudEmbeds: [],
          bandcampEmbeds: [],
          contactName: '',
          contactEmail: '',
          recommendationNotes: '',
          genres: [],
          availability: {
            from: new Date(),
            to: new Date(),
          },
          mainImage: '',
          createdAt: new Date(),
          updatedAt: new Date(),
          status: 'pending'
        };
        
        setSelectedArtist(defaultArtist);
      }
    }
  }, [initialData, venues]);


  // Update useEffect for venues
  useEffect(() => {
    const fetchVenues = async () => {
      setLoading(prev => ({ ...prev, venues: true }));
      try {
        const venueData = await contractServices.getVenues();
        setVenues(venueData); // Now fully typed with all required fields
      } catch (error) {
        console.error('Error loading venues:', error);
        setVenues([]); // Empty array on error
      } finally {
        setLoading(prev => ({ ...prev, venues: false }));
      }
    };

    fetchVenues();
  }, []);

  // Add this useEffect after your existing useEffects
useEffect(() => {
    // Check if we have performance data from navigation state
    if (location.state?.initialData && location.state?.source === 'performance') {
      const performanceData = location.state.initialData;
      
      // Set form data from performance
      setFormData(prev => ({
        ...prev,
        ...performanceData,
        // Ensure dates are properly converted
        'Event Date': performanceData['Event Date'] ? new Date(performanceData['Event Date']) : null,
        'Soundcheck Time': performanceData['Soundcheck Time'] ? new Date(performanceData['Soundcheck Time']) : null,
        'Stage Time': performanceData['Stage Time'] ? new Date(performanceData['Stage Time']) : null,
        Doors: performanceData.Doors ? new Date(performanceData.Doors) : null,
        // Set default payment type if not provided
        'Payment Type': performanceData['Payment Type'] || 'bank-transfer',
        // Store the reference to the original performance
        performanceId: performanceData.performanceId
      }));
  
      // If we have band data, set selected artist
      if (performanceData.band) {
        setSelectedArtist(performanceData.band);
      }
  
      // If we have venue data, set selected venue
      if (performanceData.venue) {
        setSelectedVenue(performanceData.venue);
      }
    }
  }, [location.state]);
    
  // Update venue handling
  const handleVenueChange = (venue: Venue | null) => {
    setSelectedVenue(venue);
    if (venue) {
      handleInputChange('Venue', venue.name);
      handleInputChange('Address', venue.location || ''); // Add fallback
    } else {
      handleInputChange('Venue', '');
      handleInputChange('Address', '');
    }
  };

  // Debounced search function
  const debouncedArtistSearch = useCallback(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length < 2) return;
      
      setLoading(prev => ({ ...prev, artists: true }));
      try {
        const artistData = await contractServices.getArtists(searchTerm);
        setArtists(artistData);
      } catch (error) {
        console.error('Error searching artists:', error);
        setArtists([]);
      } finally {
        setLoading(prev => ({ ...prev, artists: false }));
      }
    }, 300),
    []
  );

  console.log('Form Data:', {
    data: formData,
    mode: mode,
    originalContractId: initialData?.id
  });
  

  // Update handleGenerateContract for edit mode
  const handleGenerateContract = async () => {
    setIsGenerating(true);
    try {
      // Prepare the contract generation data
      const contractData: FormData = {
        ...formData,
        mode: mode, // Add mode to the data
        originalContractId: mode === 'edit' ? initialData?.id : undefined,
        contractNumber: mode === 'edit' 
          ? (initialData?.contractNumber 
             ? (initialData.contractNumber.includes('-R')
                ? initialData.contractNumber
                : `${initialData.contractNumber}-R01`)
             : undefined)
          : undefined
      };
  
      // Generate the contract
      const contractUrl = await contractServices.generateContract({
        data: formData,
        mode: mode,
        originalContractId: initialData?.id
      });

      // Download the contract
      const blob = await contractServices.downloadContract(contractUrl);
  
      // Handle contract updates for edit mode
      if (mode === 'edit' && initialData?.id) {
        await contractServices.updateContract(initialData.id, {
          ...contractData,
          contractPath: contractUrl
        });
      }
  
      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      // Create filename - use contract number or item, with fallback
      const filename = `${contractData.contractNumber || contractData.Item || 'contract'}_contract.docx`;
      link.setAttribute('download', filename);
      
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
  
      // Set success notification
      setNotification({
        open: true,
        message: mode === 'edit' 
          ? 'Contract revision generated successfully!' 
          : 'Contract generated successfully!',
        severity: 'success'
      });
  
      // Call onSubmit if provided
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      console.error('Error with contract:', error);
      setNotification({
        open: true,
        message: `Failed to ${mode === 'edit' ? 'update' : 'generate'} contract. Please try again.`,
        severity: 'error'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleInputChange = (field: keyof FormData, value: string | Date | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Add this handleSubmit function
  const handleSubmit = async () => {
    try {
      setIsGenerating(true);
      
      // Prepare contract data
      const contractData = {
        ...formData,
        performanceId: formData.performanceId, // Include reference to original performance
        mode: mode,
        originalContractId: mode === 'edit' ? initialData?.id : undefined,
        contractNumber: mode === 'edit' 
          ? (initialData?.contractNumber 
             ? (initialData.contractNumber.includes('-R')
                ? initialData.contractNumber
                : `${initialData.contractNumber}-R01`)
             : undefined)
          : undefined
      };
  
      // Generate the contract
      const contractUrl = await contractServices.generateContract({
        data: contractData,
        mode: mode,
        originalContractId: initialData?.id
      });
  
      // Download the contract
      const blob = await contractServices.downloadContract(contractUrl);
  
      // If we're editing an existing contract or creating a new one,
      // use the updateContract method which handles both cases
      if (initialData?.id || mode === 'edit') {
        await contractServices.updateContract(initialData?.id || '', {
          ...contractData,
          contractPath: contractUrl
        });
      }
  
      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      // Create filename using contract number or item name
      const filename = `${contractData.contractNumber || contractData.Item || 'contract'}_contract.docx`;
      link.setAttribute('download', filename);
      
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
  
      // Show success notification
      setNotification({
        open: true,
        message: mode === 'edit' 
          ? 'Contract revision generated successfully!' 
          : 'Contract generated successfully!',
        severity: 'success'
      });
  
      // Call onSubmit callback if provided
      if (onSubmit) {
        onSubmit();
      }
  
    } catch (error) {
      console.error('Error submitting contract:', error);
      setNotification({
        open: true,
        message: `Failed to ${mode === 'edit' ? 'update' : 'generate'} contract. Please try again.`,
        severity: 'error'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleActivityChange = (value: string) => {
    handleInputChange('Activities Master', value);
  };
  
  const handleIMCCodeChange = (value: string) => {
    handleInputChange('IMC Code', value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Card sx={{ maxWidth: 'lg', mx: 'auto', mt: 3 }}>
        <CardHeader
          title="IMC Artist Contract Details"
          subheader="Fill in the details for the contract"
        />

        <CardContent>
          <Grid container spacing={3}>
            {/* Artist Details Section */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Artist Details
                </Typography>
                
                <TextField
                  fullWidth
                  label="Contact Name"
                  value={formData.Contact}
                  onChange={(e) => handleInputChange('Contact', e.target.value)}
                  placeholder="Artist/Manager Name"
                />

<Autocomplete
  fullWidth
  freeSolo
  value={selectedArtist}
  onChange={(_, newValue) => {
    if (typeof newValue === 'string') {
      handleInputChange('Item', newValue);
    } else if (newValue) {
      handleInputChange('Item', newValue.artistName);
    } else {
      handleInputChange('Item', '');
    }
    setSelectedArtist(newValue as BandRecommendation | null);
  }}
  onInputChange={(_, newInputValue) => {
    debouncedArtistSearch(newInputValue);
  }}
  options={artists}
  getOptionLabel={(option) => {
    if (typeof option === 'string') return option;
    return option.artistName || '';
  }}
  loading={loading.artists}
  renderOption={(props, option) => (
    <li {...props}>
      <Stack spacing={0}>
        <Typography variant="body1">
          {option.artistName}
        </Typography>
        {option.genres && option.genres.length > 0 && (
          <Typography variant="caption" color="text.secondary">
            {option.genres.join(', ')}
          </Typography>
        )}
      </Stack>
    </li>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Artist/Group Name"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading.artists ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )}
/>

                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  value={formData.Email}
                  onChange={(e) => handleInputChange('Email', e.target.value)}
                  placeholder="contact@example.com"
                />

                <TextField
                  fullWidth
                  type="tel"
                  label="Phone"
                  value={formData.Phone}
                  onChange={(e) => handleInputChange('Phone', e.target.value)}
                  placeholder="+353 "
                />
              </Stack>
            </Grid>

            {/* Event Details Section */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Event Details
                </Typography>

                <DatePicker
                  label="Event Date"
                  value={formData['Event Date']}
                  onChange={(date) => handleInputChange('Event Date', date)}
                  slotProps={{ textField: { fullWidth: true } }}
                />

<Autocomplete
    fullWidth
    value={selectedVenue}
    onChange={(_, newValue) => handleVenueChange(newValue)}
    options={venues}
    getOptionLabel={(option) => option.name}
    loading={loading.venues}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Venue"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading.venues ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />

                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Venue Address"
                  value={formData.Address}
                  onChange={(e) => handleInputChange('Address', e.target.value)}
                  placeholder="Venue Address"
                />
              </Stack>
            </Grid>

            {/* Performance Details Section */}
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Performance Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TimePicker
                      label="Soundcheck Time"
                      value={formData['Soundcheck Time']}
                      onChange={(time) => handleInputChange('Soundcheck Time', time)}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Soundcheck Duration"
                      value={formData['Soundcheck Duration']}
                      onChange={(e) => handleInputChange('Soundcheck Duration', e.target.value)}
                      placeholder="e.g., 60 minutes"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TimePicker
                      label="Doors Time"
                      value={formData.Doors}
                      onChange={(time) => handleInputChange('Doors', time)}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TimePicker
                      label="Stage Time"
                      value={formData['Stage Time']}
                      onChange={(time) => handleInputChange('Stage Time', time)}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Performance Type</InputLabel>
                      <Select
                        value={formData['Performance Type']}
                        onChange={(e) => handleInputChange('Performance Type', e.target.value)}
                        label="Performance Type"
                      >
                        {PERFORMANCE_TYPES.map(type => (
                          <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Minimum Duration"
                      value={formData['Min Duration']}
                      onChange={(e) => handleInputChange('Min Duration', e.target.value)}
                      placeholder="e.g., 90 minutes"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            {/* Financial Details Section */}
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Financial Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Fee"
                      value={formData.Fees}
                      onChange={(e) => handleInputChange('Fees', e.target.value)}
                      placeholder="0.00"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EuroSymbol />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Payment Type</InputLabel>
                      <Select
                        value={formData['Payment Type']}
                        onChange={(e) => handleInputChange('Payment Type', e.target.value)}
                        label="Payment Type"
                      >
                        <MenuItem value="bank-transfer">Bank Transfer</MenuItem>
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="check">Check</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            {/* Travel & Accommodation Section */}
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Travel & Accommodation
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="International Flights"
                      value={formData['Int\'l Flights']}
                      onChange={(e) => handleInputChange('Int\'l Flights', e.target.value)}
                      placeholder="Flight details or N/A"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FlightTakeoff />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Ground Transport"
                      value={formData['Ground Transport']}
                      onChange={(e) => handleInputChange('Ground Transport', e.target.value)}
                      placeholder="Transport details or N/A"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DirectionsCar />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Accommodation Type</InputLabel>
                      <Select
                        value={formData['Accommodation Type']}
                        onChange={(e) => handleInputChange('Accommodation Type', e.target.value)}
                        label="Accommodation Type"
                        startAdornment={
                          <InputAdornment position="start">
                            <Hotel />
                          </InputAdornment>
                        }
                      >
                        {ACCOMMODATION_TYPES.map(type => (
                          <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Accommodation Quantity"
                      value={formData['Accommodation Qty']}
                      onChange={(e) => handleInputChange('Accommodation Qty', e.target.value)}
                      placeholder="Number of rooms"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            {/* Per Diem Section */}
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography variant="h6" color="primary">
                  Per Diem
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Per Diem Amount"
                      value={formData['PD Amount']}
                      onChange={(e) => handleInputChange('PD Amount', e.target.value)}
                      placeholder="0.00"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RestaurantMenu />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Per Diem Quantity"
                      value={formData['PD Quantity']}
                      onChange={(e) => handleInputChange('PD Quantity', e.target.value)}
                      placeholder="Number of per diems"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Number of Days"
                      value={formData['PD Days']}
                      onChange={(e) => handleInputChange('PD Days', e.target.value)}
                      placeholder="Number of days"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            {/* Internal Use Section */}
            <Grid item xs={12}>
            <InternalUseSection
  activityValue={formData['Activities Master']}
  imcCodeValue={formData['IMC Code']}
  onActivityChange={(value) => handleInputChange('Activities Master', value)}
  onIMCCodeChange={(value) => handleInputChange('IMC Code', value)}
/>
                  </Grid>
                </Grid>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
      <Button
        variant="outlined"
        startIcon={<Preview />}
        sx={{ mr: 1 }}
        onClick={() => setPreviewOpen(true)}
        >
        Preview
      </Button>
      <Button
  variant="contained"
  startIcon={<Save />}
  onClick={handleSubmit}
  disabled={isGenerating}
>
  {isGenerating ? 'Generating...' : 'Generate Contract'}
</Button>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
 
{/* Add ContractPreview component */}
<ContractPreview
  open={previewOpen}
  onClose={() => setPreviewOpen(false)}
  formData={formData}
  onGenerate={handleGenerateContract}
/>
</CardActions>
      </Card>
    </LocalizationProvider>
  );
};

export default IMCContractForm;