import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Container,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  FormGroup,
  Divider,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

interface VenueFormData {
  name: string;
  capacity: number;
  location: string;
  website?: string;
  description?: string;
  capacityDetails?: {
    standing: number;
    seating: number;
    wheelchair: number;
  };
  boxOffice?: {
    inHouse: boolean;
    systemUsed: string;
    contactInfo: {
      phone: string;
      email: string;
    };
  };
  facilities?: {
    greenRoom: boolean;
    dressingRooms: number;
    catering: boolean;
    wifi: boolean;
    shower: boolean;
    bathroom: boolean;
    parking: boolean;
  };
  technical?: {
    stage: {
      width: number;
      depth: number;
      height: number;
    };
    sound: {
      inHousePA: boolean;
      monitors: number;
      microphones: boolean;
      notes: string;
    };
    lighting: {
      basic: boolean;
      notes: string;
    };
  };
  access?: {
    loadingBay: boolean;
    loadingNotes: string;
    parking: boolean;
    wheelchairAccess: boolean;
    lift: boolean;
  };
  frontOfHouse?: {
    bar: boolean;
    merchandise: {
      allowed: boolean;
      commission: number;
    };
    cloakroom: boolean;
  };
  contactInfo?: {
    name: string;
    email: string;
    phone: string;
    technical: {
      name: string;
      email: string;
      phone: string;
    };
  };
}

const defaultFormData = {
  name: '',
  capacity: 0,
  location: '',
  website: '',
  description: '',
  capacityDetails: {
    standing: 0,
    seating: 0,
    wheelchair: 0
  },
  boxOffice: {
    inHouse: false,
    systemUsed: '',
    contactInfo: {
      phone: '',
      email: ''
    }
  },
  facilities: {
    greenRoom: false,
    dressingRooms: 0,
    catering: false,
    wifi: false,
    shower: false,
    bathroom: false,
    parking: false
  },
  technical: {
    stage: {
      width: 0,
      depth: 0,
      height: 0
    },
    sound: {
      inHousePA: false,
      monitors: 0,
      microphones: false,
      notes: ''
    },
    lighting: {
      basic: false,
      notes: ''
    }
  },
  access: {
    loadingBay: false,
    loadingNotes: '',
    parking: false,
    wheelchairAccess: false,
    lift: false
  },
  frontOfHouse: {
    bar: false,
    merchandise: {
      allowed: false,
      commission: 0
    },
    cloakroom: false
  },
  contactInfo: {
    name: '',
    email: '',
    phone: '',
    technical: {
      name: '',
      email: '',
      phone: ''
    }
  }
};

const VenueForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(defaultFormData);
  const [expandedPanel, setExpandedPanel] = useState<string | false>('basic');


// Helper function to ensure boolean values
const transformBooleans = (data: any) => ({
  ...data,
  facilities: {
    ...data.facilities,
    greenRoom: Boolean(data.facilities?.greenRoom),
    dressingRooms: Number(data.facilities?.dressingRooms || 0),
    catering: Boolean(data.facilities?.catering),
    wifi: Boolean(data.facilities?.wifi),
    shower: Boolean(data.facilities?.shower),
    bathroom: Boolean(data.facilities?.bathroom),
    parking: Boolean(data.facilities?.parking)
  },
  technical: {
    ...data.technical,
    sound: {
      ...data.technical?.sound,
      inHousePA: Boolean(data.technical?.sound?.inHousePA),
      monitors: Number(data.technical?.sound?.monitors || 0),
      microphones: Boolean(data.technical?.sound?.microphones)
    },
    lighting: {
      ...data.technical?.lighting,
      basic: Boolean(data.technical?.lighting?.basic)
    }
  },
  access: {
    ...data.access,
    loadingBay: Boolean(data.access?.loadingBay),
    parking: Boolean(data.access?.parking),
    wheelchairAccess: Boolean(data.access?.wheelchairAccess),
    lift: Boolean(data.access?.lift)
  },
  frontOfHouse: {
    ...data.frontOfHouse,
    bar: Boolean(data.frontOfHouse?.bar),
    cloakroom: Boolean(data.frontOfHouse?.cloakroom),
    merchandise: {
      ...data.frontOfHouse?.merchandise,
      allowed: Boolean(data.frontOfHouse?.merchandise?.allowed),
      commission: Number(data.frontOfHouse?.merchandise?.commission || 0)
    }
  },
  boxOffice: {
    ...data.boxOffice,
    inHouse: Boolean(data.boxOffice?.inHouse)
  },
  staffing: {
    ...data.staffing,
    technicalRequired: Boolean(data.staffing?.technicalRequired),
    securityRequired: Boolean(data.staffing?.securityRequired)
  },
  operations: {
    ...data.operations,
    noise: {
      ...data.operations?.noise,
      restrictions: Boolean(data.operations?.noise?.restrictions)
    }
  }
});

useEffect(() => {
  const fetchVenue = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/venues/${id}`);
        
        // Transform the response data and merge with defaults
        const transformedData = transformBooleans({
          ...defaultFormData,
          ...response.data,
          contactInfo: {
            ...defaultFormData.contactInfo,
            ...(response.data.contactInfo || {}),
            technical: {
              ...defaultFormData.contactInfo.technical,
              ...(response.data.contactInfo?.technical || {})
            }
          },
          technical: {
            ...defaultFormData.technical,
            ...(response.data.technical || {}),
            stage: {
              ...defaultFormData.technical.stage,
              ...(response.data.technical?.stage || {})
            },
            sound: {
              ...defaultFormData.technical.sound,
              ...(response.data.technical?.sound || {})
            },
            lighting: {
              ...defaultFormData.technical.lighting,
              ...(response.data.technical?.lighting || {})
            }
          },
          facilities: {
            ...defaultFormData.facilities,
            ...(response.data.facilities || {})
          },
          frontOfHouse: {
            ...defaultFormData.frontOfHouse,
            ...(response.data.frontOfHouse || {}),
            merchandise: {
              ...defaultFormData.frontOfHouse.merchandise,
              ...(response.data.frontOfHouse?.merchandise || {})
            }
          },
          access: {
            ...defaultFormData.access,
            ...(response.data.access || {})
          },
          capacityDetails: {
            ...defaultFormData.capacityDetails,
            ...(response.data.capacityDetails || {})
          },
          boxOffice: {
            ...defaultFormData.boxOffice,
            ...(response.data.boxOffice || {}),
            contactInfo: {
              ...defaultFormData.boxOffice.contactInfo,
              ...(response.data.boxOffice?.contactInfo || {})
            }
          }
        });

        setFormData(transformedData);
      } catch (err) {
        setError('Failed to fetch venue details');
        console.error('Error fetching venue:', err);
      } finally {
        setLoading(false);
      }
    }
  };
  fetchVenue();
}, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'number' ? parseFloat(value) || 0 : value;
    
    setFormData(prev => {
      const newData = { ...prev };
      const keys = name.split('.');
      let current: any = newData;
      
      // Navigate to the second-to-last key
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      
      // Set the value at the final key
      current[keys[keys.length - 1]] = newValue;
      
      return newData;
    });
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/venues/${id}`, formData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/venues`, formData);
      }
      navigate('/admin/venues');
    } catch (err) {
      console.error('Error saving venue:', err);
      setError('Failed to save venue');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit Venue' : 'Create New Venue'}
        </Typography>
        
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          {/* Basic Information */}
          <Accordion 
            expanded={expandedPanel === 'basic'} 
            onChange={handleAccordionChange('basic')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Venue Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Capacity"
                    name="capacity"
                    type="number"
                    value={formData.capacity}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={formData.website || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                    value={formData.description || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Capacity Details */}
          <Accordion 
            expanded={expandedPanel === 'capacity'} 
            onChange={handleAccordionChange('capacity')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Capacity Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Standing Capacity"
                    name="capacityDetails.standing"
                    type="number"
                    value={formData.capacityDetails?.standing || 0}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Seating Capacity"
                    name="capacityDetails.seating"
                    type="number"
                    value={formData.capacityDetails?.seating || 0}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Wheelchair Spaces"
                    name="capacityDetails.wheelchair"
                    type="number"
                    value={formData.capacityDetails?.wheelchair || 0}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Facilities */}
          <Accordion 
            expanded={expandedPanel === 'facilities'} 
            onChange={handleAccordionChange('facilities')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Facilities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.facilities?.greenRoom || false}
                          onChange={handleChange}
                          name="facilities.greenRoom"
                        />
                      }
                      label="Green Room"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Number of Dressing Rooms"
                      name="facilities.dressingRooms"
                      type="number"
                      value={formData.facilities?.dressingRooms || 0}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.facilities?.catering || false}
                          onChange={handleChange}
                          name="facilities.catering"
                        />
                      }
                      label="Catering Available"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.facilities?.wifi || false}
                          onChange={handleChange}
                          name="facilities.wifi"
                        />
                      }
                      label="WiFi Available"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.facilities?.shower || false}
                          onChange={handleChange}
                          name="facilities.shower"
                        />
                      }
                      label="Shower Available"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.facilities?.bathroom || false}
                          onChange={handleChange}
                          name="facilities.bathroom"
                        />
                      }
                      label="Private Bathroom"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </AccordionDetails>
          </Accordion>

          {/* Technical Specifications */}
          <Accordion 
            expanded={expandedPanel === 'technical'} 
            onChange={handleAccordionChange('technical')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Technical Specifications</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Stage Dimensions</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Width (m)"
                    name="technical.stage.width"
                    type="number"
                    value={formData.technical?.stage.width || 0}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Depth (m)"
                    name="technical.stage.depth"
                    type="number"
                    value={formData.technical?.stage.depth || 0}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Height (m)"
                    name="technical.stage.height"
                    type="number"
                    value={formData.technical?.stage.height || 0}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>Sound System</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.technical?.sound.inHousePA || false}
                        onChange={handleChange}
                        name="technical.sound.inHousePA"
                      />
                    }
                    label="In-house PA System"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Number of Monitors"
                    name="technical.sound.monitors"
                    type="number"
                    value={formData.technical?.sound.monitors || 0}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Sound Notes"
                    name="technical.sound.notes"
                    multiline
                    rows={4}
                    value={formData.technical?.sound.notes || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>Lighting</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.technical?.lighting.basic || false}
                        onChange={handleChange}
                        name="technical.lighting.basic"
                      />
                    }
                    label="Basic Lighting Available"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Lighting Notes"
                    name="technical.lighting.notes"
                    multiline
                    rows={4}
                    value={formData.technical?.lighting.notes || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Access */}
          <Accordion 
            expanded={expandedPanel === 'access'} 
            onChange={handleAccordionChange('access')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Access & Loading</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.access?.loadingBay || false}
                        onChange={handleChange}
                        name="access.loadingBay"
                      />
                    }
                    label="Loading Bay Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.access?.parking || false}
                        onChange={handleChange}
                        name="access.parking"
                      />
                    }
                    label="Parking Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.access?.wheelchairAccess || false}
                        onChange={handleChange}
                        name="access.wheelchairAccess"
                      />
                    }
                    label="Wheelchair Access"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.access?.lift || false}
                        onChange={handleChange}
                        name="access.lift"
                      />
                    }
                    label="Lift Available"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Loading Notes"
                    name="access.loadingNotes"
                    multiline
                    rows={4}
                    value={formData.access?.loadingNotes || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Front of House */}
          <Accordion 
            expanded={expandedPanel === 'frontOfHouse'} 
            onChange={handleAccordionChange('frontOfHouse')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Front of House</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.frontOfHouse?.bar || false}
                        onChange={handleChange}
                        name="frontOfHouse.bar"
                      />
                    }
                    label="Bar Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.frontOfHouse?.cloakroom || false}
                        onChange={handleChange}
                        name="frontOfHouse.cloakroom"
                      />
                    }
                    label="Cloakroom Available"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Merchandise</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.frontOfHouse?.merchandise.allowed || false}
                        onChange={handleChange}
                        name="frontOfHouse.merchandise.allowed"
                      />
                    }
                    label="Merchandise Sales Allowed"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Commission (%)"
                    name="frontOfHouse.merchandise.commission"
                    type="number"
                    value={formData.frontOfHouse?.merchandise.commission || 0}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Contact Information */}
          <Accordion 
            expanded={expandedPanel === 'contact'} 
            onChange={handleAccordionChange('contact')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Contact Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Primary Contact</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Name"
                    name="contactInfo.name"
                    value={formData.contactInfo?.name || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Email"
                    name="contactInfo.email"
                    type="email"
                    value={formData.contactInfo?.email || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Phone"
                    name="contactInfo.phone"
                    value={formData.contactInfo?.phone || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>Technical Contact</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Technical Contact Name"
                    name="contactInfo.technical.name"
                    value={formData.contactInfo?.technical.name || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Technical Contact Email"
                    name="contactInfo.technical.email"
                    type="email"
                    value={formData.contactInfo?.technical.email || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Technical Contact Phone"
                    name="contactInfo.technical.phone"
                    value={formData.contactInfo?.technical.phone || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Form Buttons */}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/admin/venues')}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {id ? 'Update Venue' : 'Create Venue'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default VenueForm;