import React, { useState, useEffect } from 'react';
import { 
  Box,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  Typography,
  Grid,
  Paper,
  SelectChangeEvent,
  CircularProgress,
  Tooltip,
  Chip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isThursday, startOfMonth, addDays, addWeeks } from 'date-fns';
import axiosInstance from '../utils/axiosConfig';
import jazzSubgenres from '../data/jazzSubgenres';
import ScheduleView from './ScheduleView';
import SmartBandInput from './SmartBandInput';
import { BandRecommendation } from '../types/BandRecommendations';

type ProgrammingStatus = 'BOOKED' | 'PROVISIONAL' | 'CHECKING' | 'AVAILABLE';

const STATUS_COLORS: Record<ProgrammingStatus, string> = {
    BOOKED: '#ef5350',        // A brighter, more vibrant red
    PROVISIONAL: '#ffee58',   // A richer yellow that stands out
    CHECKING: '#42a5f5',      // A more saturated blue
    AVAILABLE: '#66bb6a',     // A more vivid green
  };

interface Programming {
  _id: string;
  date: Date | null;
  artistName: string;
  primaryContact: string;
  numPerformers: number;
  genre: string;
  email: string;
  phone: string;
  previouslyPlayed: boolean;  // Changed to boolean to match ConcertTypes
  ensembleMakeup: 'all-male' | 'all-female' | 'mixed' | 'predominantly-male' | 'predominantly-female' | 'other' | 'female-led';
  expectedDraw: 'LOW' | 'MEDIUM' | 'HIGH' | 'EXCEL';
  pricePoint: number;
  bookingSource: string;
  notes?: string;  // Made optional to match ConcertTypes
  status: ProgrammingStatus;
}

interface ProgramSchedulerProps {
    initialData?: Programming | null;
    onSaved: () => void;
    onError: (message: string) => void;
    selectedDate?: Date | null;  // Add this
  }

interface FormData extends Omit<Programming, '_id'> {
  date: Date | null;
  selectedBand?: BandRecommendation | null;

}

const DRAW_LEVELS = {
  LOW: 'Low (0-15)',
  MEDIUM: 'Medium (15-30)',
  HIGH: 'High (50+)',
  EXCEL: 'Excellent (100+)'
} as const;

const BOOKING_SOURCES = [
  'Direct Inquiry/Request',
  'Via Research',
  'Via Agent',
  'Via Social Media',
  'A Recommendation',
  'Artistic Director Choice',
  'Cooler Artist',
  'Past Performer',
  'Festival Circuit',
  'Other'
] as const;

const ProgramScheduler: React.FC<ProgramSchedulerProps> = ({ 
    initialData, 
    onSaved, 
    selectedDate,  // Add this
    onError 
  }) => {
    const [selectedBand, setSelectedBand] = useState<BandRecommendation | null>(null);

    const handleBandSelection = (band: BandRecommendation | null) => {
        setSelectedBand(band);
        if (band) {
            setFormData(prev => ({
                ...prev,
                artistName: band.artistName,
                phone: band.contactPhone || '',
                email: band.contactEmail,
                primaryContact: band.contactName,
                numPerformers: band.totalMembers,
                genre: band.genres[0] || '',
            }));
        }
    };

    const handleManualBandInput = (value: string) => {
        console.log('Manual input received:', value); // Debug log
        setSelectedBand(null);
        // Update the form data with the new artist name
        setFormData(prev => {
          const updatedData = {
            ...prev,
            artistName: value
          };
          console.log('Updated form data:', updatedData); // Debug log
          return updatedData;
        });
      };



      const [formData, setFormData] = useState<FormData>(() => {
        if (initialData) {
            // Convert date string to Date object if it exists
            const date = initialData.date ? new Date(initialData.date) : null;
            
            return {
                ...initialData,
                date,
                // Set default values for any fields that might be undefined
                artistName: initialData.artistName || '',
                primaryContact: initialData.primaryContact || '',
                numPerformers: initialData.numPerformers || 1,
                genre: initialData.genre || '',
                email: initialData.email || '',
                phone: initialData.phone || '',
                previouslyPlayed: initialData.previouslyPlayed || false,
                ensembleMakeup: initialData.ensembleMakeup || 'mixed',
                expectedDraw: initialData.expectedDraw || 'MEDIUM',
                pricePoint: initialData.pricePoint || 15,
                bookingSource: initialData.bookingSource || '',
                notes: initialData.notes || '',
                status: initialData.status || 'PROVISIONAL'
            };
        }
        
        return {
            date: selectedDate || null,
            artistName: '',
            primaryContact: '',
            numPerformers: 1,
            genre: '',
            email: '',
            phone: '',
            previouslyPlayed: false,
            ensembleMakeup: 'mixed',
            expectedDraw: 'MEDIUM',
            pricePoint: 15,
            bookingSource: '',
            notes: '',
            status: 'PROVISIONAL'
        };
    });
  

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [existingProgramming, setExistingProgramming] = useState<Programming[]>([]);


  useEffect(() => {
    fetchExistingProgramming();
  }, []);

  useEffect(() => {
    if (initialData) {
      // Convert date string to Date object if it exists
      const date = initialData.date ? new Date(initialData.date) : null;
      
      // Set form data
      setFormData({
        ...initialData,
        date, // This fixes the date issue
        artistName: initialData.artistName || '',
        primaryContact: initialData.primaryContact || '',
        numPerformers: initialData.numPerformers || 1,
        genre: initialData.genre || '',
        email: initialData.email || '',
        phone: initialData.phone || '',
        previouslyPlayed: initialData.previouslyPlayed || false,
        ensembleMakeup: initialData.ensembleMakeup || 'mixed',
        expectedDraw: initialData.expectedDraw || 'MEDIUM',
        pricePoint: initialData.pricePoint || 15,
        bookingSource: initialData.bookingSource || '',
        notes: initialData.notes || '',
        status: initialData.status || 'PROVISIONAL'
      });
  
      // Also update the initial artist name in the SmartBandInput
      handleManualBandInput(initialData.artistName);
    }
  }, [initialData]);

  const fetchExistingProgramming = async (): Promise<void> => {
    try {
      const response = await axiosInstance.get('/api/programmings/upcoming');
      setExistingProgramming(response.data);
    } catch (err) {
      console.error('Failed to fetch programming:', err);
      setError('Failed to load existing programming');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!formData.date) return;
  
    setLoading(true);
    setError(null);
  
    console.log('Submitting form data:', formData); // Debug log
  
    try {
      const submissionData = {
        ...formData,
        date: format(formData.date, 'yyyy-MM-dd'),
      };
  
      console.log('Submission data:', submissionData); // Debug log
  
      if (initialData?._id) {
        await axiosInstance.put(`/api/programmings/${initialData._id}`, submissionData);
      } else {
        await axiosInstance.post('/api/programmings', submissionData);
      }
  
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
      onSaved();
    } catch (err) {
      console.error('Failed to save programming:', err);
      const errorMessage = 'Failed to save programming. Please try again.';
      setError(errorMessage);
      onError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Update button text based on whether we're editing or creating
  const getSubmitButtonText = () => {
    if (loading) return <CircularProgress size={24} />;
    return initialData ? 'Update Program' : 'Add to Program';
  };


  const resetForm = (): void => {
    setFormData({
      date: null,
      artistName: '',
      primaryContact: '',
      numPerformers: 1,
      genre: '',
      email: '',
      phone: '',
      previouslyPlayed: false,
      ensembleMakeup: 'mixed',
      expectedDraw: 'MEDIUM',
      pricePoint: 15,
      bookingSource: '',
      notes: '',
      status: 'PROVISIONAL'
    });
  };

  const handleChange = (field: keyof FormData) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ): void => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleDateChange = (newDate: Date | null): void => {
    if (newDate && isThursday(newDate)) {
      setFormData(prev => ({
        ...prev,
        date: newDate
      }));
    }
  };

  const getDateBookingStatus = (date: Date): Programming | undefined => {
    return existingProgramming.find(prog => 
      prog.date && format(new Date(prog.date), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    );
  };

  const isDateBooked = (date: Date): boolean => {
    const booking = getDateBookingStatus(date);
    return booking?.status === 'BOOKED';
  };

  const getNextThursdays = (): Date[] => {
    const thursdays: Date[] = [];
    let currentDate = startOfMonth(new Date());
    
    while (!isThursday(currentDate)) {
      currentDate = addDays(currentDate, 1);
    }

    for (let i = 0; i < 4; i++) {
      if (i > 0) {
        currentDate = addWeeks(currentDate, 1);
      }
      thursdays.push(currentDate);
    }

    return thursdays;
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Cooler Thursday Programming
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
    label="Select Thursday"
    value={formData.date}
    onChange={handleDateChange}
    shouldDisableDate={(date: Date) => !isThursday(date) || isDateBooked(date)}
    slotProps={{ 
      textField: { 
        fullWidth: true,
        helperText: "Only Thursdays available"
      },
      day: { 
        sx: { 
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: 'rgba(66, 165, 245, 0.1)'
          }
        } 
      }
    }}
    views={['day']}
  />
</LocalizationProvider>

            <Box sx={{ mt: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Next Available Thursdays:
              </Typography>
              {getNextThursdays().map((thursday, index) => {
                const booking = getDateBookingStatus(thursday);
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      color={booking?.status === 'BOOKED' ? "error.main" : "textSecondary"}
                    >
                      {format(thursday, 'MMMM do, yyyy')}
                    </Typography>
                    {booking && (
                      <Chip
                        label={booking.status}
                        size="small"
                        sx={{
                          bgcolor: STATUS_COLORS[booking.status],
                          color: booking.status === 'PROVISIONAL' ? 'text.primary' : 'white'
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>

            <Box sx={{ mt: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Booking Status:
              </Typography>
              {Object.entries(STATUS_COLORS).map(([status, color]) => (
                <Box
                  key={status}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1
                  }}
                >
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      bgcolor: color,
                      borderRadius: 0.5
                    }}
                  />
                  <Typography variant="body2">
                    {status.charAt(0) + status.slice(1).toLowerCase()}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
              <Grid item xs={12}>
  <SmartBandInput
    value={selectedBand}
    onChange={handleBandSelection}
    onManualInput={handleManualBandInput}
    initialValue={formData.artistName} // Use formData.artistName instead of initialData?.artistName
    loading={loading}
  />
</Grid>

  </Grid>
  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    rows={2}
                    label="Primary Contact Name"
                    name="primaryContact"
                    value={formData.primaryContact}
                    onChange={handleChange('primaryContact')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    rows={2}
                    label="Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange('phone')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    rows={2}
                    label="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange('email')}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Genre</InputLabel>
                    <Select
                      value={formData.genre}
                      onChange={handleChange('genre')}
                      label="Genre"
                    >
                      {jazzSubgenres.map((genre) => (
                        <MenuItem key={genre} value={genre}>{genre}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Number of Performers"
                    value={formData.numPerformers}
                    onChange={handleChange('numPerformers')}
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                <Grid item xs={12}>
                <FormControl component="fieldset" required>
  <FormLabel>Previously Played?</FormLabel>
  <RadioGroup
    row
    value={formData.previouslyPlayed ? "true" : "false"}
    onChange={(e) => handleChange('previouslyPlayed')(
      { target: { value: e.target.value === "true" } } as any
    )}
  >
    <FormControlLabel value="true" control={<Radio />} label="Yes" />
    <FormControlLabel value="false" control={<Radio />} label="No" />
  </RadioGroup>
</FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Ensemble Makeup</InputLabel>
                    <Select
                      value={formData.ensembleMakeup}
                      onChange={handleChange('ensembleMakeup')}
                      label="Ensemble Makeup"
                    >
                      <MenuItem value="all-male">All Male</MenuItem>
                      <MenuItem value="all-female">All Female</MenuItem>
                      <MenuItem value="mixed">Mixed Ensemble</MenuItem>
                      <MenuItem value="female-led">Female Led</MenuItem>
                      <MenuItem value="predominantly-male">Predominantly Male</MenuItem>
                      <MenuItem value="predominantly-female">Predominantly Female</MenuItem>
                      <MenuItem value="other">Other/Non-binary</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Expected Draw</InputLabel>
                    <Select
                      value={formData.expectedDraw}
                      onChange={handleChange('expectedDraw')}
                      label="Expected Draw"
                    >
                      {(Object.keys(DRAW_LEVELS) as Array<keyof typeof DRAW_LEVELS>).map((key) => (
                        <MenuItem key={key} value={key}>{DRAW_LEVELS[key]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Booking Source</InputLabel>
                    <Select
                      value={formData.bookingSource}
                      onChange={handleChange('bookingSource')}
                      label="Booking Source"
                    >
                      {BOOKING_SOURCES.map((source) => (
                        <MenuItem key={source} value={source}>{source}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Price Point (€)"
                    value={formData.pricePoint}
                    onChange={handleChange('pricePoint')}
                    inputProps={{ min: 0 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    label="Quick Notes"
                    value={formData.notes}
                    onChange={handleChange('notes')}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Booking Status</InputLabel>
                    <Select
value={formData.status}
onChange={handleChange('status')}
label="Booking Status"
>
<MenuItem value="PROVISIONAL">Provisional</MenuItem>
<MenuItem value="CHECKING">Checking</MenuItem>
<MenuItem value="BOOKED">Booked</MenuItem>
<MenuItem value="AVAILABLE">Available</MenuItem>
</Select>
</FormControl>
</Grid>

<Grid item xs={12}>
<Button
    type="submit"
    variant="contained"
    fullWidth
    disabled={!formData.date || loading}
    sx={{ mt: 2 }}
  >
    {getSubmitButtonText()}
  </Button>
</Grid>
</Grid>
</form>
</Grid>
</Grid>

{success && (
<Alert severity="success" sx={{ mt: 2 }}>
Program entry added successfully
</Alert>
)}

{error && (
<Alert severity="error" sx={{ mt: 2 }}>
{error}
</Alert>
)}
</Paper>
</Box>
);
};

export default ProgramScheduler;