import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Box,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import CustomNumberInput from '../../components/CustomNumberInput';
import axiosInstance from '../../utils/axiosConfig';
import { AxiosError } from 'axios';


interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

interface Venue {
  _id: string;
  name: string;
  capacity: number;
}

interface RevenueProjection {
  gross: number;
  vat: number;
  net: number;
}

interface TicketConfigDialogProps {
  open: boolean;
  onClose: () => void;
  venue: Venue;
  onSave: (config: { 
    ticketTypes: TicketType[];
    projections: {
      revenue: RevenueProjection;
      chartData: any[];
    }
  }) => void;
  performanceId?: string;
  strandId?: string;
  eventId?: string;
  expectedOccupancy?: number;
  existingConfig?: {
    ticketTypes: TicketType[];
  };
}

interface ChartDataPoint {
  name: string;
  'Gross Revenue': number;
  'VAT Amount': number;
  'Net Revenue': number;
}

const VAT_RATES = {
  STANDARD: 0.23,
  REDUCED: 0.135,
  EXEMPT: 0
} as const;

const DEFAULT_TICKET_TYPES: TicketType[] = [
  { 
    name: 'Early Bird', 
    quantity: 0, 
    price: 0, 
    revenueImpact: true,
    vatRate: VAT_RATES.REDUCED,
    vatExempt: false
  },
  { 
    name: 'Performer Promo', 
    quantity: 0, 
    price: 0, 
    revenueImpact: true,
    vatRate: VAT_RATES.REDUCED,
    vatExempt: false
  },
  { 
    name: 'Guests', 
    quantity: 0, 
    price: 0, 
    revenueImpact: false,
    vatRate: VAT_RATES.EXEMPT,
    vatExempt: true
  },
  { 
    name: 'General Admission', 
    quantity: 0, 
    price: 0, 
    revenueImpact: true,
    vatRate: VAT_RATES.REDUCED,
    vatExempt: false
  }
];

export const TicketConfigDialog: React.FC<TicketConfigDialogProps> = ({
  open,
  onClose,
  venue,
  performanceId,
  strandId,
  eventId,
  onSave,
  existingConfig
}) => {

    const [performanceDetails, setPerformanceDetails] = useState<{
        artistName?: string;
        date?: string;
      }>({});
    
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>(DEFAULT_TICKET_TYPES);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [occupancyRate, setOccupancyRate] = useState<number>(100);
  const [capacityWarning, setCapacityWarning] = useState<string | null>(null);

  useEffect(() => {
    const fetchPerformanceDetails = async () => {
      if (performanceId) {
        try {
          const response = await axiosInstance.get(`/api/performances/${performanceId}`);
          setPerformanceDetails({
            artistName: response.data.simpleBandName || response.data.band?.artistName,
            date: response.data.startTime ? new Date(response.data.startTime).toLocaleDateString() : undefined
          });
        } catch (error) {
          console.error('Error fetching performance details:', error);
        }
      }
    };

    if (open) {
      fetchPerformanceDetails();
    }
  }, [performanceId, open]);

  // Initialize ticket types from existing config or defaults
  useEffect(() => {
    if (existingConfig?.ticketTypes) {
      // Preserve existing configuration exactly as is
      setTicketTypes(existingConfig.ticketTypes);
    } else {
      // Initialize with defaults and adjust General Admission based on occupancy
      const targetCapacity = Math.floor((venue.capacity * occupancyRate) / 100);
      const initialTickets = DEFAULT_TICKET_TYPES.map(ticket => ({
        ...ticket,
        quantity: ticket.name === 'General Admission' ? targetCapacity : 0
      }));
      setTicketTypes(initialTickets);
    }
  }, [existingConfig, venue.capacity, occupancyRate]);

  const calculateVatAmount = (price: number, vatRate: number): number => {
    return price - (price / (1 + vatRate));
  };

  const calculateNetPrice = (price: number, vatRate: number): number => {
    return price / (1 + vatRate);
  };

  const autoAllocateGeneralAdmission = (tickets: TicketType[], capacity: number): TicketType[] => {
    const totalAllocated = tickets
      .filter(ticket => ticket.name !== 'General Admission')
      .reduce((sum, ticket) => sum + ticket.quantity, 0);
    
    const generalAdmission = Math.max(0, capacity - totalAllocated);
  
    return tickets.map(ticket => 
      ticket.name === 'General Admission' 
        ? { ...ticket, quantity: generalAdmission }
        : ticket
    );
  };

  const checkCapacityLimits = (tickets: TicketType[]) => {
    const totalAllocated = tickets.reduce((sum, ticket) => sum + ticket.quantity, 0);
    const maxCapacity = Math.floor((venue.capacity * occupancyRate) / 100);
    
    if (totalAllocated > venue.capacity) {
      setCapacityWarning(`Total allocation (${totalAllocated}) exceeds venue capacity (${venue.capacity})`);
    } else if (totalAllocated > maxCapacity) {
      setCapacityWarning(`Total allocation (${totalAllocated}) exceeds target occupancy (${maxCapacity})`);
    } else {
      setCapacityWarning(null);
    }
  };

  const handleTicketChange = (index: number, field: keyof TicketType, value: number | boolean) => {
    const newTickets = ticketTypes.map((ticket, i) => {
      if (i === index) {
        return { ...ticket, [field]: value };
      }
      return ticket;
    });
    
    const adjustedTickets = autoAllocateGeneralAdmission(
      newTickets,
      Math.floor((venue.capacity * occupancyRate) / 100)
    );
    
    setTicketTypes(adjustedTickets);
    checkCapacityLimits(adjustedTickets);
  };

  const calculateRevenue = (tickets: TicketType[]): RevenueProjection => {
    return tickets
      .filter(ticket => ticket.revenueImpact)
      .reduce((acc, ticket) => {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
        const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
  
        return {
          gross: acc.gross + grossRevenue,
          vat: acc.vat + vatAmount,
          net: acc.net + netRevenue
        };
      }, { gross: 0, vat: 0, net: 0 });
  };

  const getChartData = (): ChartDataPoint[] => {
    return ticketTypes
      .filter(ticket => ticket.revenueImpact)
      .map(ticket => {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
        const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
  
        return {
          name: ticket.name,
          'Gross Revenue': grossRevenue,
          'VAT Amount': vatAmount,
          'Net Revenue': netRevenue
        };
      });
  };

  const handleOccupancyChange = (event: SelectChangeEvent<number>) => {
    const newRate = Number(event.target.value);
    setOccupancyRate(newRate);
    
    const newCapacity = Math.floor((venue.capacity * newRate) / 100);
    const adjustedTickets = autoAllocateGeneralAdmission(ticketTypes, newCapacity);
    
    setTicketTypes(adjustedTickets);
    checkCapacityLimits(adjustedTickets);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const revenue = calculateRevenue(ticketTypes);
  
      const payload = { 
        performanceId, 
        strandId, 
        eventId: eventId || null, 
        venueId: venue?._id || null, 
        ticketTypes,
        projections: {
          revenue,
          chartData: getChartData()
        }
      };
  
      console.log("Submitting ticket config:", payload); 
  
      if (!performanceId) {
        setError('Performance ID is missing.');
        setLoading(false);
        return;
      }
  
      await axiosInstance.post('/api/ticket-configs', payload);
      
      onSave(payload);
      onClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error('Axios error:', error);
        setError(error.response?.data?.error || 'Failed to save configuration.');
      } else if (error instanceof Error) {
        console.error('General error:', error.message);
        setError(error.message);
      } else {
        console.error('Unknown error:', error);
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Configure Tickets{performanceDetails.artistName ? ` for ${performanceDetails.artistName}` : ''}{performanceDetails.date ? ` (${performanceDetails.date})` : ''}
      </DialogTitle>
        <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Venue: {venue.name} (Capacity: {venue.capacity})
          </Typography>
        </Box>

        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography>Expected Occupancy:</Typography>
          <Select
            value={occupancyRate}
            onChange={handleOccupancyChange}
            size="small"
            sx={{ width: 120 }}
          >
            <MenuItem value={25}>25%</MenuItem>
            <MenuItem value={50}>50%</MenuItem>
            <MenuItem value={65}>65%</MenuItem>
            <MenuItem value={75}>75%</MenuItem>
            <MenuItem value={85}>85%</MenuItem>
            <MenuItem value={100}>100%</MenuItem>
          </Select>
          {capacityWarning && (
            <Alert severity="warning" sx={{ flex: 1 }}>
              {capacityWarning}
            </Alert>
          )}
        </Box>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography variant="subtitle2">
                    Venue Capacity: {venue.capacity} | Target Occupancy: {Math.floor((venue.capacity * occupancyRate) / 100)} ({occupancyRate}%)
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ticket Type</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price (€)</TableCell>
                <TableCell align="right">VAT Status</TableCell>
                <TableCell align="right">Net Price (€)</TableCell>
                <TableCell align="right">VAT Amount (€)</TableCell>
                <TableCell align="right">Total Revenue (€)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketTypes.map((ticket, index) => {
                const grossRevenue = ticket.quantity * ticket.price;
                const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
                const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
                
                return (
                  <TableRow key={ticket.name}>
                    <TableCell>{ticket.name}</TableCell>
                    <TableCell align="right">
                      <CustomNumberInput
                        value={ticket.quantity}
                        onChange={(value) => handleTicketChange(index, 'quantity', value)}
                        min={0}
                        max={Math.floor((venue.capacity * occupancyRate) / 100)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CustomNumberInput
                        value={ticket.price}
                        onChange={(value) => handleTicketChange(index, 'price', value)}
                        min={0}
                        disabled={!ticket.revenueImpact}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Select
                        value={ticket.vatExempt}
                        onChange={(e) => handleTicketChange(index, 'vatExempt', e.target.value === 'true')}
                        size="small"
                      >
                        <MenuItem value="false">Reduced VAT</MenuItem>
                        <MenuItem value="true">VAT Exempt</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="right">{netRevenue.toFixed(2)}</TableCell>
                    <TableCell align="right">{vatAmount.toFixed(2)}</TableCell>
                    <TableCell align="right">{grossRevenue.toFixed(2)}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>Total Allocated</TableCell>
                <TableCell align="right">
                  {ticketTypes.reduce((sum, ticket) => sum + ticket.quantity, 0)}
                </TableCell>
                <TableCell colSpan={5}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mb: 3 }}>
          {(() => {
            const totals = calculateRevenue(ticketTypes);
            return (
              <>
                <Typography variant="h6">Revenue Summary</Typography>
                <Typography>Gross Revenue: €{totals.gross.toFixed(2)}</Typography>
                <Typography>VAT Amount: €{totals.vat.toFixed(2)}</Typography>
                <Typography>Net Revenue: €{totals.net.toFixed(2)}</Typography>
              </>
            );
          })()}
        </Box>

        <Box sx={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={getChartData()}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Gross Revenue" fill="#8884d8" />
              <Bar dataKey="VAT Amount" fill="#82ca9d" />
              <Bar dataKey="Net Revenue" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave} 
          variant="contained" 
          color="primary"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Configuration'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketConfigDialog;