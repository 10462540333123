import React from 'react';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';

interface TicketSalesSectionProps {
  ticketsSold: number;
  ticketSales: number;
  totalIncome: number;
  totalCost: number;
  costBreakdown: {
    fee: number;
    travel: number;
    accommodation: number;
    perDiem: number;
    equipment: number;
    other: number;
    contingency: number;
  };
}

const TicketSalesSection: React.FC<TicketSalesSectionProps> = ({
  ticketsSold,
  ticketSales,
  totalIncome,
  totalCost,
  costBreakdown,
}) => {
  const theme = useTheme();
  
  // Modern color palette
  const colors = {
    income: '#4CAF50',    // Green
    cost: '#FF5722',      // Orange-red
    bar: {
      fee: '#2196F3',         // Blue
      travel: '#00BCD4',      // Cyan
      accommodation: '#3F51B5',// Indigo
      perDiem: '#009688',     // Teal
      equipment: '#673AB7',    // Deep Purple
      other: '#795548',       // Brown
      contingency: '#607D8B'   // Blue Grey
    }
  };

  const incomeExpenseData = [
    { name: 'Total Income', value: totalIncome },
    { name: 'Total Costs', value: totalCost },
  ];

  const costBreakdownData = [
    { name: 'Performance Fee', value: costBreakdown.fee, color: colors.bar.fee },
    { name: 'Travel', value: costBreakdown.travel, color: colors.bar.travel },
    { name: 'Accommodation', value: costBreakdown.accommodation, color: colors.bar.accommodation },
    { name: 'Per Diem', value: costBreakdown.perDiem, color: colors.bar.perDiem },
    { name: 'Equipment', value: costBreakdown.equipment, color: colors.bar.equipment },
    { name: 'Other Costs', value: costBreakdown.other, color: colors.bar.other },
    { name: 'Contingency', value: costBreakdown.contingency, color: colors.bar.contingency },
  ];

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 2,
            border: 1,
            borderColor: 'divider',
            borderRadius: 1,
            boxShadow: theme.shadows[3]
          }}
        >
          <Typography variant="subtitle2" color="text.primary">
            {label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            €{payload[0].value.toFixed(2)}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom color="text.primary">
            Ticket Sales Overview
          </Typography>
          <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
            <Box>
              <Typography variant="h4" color="text.primary">
                {ticketsSold}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tickets Sold
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary">
                €{ticketSales.toFixed(2)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Sales
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mb: 6 }}>
          <Typography variant="h6" gutterBottom color="text.primary">
            Income vs. Costs
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={incomeExpenseData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
              >
                <Cell fill={colors.income} />
                <Cell fill={colors.cost} />
              </Pie>
              <Tooltip content={CustomTooltip} />
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom color="text.primary">
            Cost Breakdown
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={costBreakdownData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis
                dataKey="name"
                tick={{ fill: theme.palette.text.secondary }}
                angle={-45}
                textAnchor="end"
                height={80}
              />
              <YAxis tick={{ fill: theme.palette.text.secondary }} />
              <Tooltip content={CustomTooltip} />
              <Bar dataKey="value">
                {costBreakdownData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TicketSalesSection;