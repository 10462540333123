import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { Euro } from '@mui/icons-material';

interface PerformanceFinancialsProps {
  ticketConfig: {
    ticketTypes: Array<{
      name: string;
      quantity: number;
      price: number;
      vatRate: number;
      vatExempt: boolean;
      revenueImpact: boolean;
    }>;
  };
  performance: {
    contingencyRate: number;  // Move this up to root level
    costs: {
      bandFee: number;
      travel: {
        flights: number;
        groundTransport: number;
        other: number;
        total: number;
      };
      accommodation: {
        total: number;
      };
      perDiem: {
        total: number;
      };
      equipment: {
        total: number;
      };
      technical: {
        total: number;
      };
      additional: {
        marketing: number;
        insurance: number;
        visas: number;
        total: number;
      };
      summary: {
        totalCosts: number;
        contingency: number;
        grandTotal: number;
      };
    };
  };
}

const PerformanceFinancials: React.FC<PerformanceFinancialsProps> = ({
  ticketConfig,
  performance
}) => {
  // Calculate total potential revenue from tickets
  const calculateTicketRevenue = () => {
    return ticketConfig.ticketTypes.reduce((total, ticket) => {
      if (ticket.revenueImpact) {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : (grossRevenue * ticket.vatRate);
        const netRevenue = grossRevenue - vatAmount;
        return total + netRevenue;
      }
      return total;
    }, 0);
  };

  const totalRevenue = calculateTicketRevenue();
  const totalCosts = performance.costs.summary.grandTotal;
  const subventionRequired = totalCosts > totalRevenue ? totalCosts - totalRevenue : 0;

  // Prepare data for the comparison chart
  const comparisonData = [
    {
      category: 'Income vs Costs',
      Revenue: totalRevenue,
      Costs: totalCosts,
      Subvention: subventionRequired
    }
  ];

  // Prepare data for the cost breakdown chart
  const costBreakdown = [
    {
      name: 'Band Fee',
      value: performance.costs.bandFee
    },
    {
      name: 'Travel',
      value: performance.costs.travel.total
    },
    {
      name: 'Accommodation',
      value: performance.costs.accommodation.total
    },
    {
      name: 'Per Diem',
      value: performance.costs.perDiem.total
    },
    {
        name: 'Marketing',  // Add marketing separately
        value: performance.costs.additional.marketing
      },
    {
      name: 'Equipment',
      value: performance.costs.equipment.total
    },
    {
      name: 'Technical',
      value: performance.costs.technical.total
    },
    {
      name: 'Additional',
      value: performance.costs.additional.total
    },
    {
        name: `Contingency (${(performance.contingencyRate || 10)}%)`, // Show contingency rate
        value: performance.costs.summary.contingency
      }
  ].filter(item => item.value > 0);

  // Format currency
  const formatCurrency = (amount: number) => {
    return `€${amount.toFixed(2)}`;
  };

  return (
    <Card>
      <CardContent>
      <Typography variant="h4" gutterBottom color="white" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  				<Euro fontSize="small" />
          Financial Overview
        </Typography>

        <Grid container spacing={3}>
          {/* Summary Cards */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, bgcolor: 'primary.dark', color: 'primary.contrastText' }}>
              <Typography variant="h6">Expected Ticket Revenue</Typography>
              <Typography variant="h4">{formatCurrency(totalRevenue)}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, bgcolor: 'error.dark', color: 'error.contrastText' }}>
              <Typography variant="h6">Expected Costs</Typography>
              <Typography variant="h4">{formatCurrency(totalCosts)}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, bgcolor: subventionRequired > 0 ? 'warning.dark' : 'success.light', color: 'text.primary' }}>
              <Typography variant="h6">
                {subventionRequired > 0 ? 'Subvention Required' : 'Profit'}
              </Typography>
              <Typography variant="h4">
                {formatCurrency(subventionRequired > 0 ? subventionRequired : totalRevenue - totalCosts)}
              </Typography>
            </Paper>
          </Grid>

          {/* Income Breakdown */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Income Breakdown</Typography>
              <Box sx={{ mt: 2 }}>
                {ticketConfig.ticketTypes.map((ticket, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>
                      {ticket.name} ({ticket.quantity} × {formatCurrency(ticket.price)})
                    </Typography>
                    <Typography>
                      {formatCurrency(ticket.quantity * ticket.price)}
                    </Typography>
                  </Box>
                ))}
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Total Revenue</Typography>
                  <Typography variant="h6">{formatCurrency(totalRevenue)}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Cost Breakdown */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Cost Breakdown</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={costBreakdown}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => formatCurrency(value as number)} />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          {/* Income vs Costs Comparison */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Income vs Costs</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={comparisonData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="category" />
                    <YAxis />
                    <Tooltip formatter={(value) => formatCurrency(value as number)} />
                    <Legend />
                    <Bar dataKey="Revenue" fill="#4CAF50" />
                    <Bar dataKey="Costs" fill="#f44336" />
                    <Bar dataKey="Subvention" fill="#ff9800" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PerformanceFinancials;