// src/utils/wordExport.ts
import axiosInstance from "./axiosConfig";

interface WordExportOptions {
    artist?: string;
    venue?: string;
    venueAddress?: string;
    contactInfo?: {
        name: string;
        email: string;
        phone: string;
    };
}

export const exportScheduleToWord = async (schedule: any[], options: WordExportOptions) => {
    try {
        const response = await axiosInstance.post('/api/word/generate-schedule', {
            schedule,
            options
        });
        
        return response.data.url;
    } catch (error) {
        console.error('Error generating Word document:', error);
        throw error;
    }
};