import React from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Calculator } from 'lucide-react';
import FlightIcon from '@mui/icons-material/Flight';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Per Diem
import HotelIcon from '@mui/icons-material/Hotel'; // Accommodation
import BuildIcon from '@mui/icons-material/Build'; // Technical
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Equipment
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // Other
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

interface CostsSectionProps {
  calculatedCosts: {
    travel: {
      flights: number;
      groundTransport: number;
      other: number;
      total: number;
    };

    accommodation: {
      singleRooms: {
        quantity: number;
        costPerRoom: number;
        totalNights: number;
      };
      twinRooms: {
        quantity: number;
        costPerRoom: number;
        totalNights: number;
      };
      total: number;
    };

    perDiem: {
      dailyRate: number;
      numberOfDays: number;
      total: number;
    };

    equipment: {
      rental: number;
      shipping: number;
      backline: number;
      total: number;
    };

    technical: {
      soundEngineer: number;
      lightingEngineer: number;
      backline: number;
      other: number;
      total: number;
    };

    additional: {
      marketing: number;
      insurance: number;
      visas: number;
      total: number;
    };

    otherCosts: Array<{ description: string; amount: number }>;

    summary: {
      totalCosts: number;
      contingency: number;
      grandTotal: number;
    };

    currency: string;
  } | null;
  onEventCalculatorOpen: () => void;
}

const CostsSection: React.FC<CostsSectionProps> = ({
  calculatedCosts,
  onEventCalculatorOpen
}) => {
  const formatCurrency = (amount: number) => {
    if (!calculatedCosts) return '€0.00';
    const symbol =
      calculatedCosts.currency === 'GBP' ? '£' :
      calculatedCosts.currency === 'USD' ? '$' : '€';
    return `${symbol}${amount.toFixed(2)}`;
  };

  const renderCostItem = (label: string, amount: number) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
      <Typography variant="body2">{label}:</Typography>
      <Typography variant="body2" fontWeight="medium">
        {formatCurrency(amount)}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onEventCalculatorOpen}
          startIcon={<Calculator />}
          fullWidth
          sx={{ mb: 2 }}
        >
          {calculatedCosts ? 'Recalculate Costs' : 'Calculate Costs'}
        </Button>
      </Grid>

      {calculatedCosts && (
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ minHeight: '48px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', pr: 2 }}>
                <Typography variant="subtitle1">Cost Breakdown</Typography>
                <Typography variant="subtitle1" color="primary">
                  Total: {formatCurrency(calculatedCosts.summary.totalCosts + calculatedCosts.summary.contingency)}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Grid container spacing={2}>
                
                {/* Travel */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <Box sx={{ display: 'flex', gap: 0.5 }}>
    <FlightIcon fontSize="small" /> / 
    <DirectionsCarIcon fontSize="small" />
  </Box>
  Travel
</Typography>
                    {renderCostItem('Flights', calculatedCosts.travel.flights)}
                    {renderCostItem('Ground Transport', calculatedCosts.travel.groundTransport)}
                    {renderCostItem('Other Travel', calculatedCosts.travel.other)}
                    <Box sx={{ mt: 1, borderTop: 1, borderColor: 'divider', pt: 1 }}>
                      {renderCostItem('Total', calculatedCosts.travel.total)}
                    </Box>
                  </Paper>
                </Grid>

                {/* Accommodation */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <HotelIcon fontSize="small" />
  Accommodation
</Typography>
                    {renderCostItem('Total', calculatedCosts.accommodation.total)}
                  </Paper>
                </Grid>

                {/* Per Diem */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <AttachMoneyIcon fontSize="small" />
  Per Diem
</Typography>

                    {renderCostItem('Total', calculatedCosts.perDiem.total)}
                  </Paper>
                </Grid>

                {/* Technical */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <BuildIcon fontSize="small" />
  Technical
</Typography>
                    {renderCostItem('Sound Engineer', calculatedCosts.technical.soundEngineer)}
                    {renderCostItem('Lighting Engineer', calculatedCosts.technical.lightingEngineer)}
                    {renderCostItem('Backline', calculatedCosts.technical.backline)}
                    {renderCostItem('Other', calculatedCosts.technical.other)}
                    <Box sx={{ mt: 1, borderTop: 1, borderColor: 'divider', pt: 1 }}>
                      {renderCostItem('Total', calculatedCosts.technical.total)}
                    </Box>
                  </Paper>
                </Grid>

                {/* Equipment */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <PrecisionManufacturingIcon fontSize="small" />
  Equipment
</Typography>

                    {renderCostItem('Rental', calculatedCosts.equipment.rental)}
                    {renderCostItem('Shipping', calculatedCosts.equipment.shipping)}
                    {renderCostItem('Backline', calculatedCosts.equipment.backline)}
                    <Box sx={{ mt: 1, borderTop: 1, borderColor: 'divider', pt: 1 }}>
                      {renderCostItem('Total', calculatedCosts.equipment.total)}
                    </Box>
                  </Paper>
                </Grid>

                                {/* Additional */}
                                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <PrecisionManufacturingIcon fontSize="small" />
  Additional
</Typography>

                    {renderCostItem('Marketing', calculatedCosts.additional.marketing)}
                    {renderCostItem('Insurance', calculatedCosts.additional.insurance)}
                    {renderCostItem('Visas', calculatedCosts.additional.visas)}
                    <Box sx={{ mt: 1, borderTop: 1, borderColor: 'divider', pt: 1 }}>
                      {renderCostItem('Total', calculatedCosts.additional.total)}
                    </Box>
                  </Paper>
                </Grid>


                {/* Other Costs */}
                {calculatedCosts.otherCosts.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <MoreHorizIcon fontSize="small" />
  Other
</Typography>
                      {calculatedCosts.otherCosts.map((cost, index) => (
                        renderCostItem(cost.description || `Item ${index + 1}`, cost.amount)
                      ))}
                    </Paper>
                  </Grid>
                )}

                {/* Grand Total */}
                <Grid item xs={12}>
                <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
  <Typography variant="h6" gutterBottom>Total Cost Breakdown</Typography>
  {renderCostItem('Total Costs', calculatedCosts.summary.totalCosts)} 
  {renderCostItem('Contingency', calculatedCosts.summary.contingency)}
  <Box sx={{ borderTop: 2, borderColor: 'primary.contrastText', mt: 2, pt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography variant="h5">Grand Total:</Typography>
    <Typography variant="h4">
      {formatCurrency(calculatedCosts.summary.totalCosts + calculatedCosts.summary.contingency)}
    </Typography>
  </Box>
</Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};

export default CostsSection;