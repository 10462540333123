import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import IMCContractForm from '../../components/contracts/IMCContractForm';
import { Container, Box, Typography, CircularProgress, Alert } from '@mui/material';

const EditContractPage = () => {
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [contractData, setContractData] = useState<any>(null);
  
    useEffect(() => {
      if (!id) {
        console.error('No ID in URL params');
        setError('No contract ID provided');
        setLoading(false);
        return;
      }
  
      console.log('Loading contract with ID:', id);
      fetchContract(id);
    }, [id]);
  
    const fetchContract = async (contractId: string) => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/contracts/${contractId}`);
        
        if (!response.data) {
          throw new Error('No contract data received');
        }
  
        // Transform MongoDB data to form data
        const contract = response.data;
        const formData = {
            id: contract._id,
            Contact: contract.contractData?.Contact || contract.artistName || '',
            Item: contract.contractData?.Item || contract.artistName || '',
            Email: contract.contractData?.Email || contract.email || '',
            Phone: contract.contractData?.Phone || contract.phone || '',
            'Event Date': contract.eventDate ? new Date(contract.eventDate) : null,
            Venue: contract.contractData?.Venue || contract.venue || '',
            Address: contract.contractData?.Address || contract.address || '',
            'Soundcheck Time': contract.contractData?.['Soundcheck Time'] 
              ? new Date(`2025-01-01T${contract.contractData['Soundcheck Time']}`)
              : null,
            'Soundcheck Duration': contract.contractData?.['Soundcheck Duration'] || '',
            Doors: contract.contractData?.Doors 
              ? new Date(`2025-01-01T${contract.contractData.Doors}`)
              : null,
            'Stage Time': contract.contractData?.['Stage Time']
              ? new Date(`2025-01-01T${contract.contractData['Stage Time']}`)
              : null,
            'Performance Type': contract.contractData?.['Performance Type'] || '',
            'Min Duration': contract.contractData?.['Min Duration'] || '',
            Fees: contract.contractData?.Fees || '',
            'Payment Type': contract.contractData?.['Payment Type'] || '',
            'Int\'l Flights': contract.contractData?.['Int\'l Flights'] || '',
            'Ground Transport': contract.contractData?.['Ground Transport'] || '',
            'Accommodation Type': contract.contractData?.['Accommodation Type'] || '',
            'Accommodation Qty': contract.contractData?.['Accommodation Qty'] || '',
            'PD Amount': contract.contractData?.['PD Amount'] || '',
            'PD Quantity': contract.contractData?.['PD Quantity'] || '',
            'PD Days': contract.contractData?.['PD Days'] || '',
            'Activities Master': contract.contractData?.['Activities Master'] || '',
            'IMC Code': contract.contractData?.['IMC Code'] || '',
            contractPath: contract.contractPath || ''
          };
          
          console.log('Setting contract data:', formData);
          setContractData(formData);
        } catch (err) {
          console.error('Error fetching contract:', err);
          setError('Failed to load contract details');
        } finally {
          setLoading(false);
        }
      };
  
    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;
    if (!contractData) return <Alert severity="warning">No contract data found.</Alert>;
  
    return (
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <Typography variant="h4" gutterBottom>
            Edit Contract
          </Typography>
          <IMCContractForm 
            initialData={contractData} 
            mode="edit"
            contractPath={contractData.contractPath}
          />
        </Box>
      </Container>
    );
  };
  

export default EditContractPage;