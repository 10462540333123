import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Chip,
  CircularProgress,
  Alert,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Checkbox,
  Button,
  Divider
} from '@mui/material';
import { 
  CalendarCheck,
  Clock,
  Lightbulb,
  ClipboardList,
  Edit,
  Trash2,
  ExternalLink,
  CheckCircle2,
  ListTodo
} from 'lucide-react';
import { format, differenceInDays, isAfter } from 'date-fns';
import { Performance, Staff } from '../../types/mongodb';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from '../../contexts/AuthContext';
import TaskList from './TaskList';
import TaskboardList from './TaskboardList';


interface Task {
  _id: string;
  text: string;
  completed: boolean;
  urgent: boolean;
  created: string;
  assignedTo: string;
  createdBy: string;
  dueDate?: string;
  strandId?: string;
  createdAt: string;
  updatedAt: string;
}

interface TaskboardSubtask {
    _id: string;
    title: string;
    completed: boolean;
    assignedTo: string;
    dueDate: string;
    boardId?: string;  // Added for when we combine with parent board info
    boardTitle?: string;  // Added for when we combine with parent board info
  }
  
  interface Taskboard {
    _id: string;
    completed?: boolean;  // Add this
    title: string;
    description: string;
    status: string;
    priority: 'high' | 'medium' | 'low';
    progress: number;
    category: string;
    dueDate: string;
    subtasks: TaskboardSubtask[];
    assignedTo: string;
  }

interface TaskboardItem {
  _id: string;
  title: string;
  description: string;
  status: string;
  priority: 'high' | 'medium' | 'low';
  progress: number;
  dueDate?: string;
  assignedTo: string;
  subtasks: Array<{
    title: string;
    completed: boolean;
    assignedTo: string;
    dueDate: string;
  }>;
}

interface Idea {
  _id: string;
  title: string;
  sourceType: string;
  sourceUrl?: string;
  notes?: string;
  status: string;
  priority: 'high' | 'medium' | 'low';
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

interface UserTasksData {
  nextPerformance: Performance | null;
  tasks: Task[];
  taskboardItems: TaskboardItem[];
  ideas: Idea[];
}

const TasksOverview: React.FC<{ userId: string }> = ({ userId }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState({
    nextPerformances: [] as Performance[],
    tasks: [] as Task[],
    taskboards: [] as Taskboard[],
    ideas: [] as Idea[]
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Fetching user data for ID:', userId);
        const [performancesRes, tasksRes, taskboardsRes, ideasRes] = await Promise.all([
          axiosInstance.get<Performance[]>('/api/performances', {
            params: {
              staff: userId,
              sort: 'startTime',
              future: true,
              limit: 2
            }
          }),
          axiosInstance.get<Task[]>('/api/tasks', {
            params: {
              assignedTo: userId,
              completed: false
            }
          }),
          axiosInstance.get<Taskboard[]>('/api/taskboards', {
            params: {
              $or: [
                { assignedTo: userId },
                { 'subtasks.assignedTo': userId }
              ],
              status: { $ne: 'done' }
            }
          }),
          axiosInstance.get<Idea[]>('/api/ideas', {
            params: {
              createdBy: userId,
              status: ['new', 'in_progress']
            }
          })
        ]);

        console.log('API Responses:', {
          performances: performancesRes.data,
          tasks: tasksRes.data,
          taskboards: taskboardsRes.data,
          ideas: ideasRes.data
        });

        setData({
          nextPerformances: performancesRes.data
            .filter(perf => isAfter(new Date(perf.startTime), new Date()))
            .slice(0, 2),
          tasks: tasksRes.data,
          taskboards: taskboardsRes.data,
          ideas: ideasRes.data
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Unable to load dashboard data');
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId, user]);


  const handleTaskToggle = async (taskId: string) => {
    try {
      await axiosInstance.patch(`/api/tasks/${taskId}/toggle`);
      // Refresh tasks
      const tasksRes = await axiosInstance.get<Task[]>('/api/tasks', {
        params: {
          assignedTo: userId,
          status: 'incomplete'
        }
      });
      setData(prev => ({ ...prev, tasks: tasksRes.data }));
    } catch (error) {
      console.error('Error toggling task:', error);
    }
  };

  const handleDeleteIdea = async (ideaId: string) => {
    try {
      await axiosInstance.delete(`/api/ideas/${ideaId}`);
      setData(prev => ({
        ...prev,
        ideas: prev.ideas.filter(idea => idea._id !== ideaId)
      }));
    } catch (error) {
      console.error('Error deleting idea:', error);
    }
  };

  const handleEditIdea = (ideaId: string) => {
    navigate(`/admin/ideas?edit=${ideaId}`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const { nextPerformances, tasks, taskboards, ideas } = data;

  const userSubtasks: TaskboardSubtask[] = taskboards?.length 
    ? taskboards.flatMap(board => 
        (board?.subtasks || [])
          .filter(subtask => subtask?.assignedTo === userId && !subtask.completed)
          .map(subtask => ({
            ...subtask,
            boardId: board._id,
            boardTitle: board.title
          }))
      )
    : [];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Overview
      </Typography>

    {/* Upcoming Performances as an Accordion */}
      {nextPerformances.length > 0 && (
        <Accordion defaultExpanded sx={{ mb: 3 }}>
          <AccordionSummary expandIcon={<CalendarCheck />}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarCheck />
              <Typography>Your Upcoming Performances</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {nextPerformances.map((perf) => {
              const daysUntil = differenceInDays(new Date(perf.startTime), new Date());
              return (
                <Card key={perf._id} sx={{ mb: 1, position: 'relative' }}>
                  <CardContent>
                    <Typography variant="h6">{perf.simpleBandName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {format(new Date(perf.startTime), 'PPP p')}
                    </Typography>
                    <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                      <Chip
                        label={`${daysUntil} days until show`}
                        color="info"
                        size="small"
                      />
                      {typeof perf.staff !== 'string' && 'assignedRole' in perf.staff && (
                        <Chip
                          label={perf.staff.assignedRole as string}
                          color="secondary"
                          size="small"
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}

<TaskList userId={userId} />
<TaskboardList userId={userId} />

      {/* Ideas Section as an Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<Clock />}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Lightbulb />
            <Typography>Your Ideas</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {ideas.map((idea) => (
              <ListItem key={idea._id}>
                <ListItemIcon>
                  <Lightbulb
                    className={
                      idea.priority === 'high'
                        ? 'text-purple-500'
                        : idea.priority === 'medium'
                        ? 'text-blue-500'
                        : 'text-gray-500'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={idea.title}
                  secondary={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Chip size="small" label={idea.status} />
                      <Typography variant="caption">
                        Created: {format(new Date(idea.createdAt), 'PP')}
                      </Typography>
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleEditIdea(idea._id)}>
                    <Edit size={16} />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDeleteIdea(idea._id)}>
                    <Trash2 size={16} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Button
              fullWidth
              variant="outlined"
              startIcon={<Lightbulb />}
              onClick={() => navigate('/admin/ideas')}
              sx={{ mt: 2 }}
            >
              Add New Idea
            </Button>
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TasksOverview;