// components/contracts/ContractGenerator/ContractPreview.tsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Paper,
  Box,
  Divider,
} from '@mui/material';
import { FormData } from '../types';

interface ContractPreviewProps {
  open: boolean;
  onClose: () => void;
  formData: FormData;
  onGenerate: () => void;
}

const UserInput = ({ children }: { children: React.ReactNode }) => (
    <Typography 
      component="span" 
      sx={{ 
        fontWeight: 'bold',
        color: '#FFD700',  // or any color that fits your theme
        display: 'inline-block',
        minWidth: '20px',  // ensures empty values still show as a placeholder
        '&:empty::before': {
          content: '"-"',
          color: 'grey.400'
        }
      }}
    >
      {children || ''}
    </Typography>
  );
  

  const ContractPreview: React.FC<ContractPreviewProps> = ({
    open,
    onClose,
    formData,
    onGenerate
  }) => {
    return (
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>Contract Preview</DialogTitle>
        
        <DialogContent>
          <Paper elevation={0} sx={{ p: 3, fontFamily: 'serif' }}>
            <Typography variant="h5" align="center" gutterBottom>
              ARTIST CONTRACT
            </Typography>
  
            <Box sx={{ my: 3 }}>
              <Typography>
                This agreement is made on {new Date().toLocaleDateString()} between Improvised Music Company 
                hereinafter referred to as the Promoter and;
              </Typography>
              <Typography>
                <UserInput>{formData.Contact}</UserInput> representing <UserInput>{formData.Item} </UserInput> hereinafter referred to as the Artist.
              </Typography>
            </Box>
  
            <Typography paragraph>
              1. That the Promoter engages the Artist and the Artist accepts an engagement at Venue(s) 
              and for the period(s) and fee(s) stated in the schedule below.
            </Typography>
  
            <Box sx={{ my: 2 }}>
              <Typography>
                Date(s): <UserInput>{formData['Event Date']?.toLocaleDateString()}</UserInput>
              </Typography>
              <Typography>
                Venue(s): <UserInput>{formData.Venue}</UserInput>
              </Typography>
            </Box>
  
            <Typography variant="h6" gutterBottom>Performance Details:</Typography>
            <Box sx={{ ml: 2 }}>
              <Typography>
                Soundcheck Time: <UserInput>{formData['Soundcheck Time']?.toLocaleTimeString()}</UserInput>
              </Typography>
              <Typography>
                Soundcheck Duration: <UserInput>{formData['Soundcheck Duration']}</UserInput>
              </Typography>
              <Typography>
                Event Door Time: <UserInput>{formData.Doors?.toLocaleTimeString()}</UserInput>
              </Typography>
              <Typography>
                Event Stage Time: <UserInput>{formData['Stage Time']?.toLocaleTimeString()}</UserInput>
              </Typography>
              <Typography>
                Performance Type: <UserInput>{formData['Performance Type']}</UserInput>
              </Typography>
              <Typography>
                Fee: €<UserInput>{formData.Fees}</UserInput> to be paid in full via  <UserInput>{formData['Payment Type']} </UserInput> post-performance on receipt of an invoice to Improvised Music Company.
              </Typography>
            </Box>
  
            <Typography sx={{ mt: 2 }}>
              Each Performance shall be no shorter than <UserInput>{formData['Min Duration']}</UserInput>.
            </Typography>
  
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" gutterBottom>Travel & Accommodation:</Typography>
              <Box sx={{ ml: 2 }}>
                <Typography>
                  • International Flights: <UserInput>{formData['Int\'l Flights']}</UserInput>
                </Typography>
                <Typography>
                  • Local Transport: <UserInput>{formData['Ground Transport']}</UserInput>
                </Typography>
                <Typography>
                  • Accommodation: <UserInput>{formData['Accommodation Type']}</UserInput> 
                  (<UserInput>{formData['Accommodation Qty']}</UserInput> rooms)
                </Typography>
                <Typography>
                  • Per Diem: <UserInput>{formData['PD Amount']}</UserInput> x 
                  <UserInput>{formData['PD Quantity']}</UserInput> for 
                  <UserInput>{formData['PD Days']}</UserInput> days
                </Typography>
              </Box>
            </Box>
  
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>Contact Details:</Typography>
              <Box sx={{ ml: 2 }}>
                <Typography>
                  Artist Email: <UserInput>{formData.Email}</UserInput>
                </Typography>
                <Typography>
                  Artist Phone: <UserInput>{formData.Phone}</UserInput>
                </Typography>
                <Typography>
                  Venue Address: <UserInput>{formData.Address}</UserInput>
                </Typography>
              </Box>
            </Box>
  
            <Box sx={{ mt: 4, color: 'text.secondary' }}>
              <Typography variant="subtitle2">Internal Use:</Typography>
              <Typography variant="body2">
                Activity: <UserInput>{formData['Activities Master']}</UserInput>
              </Typography>
              <Typography variant="body2">
                Code: <UserInput>{formData['IMC Code']}</UserInput>
              </Typography>
            </Box>
          </Paper>
        </DialogContent>
  
        <DialogActions>
          <Button onClick={onClose}>Edit</Button>
          <Button variant="contained" onClick={onGenerate}>Generate Contract</Button>
        </DialogActions>
      </Dialog>
    );
  };

export default ContractPreview;