import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { getRandomSalutation } from '../constants/salutations';
import axiosInstance from '../utils/axiosConfig';

interface LastLogin {
  lastLogin: string;
}

interface Application {
  _id: string;
  createdAt: string;
}

interface BandRecommendation {
  _id: string;
  createdAt: string;
}

interface UserGreetingProps {
  ticketsSold: number;
}

const getFirstName = (fullName: string): string => {
  return fullName.split(' ')[0];
};

const API_BASE_URL = process.env.REACT_APP_API_URL;

const UserGreeting: React.FC<UserGreetingProps> = ({ ticketsSold }) => {
  const [newApplications, setNewApplications] = useState<number | null>(null);
  const [newBands, setNewBands] = useState<number | null>(null);
  const [lastLogin, setLastLogin] = useState<string | null>(null);
  const [salutation, setSalutation] = useState<string>('');
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const firstName = getFirstName(user.name);
      setSalutation(getRandomSalutation(firstName));
    }
  }, [user]);

  useEffect(() => {
    const fetchLastLogin = async () => {
      if (user && user._id) {
        try {
          const response = await axiosInstance.get<LastLogin>(
            `${API_BASE_URL}/api/users/${user._id}/lastLogin`
          );
          setLastLogin(response.data.lastLogin);
        } catch (error) {
          console.error('Error fetching last login:', error);
        }
      }
    };

    if (user) {
      fetchLastLogin();
    }
  }, [user]);

  useEffect(() => {
    const fetchNewItems = async () => {
      if (!user || !lastLogin) return;

      try {
        const lastLoginDate = new Date(lastLogin);
        const currentTime = new Date();
        
        // If last login was less than 10 seconds ago, assume this is a fresh login
        if ((currentTime.getTime() - lastLoginDate.getTime()) < 10000) {
          setNewApplications(0);
          setNewBands(0);
          return;
        }

        // Fetch applications and bands created after last login
        const [banBamResponse, navigatorResponse, bandsResponse] = await Promise.all([
          axiosInstance.get<Application[]>(`${API_BASE_URL}/api/banbam`, {
            params: {
              createdAt_gte: lastLoginDate.toISOString(),
              timestamp: new Date().getTime() // Cache buster
            }
          }),
          axiosInstance.get<Application[]>(`${API_BASE_URL}/api/navigator`, {
            params: {
              createdAt_gte: lastLoginDate.toISOString(),
              timestamp: new Date().getTime() // Cache buster
            }
          }),
          axiosInstance.get<BandRecommendation[]>(`${API_BASE_URL}/api/bandrecommendations`, {
            params: {
              createdAt_gte: lastLoginDate.toISOString(),
              timestamp: new Date().getTime() // Cache buster
            }
          })
        ]);

        // Filter out items that might have the same timestamp as last login
        const filterByDate = (item: { createdAt: string }) => 
          new Date(item.createdAt).getTime() > lastLoginDate.getTime();

        const newApps = [
          ...banBamResponse.data.filter(filterByDate),
          ...navigatorResponse.data.filter(filterByDate)
        ].length;

        const newBandsCount = bandsResponse.data.filter(filterByDate).length;

        setNewApplications(newApps);
        setNewBands(newBandsCount);

      } catch (error) {
        console.error('Error fetching new items:', error);
        setNewApplications(null);
        setNewBands(null);
      }
    };

    if (user && lastLogin) {
      fetchNewItems();
    }
  }, [user, lastLogin]);

  if (!user) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: '#455e72' }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          {salutation}
        </Typography>
        {lastLogin && (
          <Typography variant="body1">
            Your last login was on: {new Date(lastLogin).toLocaleString()}
          </Typography>
        )}
        {newApplications !== null && (
          <Typography 
            variant="body1" 
            sx={{ 
              mt: 2, 
              fontWeight: 'bold', 
              color: newApplications > 0 ? '#4caf50' : 'inherit' 
            }}
          >
            {newApplications > 0 
              ? `You have ${newApplications} new application${newApplications === 1 ? '' : 's'} since your last login.`
              : 'No new applications since your last login.'}
          </Typography>
        )}
        {newBands !== null && (
          <Typography 
            variant="body1" 
            sx={{ 
              mt: 2, 
              fontWeight: 'bold', 
              color: newBands > 0 ? '#4caf50' : 'inherit' 
            }}
          >
            {newBands > 0 
              ? `${newBands} new band${newBands === 1 ? '' : 's'} added since your last login.`
              : 'No new bands added since your last login.'}
          </Typography>
        )}
        <Typography 
          variant="body1" 
          sx={{ 
            mt: 2, 
            fontWeight: 'bold', 
            color: ticketsSold > 0 ? '#4caf50' : 'inherit' 
          }}
        >
          {ticketsSold > 0 
            ? `${ticketsSold} ticket${ticketsSold === 1 ? '' : 's'} sold since your last login.`
            : 'No tickets sold since your last login.'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default UserGreeting;