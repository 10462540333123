import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Alert,
  SelectChangeEvent
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import axiosInstance from '../utils/axiosConfig';
import { Programming } from '../types/ConcertTypes';
import { Venue } from '../types/mongodb';
import { Strand } from '../types/Strand';
import { Event } from '../types/mongodb';

interface PerformanceConfigDialogProps {
  open: boolean;
  onClose: () => void;
  programming: Programming | null;
  onConfirm: (configData: PerformanceConfigData) => void;
}

interface PerformanceConfigData {
  programmingId: string;
  strandId: string;
  eventId: string;
  venueId: string;
  startTime: string;
  endTime: string;
}

interface FormData {
  strandId: string;
  eventId: string;
  venueId: string;
  startTime: Date | null;
  endTime: Date | null;
}

const PerformanceConfigDialog: React.FC<PerformanceConfigDialogProps> = ({ 
  open, 
  onClose, 
  programming, 
  onConfirm 
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [events, setEvents] = useState<Event[]>([]);

  const getDefaultTimes = (programmingDate: Date | string | undefined) => {
    let baseDate;
    
    if (programmingDate) {
      baseDate = new Date(programmingDate);
    } else {
      baseDate = new Date();
    }

    const startTime = new Date(baseDate);
    startTime.setHours(19, 30, 0);

    const endTime = new Date(baseDate);
    endTime.setHours(23, 30, 0);

    return { startTime, endTime };
  };
  
  const [formData, setFormData] = useState<FormData>(() => {
    const { startTime, endTime } = getDefaultTimes(programming?.date);
    return {
      strandId: '',
      eventId: '',
      venueId: '',
      startTime,
      endTime
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [venuesRes, strandsRes] = await Promise.all([
          axiosInstance.get<Venue[]>('/api/venues'),
          axiosInstance.get<Strand[]>('/api/strands')
        ]);

        setVenues(venuesRes.data);
        
        const currentYear = new Date().getFullYear();
        const now = new Date();

        const filteredStrands = strandsRes.data.filter(strand => {
          const isActive = strand.status !== 'cancelled';
          const isCurrentOrFutureYear = Number(strand.year) >= currentYear;
          const endDate = new Date(strand.endDate);
          const isStillRelevant = endDate >= now;
          const hasEventCapacity = strand.numberOfProposedEvents > 0;
          
          return isActive && 
                 isCurrentOrFutureYear && 
                 isStillRelevant && 
                 hasEventCapacity;
        });

        const sortedStrands = filteredStrands.sort((a, b) => {
          if (a.year !== b.year) {
            return Number(b.year) - Number(a.year);
          }
          return a.name.localeCompare(b.name);
        });

        setStrands(sortedStrands);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load required data');
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!formData.strandId) {
        setEvents([]);
        return;
      }

      try {
        const response = await axiosInstance.get<Event[]>(`/api/strands/${formData.strandId}/events`);
        setEvents(response.data);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events');
      }
    };

    fetchEvents();
  }, [formData.strandId]);

  const handleChange = (field: keyof FormData) => (event: SelectChangeEvent<string>) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleDateChange = (field: 'startTime' | 'endTime') => (newValue: Date | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: newValue
    }));
  };

  const handleSubmit = () => {
    if (!formData.startTime || !formData.endTime || !programming?._id) return;

    const configData: PerformanceConfigData = {
      programmingId: programming._id,
      strandId: formData.strandId,
      eventId: formData.eventId,
      venueId: formData.venueId,
      startTime: format(formData.startTime, "yyyy-MM-dd'T'HH:mm:ss"),
      endTime: format(formData.endTime, "yyyy-MM-dd'T'HH:mm:ss")
    };
    onConfirm(configData);
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Configure Performance Details</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        )}

        <Typography variant="subtitle1" gutterBottom>
          Creating performance for: {programming?.artistName}
        </Typography>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Strand</InputLabel>
              <Select
                value={formData.strandId}
                onChange={handleChange('strandId')}
                label="Strand"
              >
                {strands.map((strand) => (
                  <MenuItem key={strand._id} value={strand._id}>
                    {strand.name} ({strand.year})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Event</InputLabel>
              <Select
                value={formData.eventId}
                onChange={handleChange('eventId')}
                label="Event"
                disabled={!formData.strandId}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {events.map((event) => (
                  <MenuItem key={event._id} value={event._id}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Venue</InputLabel>
              <Select
                value={formData.venueId}
                onChange={handleChange('venueId')}
                label="Venue"
                required
              >
                {venues.map((venue) => (
                  <MenuItem key={venue._id} value={venue._id}>
                    {venue.name} (Capacity: {venue.capacity})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Performance Time
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DateTimePicker 
                  label="Start Time"
                  value={formData.startTime}
                  onChange={handleDateChange('startTime')}
                  slotProps={{ 
                    textField: { 
                      fullWidth: true,
                      helperText: "Default: 19:30 (7:30 PM)"
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimePicker 
                  label="End Time"
                  value={formData.endTime}
                  onChange={handleDateChange('endTime')}
                  slotProps={{ 
                    textField: { 
                      fullWidth: true,
                      helperText: "Default: 23:30 (11:30 PM)"
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={!formData.venueId || !formData.startTime || !formData.endTime}
        >
          Create Performance
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerformanceConfigDialog;