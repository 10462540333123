import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { 
  Email, LocationOn, Person, CalendarToday,
  MusicNote, Article, ReceiptLong, ConfirmationNumberRounded,
  ModeEdit,
  Update
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Performance, BandRecommendation, Venue } from '../../types/mongodb';
import axiosInstance from '../../utils/axiosConfig';
import { Strand } from '../../types/Strand';

interface ActionSectionProps {
  onManageStaffClick: () => void;  // Add this line
  performance: Performance & {
    band?: string | BandRecommendation;
    venue?: string | Venue;
  };
}

interface BandInfo {
  name: string; 
  primaryContact: string;
  email: string;
  phone: string;
}

const ActionSection: React.FC<ActionSectionProps> = ({ performance, onManageStaffClick}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const hasBandRecord = performance.band && 
    typeof performance.band === 'object' && 
    '_id' in performance.band;

  const hasVenueRecord = performance.venue &&
    typeof performance.venue === 'object' &&
    '_id' in performance.venue;

  const getBandInfo = async (): Promise<BandInfo> => {
    // First try BandRecommendation
    if (hasBandRecord) {
      const band = performance.band as BandRecommendation;
      return {
        name: band.artistName,
        primaryContact: band.contactName || '',
        email: band.contactEmail || '',
        phone: band.contactPhone || ''
      };
    }

    // If no BandRecommendation, try Programming database
    const bandName = performance.simpleBandName || '';
    try {
      const response = await axiosInstance.get('/api/programmings/search', {
        params: { artistName: bandName }
      });
      
      if (response.data && response.data.length > 0) {
        const latestProgramming = response.data[0];
        return {
          name: latestProgramming.artistName,
          primaryContact: latestProgramming.primaryContact || '',
          email: latestProgramming.email || '',
          phone: latestProgramming.phone || ''
        };
      }
    } catch (error) {
      console.error('Error fetching programming data:', error);
    }
  

    // Fallback to Performance data
    return {
      name: performance.simpleBandName || '',
      primaryContact: '',  // No primary contact in basic performance data
      email: '',
      phone: ''
    };
  };
  
  const handleTicketReview = async () => {
    try {
      setIsLoading(true);
      // Search for ticket configuration using the performance ID
      const response = await axiosInstance.get('/api/ticket-configs/search', {
        params: {
          performanceId: performance._id,
          selectionMethod: 'event'
        }
      });
      
      if (response.data && response.data._id) {
        // If configuration exists, navigate to its page
        navigate(`/tix-list/${response.data._id}`);
      } else {
        // If no configuration exists, navigate to the main ticketing page
        navigate('/tix-list');
      }
    } catch (error) {
      console.error('Error checking ticket configuration:', error);
      // On error, default to main ticketing page
      navigate('/tix-list');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateContract = async () => {
    try {
        setIsLoading(true);
      
        // Safely access nested properties
        const costs = performance.costs || {};
        const travel = costs.travel || {};
        const accommodation = costs.accommodation || {};
        const perDiem = costs.perDiem || {};

        // Get band info from available sources
        const bandInfo = await getBandInfo();

        // Extract the strand ID correctly
        let strandName = '';
        const strandId = (performance.strandId as Strand)?._id || performance.strandId;  // Extract the _id if it's an object

        console.log("🔍 Checking extracted strandId:", strandId);
        
        if (strandId && typeof strandId === 'string') { 
            try {
                console.log("🚀 Fetching strand data...");
                const strandResponse = await axiosInstance.get(`/api/strands/${strandId}`);
                strandName = strandResponse.data?.name || '';
                console.log("✅ Strand name:", strandName);
            } catch (error) {
                console.error("❌ Error fetching strand data:", error);
            }
        } else {
            console.error("⛔ Invalid strandId:", strandId);
        }

        // Prepare contract data
        const contractData = {
            Item: bandInfo.name,
            Contact: bandInfo.primaryContact,
            Email: bandInfo.email,
            Phone: bandInfo.phone,
            'Event Date': performance.startTime || null,
            Venue: hasVenueRecord ? (performance.venue as Venue).name : '',
            Address: hasVenueRecord ? (performance.venue as Venue).location : '',
            'Soundcheck Time': performance.startTime || null,
            'Performance Type': 'Full Concert',
            'Min Duration': '90 minutes',
            Fees: costs.bandFee?.toString() || '0',
            'Int\'l Flights': travel.flights > 0 ? 'Required' : 'N/A',
            'Ground Transport': travel.groundTransport > 0 ? 'Required' : 'N/A',
            'Accommodation Type': accommodation.singleRooms > 0 
                ? 'Single Room' 
                : accommodation.twinRooms > 0 
                    ? 'Twin Room' 
                    : 'Not Required',
            'Accommodation Qty': ((accommodation.singleRooms || 0) + (accommodation.twinRooms || 0)).toString(),
            'PD Amount': perDiem.dailyRate?.toString() || '0',
            'PD Days': perDiem.numberOfDays?.toString() || '1',
            'PD Quantity': hasBandRecord 
                ? ((performance.band as BandRecommendation).totalMembers || 1).toString() 
                : '1',
            performanceId: performance._id,
            'Stage Time': performance.startTime || null,
            Doors: performance.startTime || null,
            'Soundcheck Duration': '60 minutes',
            'Payment Type': 'bank-transfer',
            'Activities Master': strandName, // ✅ Now correctly set
            'IMC Code': ''
        };

        console.log("✅ Contract Data Prepared:", contractData);

        console.log("✅ Contract Data Prepared:", contractData);
        console.log("🚀 Navigating to Contract Page with Data...");

        setTimeout(() => {
          navigate('/admin/contracts/new', { state: contractData });
        }, 500);

} catch (error) {
        console.error("Error creating contract:", error);
    } finally {
        setIsLoading(false);
    }
};

const handleCreateSchedule = async () => {
  try {
    setIsLoading(true);
    
    // Get venue info
    const venueData = hasVenueRecord ? performance.venue as Venue : null;
    if (!venueData) {
      throw new Error('Venue information is required to create a schedule');
    }
    
    // Get band info
    const bandInfo = await getBandInfo();
    
    console.log('Creating schedule with venue data:', venueData);
    console.log('Band info:', bandInfo);

    // Prepare schedule data
    const scheduleData = {
      performanceId: performance._id,
      performanceData: {
        bandName: bandInfo.name,
        contactName: bandInfo.primaryContact,
        contactEmail: bandInfo.email,
        contactPhone: bandInfo.phone,
        venue: {
          name: venueData.name,
          location: venueData.location,
        },
        timing: {
          date: performance.startTime ? new Date(performance.startTime) : null,
          startTime: performance.startTime ? new Date(performance.startTime) : null,
          endTime: performance.endTime ? new Date(performance.endTime) : null,
          soundcheckTime: performance.startTime ? new Date(performance.startTime) : null,
        },
        costs: {
          perDiem: {
            amount: performance.costs?.perDiem?.dailyRate || 0,
            days: performance.costs?.perDiem?.numberOfDays || 1,
            totalMembers: hasBandRecord ? (performance.band as BandRecommendation).totalMembers || 1 : 1
          },
          accommodation: {
            singleRooms: performance.costs?.accommodation?.singleRooms || 0,
            twinRooms: performance.costs?.accommodation?.twinRooms || 0
          },
          travel: {
            flights: performance.costs?.travel?.flights > 0,
            groundTransport: performance.costs?.travel?.groundTransport > 0
          }
        },
        staff: performance.staff || []
      }
    };

    // Navigate to schedule page with data
    navigate('/admin/performances/schedule', { 
      state: { scheduleData }
    });

  } catch (error) {
    console.error('Error preparing schedule:', error);
  } finally {
    setIsLoading(false);
  }
};

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center' 
        }}>
          <Article sx={{ mr: 1 }} />
          Actions
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: 2 
        }}>
         <Button
          variant="contained"
           color="info"
           startIcon={<ModeEdit />}
          onClick={() => navigate(`/admin/performances/edit/${performance._id}`)}
        >
          Edit Performance Details
        </Button>

        <Button 
      variant="contained"
      color="success"
      startIcon={<Person />}
      onClick={onManageStaffClick} // Trigger scroll
    >
      Manage Staff
    </Button>

          <Button 
            variant="contained" 
            color="primary"
            startIcon={<Email />}
          >
            Contact Artist
          </Button>

          <Button 
            variant="contained" 
            color="secondary"
            startIcon={<LocationOn />}
          >
            Contact Venue
          </Button>

          {hasBandRecord && (
            <Button
              component={Link}
              to={`/band/${(performance.band as BandRecommendation)._id}`}
              variant="outlined"
              startIcon={<MusicNote />}
            >
              View Band Profile
            </Button>
          )}

<Button 
            variant="contained"
            startIcon={<ConfirmationNumberRounded />}
            onClick={handleTicketReview}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Review Ticketing'}
          </Button>


          <Button 
  variant="contained"
  startIcon={<Update />}
  color="success"
  onClick={handleCreateSchedule}
  disabled={isLoading}
>
  {isLoading ? 'Preparing Schedule...' : 'Schedule'}
</Button>

          <Button 
            variant="contained" 
            color="info"            
            startIcon={<ReceiptLong />}
            onClick={handleCreateContract}
            disabled={isLoading}
          >
            {isLoading ? 'Preparing Contract...' : 'Create Contract'}
          </Button>

        </Box>
      </CardContent>
    </Card>
  );
};

export default ActionSection;