// src/utils/pdfExport.ts

import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { ScheduleItem } from './scheduleTemplates';

interface TourDay {
  date: Date;
  items: ScheduleItem[];
}

interface ExportOptions {
  title?: string;
  artist?: string;
  venue?: string;
  venueAddress?: string;
  contactInfo?: {
    name: string;
    email: string;
    phone: string;
  };
  logos?: {
    primary?: string;    // URL for primary logo (e.g., IMC logo)
    secondary?: string;  // URL for secondary logo (e.g., venue logo)
  };
  timings?: {
    date?: string;
  };
}

const loadImage = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error loading image:', error);
    throw error;
  }
};

export const exportScheduleToPDF = async (schedule: TourDay[], options: ExportOptions = {}) => {
  const doc = new jsPDF();
  let yPos = 20;
  const margin = 20;
  const lineHeight = 7;

  // Handle logos if provided
  if (options.logos) {
    try {
      const logoHeight = 15; // Height for logos
      const pageWidth = doc.internal.pageSize.width;

      if (options.logos.primary) {
        const primaryLogoData = await loadImage(options.logos.primary);
        doc.addImage(
          primaryLogoData,
          'PNG',
          margin,
          yPos - 5,
          logoHeight * 2, // Assuming a 1.5:1 aspect ratio
          logoHeight
        );
      }

      if (options.logos.secondary) {
        const secondaryLogoData = await loadImage(options.logos.secondary);
        doc.addImage(
          secondaryLogoData,
          'PNG',
          pageWidth - margin - (logoHeight * 1.5),
          yPos - 5,
          logoHeight * 1.5,
          logoHeight
        );
      }

      yPos += logoHeight + 10; // Add space after logos
    } catch (error) {
      console.error('Error adding logos:', error);
      // Continue without logos if there's an error
    }
  }

  // Add title
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text(options.title || 'Schedule', margin, yPos);
  yPos += lineHeight * 2;

  // Add header info
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  const headerInfo = [];
  if (options.artist) headerInfo.push(`Artist: ${options.artist}`);
  if (options.venue) headerInfo.push(`Venue: ${options.venue}`);
  if (options.venueAddress) headerInfo.push(`Address: ${options.venueAddress}`);
  if (options.timings?.date) {
    const date = new Date(options.timings.date);
    headerInfo.push(`Date: ${format(date, 'EEEE, MMMM do, yyyy')}`);
  }
  if (options.contactInfo) {
    headerInfo.push(
      `Contact: ${options.contactInfo.name}`,
      `Email: ${options.contactInfo.email}`,
      `Phone: ${options.contactInfo.phone}`
    );
  }
  
  headerInfo.forEach(info => {
    doc.text(info, margin, yPos);
    yPos += lineHeight;
  });

  yPos += lineHeight / 2;

  // Process each day
  schedule.forEach((day, dayIndex) => {
    if (yPos > 270) {
      doc.addPage();
      yPos = 20;
    }

    // Add date header
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text(format(day.date, 'EEEE, MMMM do, yyyy'), margin, yPos);
    yPos += lineHeight;

    // Add schedule items
    doc.setFontSize(10);
    day.items.forEach((item) => {
      if (yPos > 280) {
        doc.addPage();
        yPos = 20;
      }

      // Time and description on same line
      doc.setFont('helvetica', 'bold');
      doc.text(item.time, margin, yPos);

      // Description right after time
      doc.setFont('helvetica', 'normal');
      const timeWidth = doc.getTextWidth(item.time + '    ');
      const descText = item.description;
      const maxWidth = doc.internal.pageSize.width - margin - timeWidth - margin;
      const descriptionLines = doc.splitTextToSize(descText, maxWidth);
      
      doc.text(descriptionLines, margin + timeWidth, yPos);
      yPos += lineHeight * (descriptionLines.length || 1);
    });

    if (dayIndex < schedule.length - 1) {
      yPos += lineHeight;
    }
  });

  // Add footer with page numbers
  const pageCount = (doc as any).internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      { align: 'center' }
    );
  }

  // Save the PDF
  const fileName = `${options.artist || 'schedule'}_schedule_${format(new Date(), 'yyyy-MM-dd')}.pdf`;
  doc.save(fileName);
};