import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Input,
  Select,
  Button,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Chip,
  MenuItem,
  SelectChangeEvent,
  Avatar,
  Stack,
  Divider,
  Badge
} from '@mui/material';
import { Eye, PenLine, Link as LinkIcon, Phone, Mail, Search, Users, X, CalendarClock } from 'lucide-react';
import { Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import AssociatePersonnelModal from './AssociatePersonnelModal';

interface Personnel {
  _id: string;
  name: string;
  roles: string[];
  phone?: string;
  email?: string;
  performances?: Performance[]; // ✅ Now expects full performance objects
  profileImage?: string;
  status?: 'active' | 'unavailable';
}

interface Performance {
  _id: string;
  startTime: string;
  simpleBandName?: string;
  endTime: string;
  status: 'pending' | 'confirmed' | 'cancelled';
  band: {
    _id: string;
    artistName: string;
  };
}

interface Filters {
  search: string;
  role: string;
  availability: 'all' | 'available' | 'assigned';
}

const PersonnelList: React.FC = () => {
  const [personnel, setPersonnel] = useState<Personnel[]>([]);
  const [filteredPersonnel, setFilteredPersonnel] = useState<Personnel[]>([]);
  const [filters, setFilters] = useState<Filters>({
    search: '',
    role: 'all',
    availability: 'all'
  });
  const [performances, setPerformances] = useState<Record<string, Performance>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPersonnelId, setSelectedPersonnelId] = useState<string | null>(null);

  const roles = [
    'Sound Engineer',
    'Lighting Engineer',
    'Stage Manager',
    'Front of House',
    'Runner',
    'Production Manager',
    'Stage Hand',
    'Staff',
    'Vision Mix Engineer',
    'Volunteer'
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [personnelResponse, performancesResponse] = await Promise.all([
          axiosInstance.get<Personnel[]>('/api/personnel'),
          axiosInstance.get<Performance[]>('/api/performances?populate=band')
        ]);

        setPersonnel(personnelResponse.data);
        setFilteredPersonnel(personnelResponse.data);

        const performanceMap = performancesResponse.data.reduce((acc, performance) => {
          acc[performance._id] = performance;
          return acc;
        }, {} as Record<string, Performance>);

        setPerformances(performanceMap);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = personnel.filter(person => {
      const matchesSearch = person.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                          person.roles.some(role => role.toLowerCase().includes(filters.search.toLowerCase()));
      const matchesRole = filters.role === 'all' || person.roles.includes(filters.role);
      const matchesAvailability = filters.availability === 'all' || 
        (filters.availability === 'available' ? !person.performances?.length : 
         filters.availability === 'assigned' ? person.performances?.length ?? 0 > 0 : true);
      
      return matchesSearch && matchesRole && matchesAvailability;
    });
    setFilteredPersonnel(filtered);
  }, [filters, personnel]);

  const handleAssociate = (personnelId: string) => {
    setSelectedPersonnelId(personnelId);
    setIsModalOpen(true);
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  };

  const getRoleColor = (role: string): string => {
    const roleColors: Record<string, string> = {
      'Sound Engineer': '#4A90E2',
      'Lighting Engineer': '#7B61FF',
      'Stage Manager': '#FF6B6B',
      'Front of House': '#FFD166',
      'Runner': '#48C774',
      'Production Manager': '#E76F51',
      'Stage Hand': '#1E90FF',
      'Staff': '#2ED573',
      'Vision Mix Engineer': '#9B51E0',
      'Volunteer': '#00B894'
    };
    return roleColors[role] || '#A0AEC0';
  };

  const getLatestPerformance = (person: Personnel) => {
    if (!person.performances || person.performances.length === 0) return null;
  
    return person.performances[0]; // ✅ Returns the latest performance object
  };
  
  return (
    <Box sx={{ p: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
      <Stack spacing={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            component={Link}
            to="/admin/personnel/new"
            variant="contained"
            sx={{
              bgcolor: 'primary.main',
              '&:hover': { bgcolor: 'primary.dark' },
              px: 3
            }}
          >
            Add New Personnel
          </Button>
        </Box>

        <Card sx={{ bgcolor: 'background.paper', p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Search />
                <Input
                  fullWidth
                  placeholder="Search by name or role..."
                  value={filters.search}
                  onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                  sx={{ color: 'text.primary' }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Select
                fullWidth
                value={filters.role}
                onChange={(e: SelectChangeEvent) => setFilters(prev => ({ ...prev, role: e.target.value }))}
                sx={{ bgcolor: 'background.paper' }}
              >
                <MenuItem value="all">All Roles</MenuItem>
                {roles.map(role => (
                  <MenuItem key={role} value={role}>{role}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} md={3}>
              <Select
                fullWidth
                value={filters.availability}
                onChange={(e: SelectChangeEvent) => setFilters(prev => ({ 
                  ...prev, 
                  availability: e.target.value as 'all' | 'available' | 'assigned'
                }))}
                sx={{ bgcolor: 'background.paper' }}
              >
                <MenuItem value="all">All Availability</MenuItem>
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="assigned">Currently Assigned</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setFilters({ search: '', role: 'all', availability: 'all' })}
                startIcon={<X />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Grid container spacing={3}>
          {filteredPersonnel.map(person => {
            const latestPerformance = getLatestPerformance(person);
            
            return (
              <Grid item xs={12} md={6} lg={4} key={person._id}>
                <Card 
                  sx={{ 
                    bgcolor: 'background.paper',
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                      color={person.status === 'unavailable' ? 'error' : 'success'}
                    >
                      <Avatar
                        src={person.profileImage}
                        sx={{ 
                          width: 56, 
                          height: 56,
                          bgcolor: 'primary.main',
                          fontSize: '1.2rem'
                        }}
                      >
                        {getInitials(person.name)}
                      </Avatar>
                    </Badge>

                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                          {person.name}
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <IconButton 
                            component={Link} 
                            to={`/admin/personnel/${person._id}`}
                            sx={{ color: 'primary.main' }}
                          >
                            <Eye />
                          </IconButton>
                          <IconButton 
                            component={Link} 
                            to={`/admin/personnel/edit/${person._id}`}
                            sx={{ color: 'secondary.main' }}
                          >
                            <PenLine />
                          </IconButton>
                          <IconButton 
                            onClick={() => handleAssociate(person._id)}
                            sx={{ color: '#3B82F6' }}
                          >
                            <LinkIcon />
                          </IconButton>
                        </Stack>
                      </Box>

                      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap sx={{ mb: 2 }}>
                        {person.roles.map((role) => (
                          <Chip
                            key={role}
                            label={role}
                            size="small"
                            sx={{
                              bgcolor: getRoleColor(role),
                              color: 'white',
                              mb: 1
                            }}
                          />
                        ))}
                      </Stack>
                    </Box>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    {latestPerformance && (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        color: 'text.secondary',
                        mb: 2
                      }}>
                        <CalendarClock size={16} />
                        <Typography variant="body2">
                          Latest: {latestPerformance.band.artistName}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Divider />

                  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Box>
                      {person.phone && (
                        <Tooltip title={`Call ${person.phone}`}>
                          <IconButton 
                            onClick={() => window.location.href = `tel:${person.phone}`}
                            sx={{ color: 'success.main' }}
                          >
                            <Phone />
                          </IconButton>
                        </Tooltip>
                      )}
                      {person.email && (
                        <Tooltip title={`Email ${person.email}`}>
                          <IconButton 
                            onClick={() => window.location.href = `mailto:${person.email}`}
                            sx={{ color: 'error.main' }}
                          >
                            <Mail />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        fontStyle: 'italic'
                      }}
                    >
                      {!latestPerformance && 'No performances yet'}
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {filteredPersonnel.length === 0 && (
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Users size={48} />
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              No personnel found matching your filters
            </Typography>
            <Button
              onClick={() => setFilters({ search: '', role: 'all', availability: 'all' })}
              sx={{ mt: 2 }}
            >
              Clear all filters
            </Button>
          </Box>
        )}
      </Stack>

      {selectedPersonnelId && (
        <AssociatePersonnelModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          personnelId={selectedPersonnelId}
        />
      )}
    </Box>
  );
};

export default PersonnelList;