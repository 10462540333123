import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import axiosInstance from '../utils/axiosConfig';

interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

interface PopulatedVenue {
  _id: string;
  name: string;
  capacity: number;
}

// Define possible performance ID types
type PerformanceId = string | { _id: string } | { toString(): string };

interface TicketConfiguration {
  _id: string;
  selectionMethod: 'strand' | 'event';
  venueId: PopulatedVenue;
  performanceId: PerformanceId;
  ticketTypes: TicketType[];
  createdAt: string;
  updatedAt: string;
}

interface PerformanceDetails {
  artistName?: string;
  date?: string;
  simpleBandName?: string;
  band?: {
    artistName?: string;
  };
  startTime?: string;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A020F0', '#E91E63'];

// Helper function to safely extract performance ID
const extractPerformanceId = (performanceId: PerformanceId): string => {
  if (typeof performanceId === 'string') {
    return performanceId;
  }
  if ('_id' in performanceId) {
    return performanceId._id;
  }
  return performanceId.toString();
};

const TicketConfigurationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [config, setConfig] = useState<TicketConfiguration | null>(null);
  const [performance, setPerformance] = useState<PerformanceDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // First fetch the ticket configuration
        const configRes = await axiosInstance.get<TicketConfiguration>(`/api/ticket-configs/${id}`);
        setConfig(configRes.data);

        // Then fetch the performance details if we have a performanceId
        if (configRes.data.performanceId) {
          const perfId = extractPerformanceId(configRes.data.performanceId);
          const performanceRes = await axiosInstance.get(`/api/performances/${perfId}`);
          setPerformance(performanceRes.data);
        }
      } catch (err: any) {
        setError(err.message || 'An error occurred while fetching the configuration details.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 4 }} />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!config) return <Typography variant="h6">No configuration found.</Typography>;

  const venueName = config.venueId ? config.venueId.name : 'N/A';

  // Calculate Revenue Data
  let totalRevenue = 0;
  let totalRevenueWithVAT = 0;
  let vatAmount = 0;
  let vatExemptRevenue = 0;
  let revenueImpactingRevenue = 0;

  const ticketData = config.ticketTypes.map((tt) => {
    const revenue = tt.quantity * tt.price;
    const vat = tt.vatExempt ? 0 : revenue * tt.vatRate;
    const revenueWithVAT = revenue - vat;

    totalRevenue += revenue;
    totalRevenueWithVAT += revenueWithVAT;
    vatAmount += vat;

    if (tt.vatExempt) {
      vatExemptRevenue += revenue;
    }
    if (tt.revenueImpact) {
      revenueImpactingRevenue += revenue;
    }

    return {
      name: tt.name,
      value: revenue,
    };
  });

  // Prepare Data for VAT vs. Non-VAT Pie Chart
  const vatData = [
    { name: 'VAT Exempt Sales', value: vatExemptRevenue },
    { name: 'VAT Inclusive Sales', value: totalRevenue - vatExemptRevenue },
  ];

  // Get artist name and date from performance details
  const artistName = performance?.simpleBandName || 
                    performance?.band?.artistName || 
                    'Unknown Artist';
  const eventDate = performance?.startTime ? 
                   new Date(performance.startTime).toLocaleDateString() : 
                   undefined;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Ticket Configuration Overview
        {artistName && ` for ${artistName}`}
        {eventDate && ` (${eventDate})`}
      </Typography>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Venue & Sales Summary
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Venue:</strong> {venueName} (Capacity: {config.venueId.capacity})</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6"><strong>Total Revenue (Incl. VAT):</strong> €{totalRevenue.toFixed(2)}</Typography>
              <Typography variant="h6"><strong>Total VAT:</strong> €{vatAmount.toFixed(2)}</Typography>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#FFD700', mt: 1 }}>
                Total Revenue (Minus VAT): €{totalRevenueWithVAT.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Ticket Sales Breakdown Table */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Ticket Sales Breakdown
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Ticket Name</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Price (€)</strong></TableCell>
                  <TableCell><strong>Revenue (€)</strong></TableCell>
                  <TableCell><strong>VAT Rate</strong></TableCell>
                  <TableCell><strong>Revenue Impact</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {config.ticketTypes.map((tt, i) => (
                  <TableRow key={i}>
                    <TableCell>{tt.name}</TableCell>
                    <TableCell>{tt.quantity}</TableCell>
                    <TableCell>€{tt.price.toFixed(2)}</TableCell>
                    <TableCell>€{(tt.quantity * tt.price).toFixed(2)}</TableCell>
                    <TableCell>
                      {tt.vatExempt ? 'Exempt' : `${(tt.vatRate * 100).toFixed(1)}%`}
                    </TableCell>
                    <TableCell style={{ color: tt.revenueImpact ? 'green' : 'red' }}>
                      {tt.revenueImpact ? '✔ Yes' : '✖ No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Revenue Charts */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Revenue Breakdown by Ticket Type
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie data={ticketData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
                    {ticketData.map((_, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketConfigurationDetail;