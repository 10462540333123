// src/components/PerformanceList.tsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, 
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import { Archive, Undo } from 'lucide-react';
import axios from 'axios';
import PerformanceFilters from './PerformanceFilters';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Performance, Venue, Event, BandRecommendation } from '../types/mongodb';
import { getStatusColor } from '../utils/performanceUtils';
import { isEvent, isBandRecommendation, isStrand } from '../utils/typeGuards';
import type { Strand } from '../types/Strand';

interface PerformanceFiltersState {
  event: string;
  band: string;
  strand: string;
  venue: string;
  status: string;
  dateRange: {
    start: Date | null;
    end: Date | null;
  };
  timeframe: 'all' | 'past' | 'upcoming' | 'thisMonth' | 'thisYear';
}

const PerformanceList: React.FC = () => {
  // State definitions
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [filteredPerformances, setFilteredPerformances] = useState<Performance[]>([]);
  const [events, setEvents] = useState<string[]>([]);
  const [bands, setBands] = useState<string[]>([]);
  const [strands, setStrands] = useState<import('../types/Strand').Strand[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [error, setError] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [selectedPerformance, setSelectedPerformance] = useState<Performance | null>(null);
  const [archiveReason, setArchiveReason] = useState<'pastEvent' | 'cancelled' | 'redundant' | 'other'>('pastEvent');
  const [archiveNotes, setArchiveNotes] = useState('');
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const [filters, setFilters] = useState<PerformanceFiltersState>({
    event: '',
    band: '',
    strand: '',
    venue: '',
    status: '',
    dateRange: {
      start: null,
      end: null
    },
    timeframe: 'upcoming'
  });

  // Helper functions

  const getEventName = (event: string | Event | undefined): string => {
    if (!event) return 'Not assigned to event';
    return isEvent(event) ? event.name : 'Not assigned to event';
  };

  const getBandName = (band: string | BandRecommendation | undefined, simpleBandName?: string): string => {
    if (simpleBandName) return simpleBandName;
    if (!band) return 'Unknown Artist';
    return isBandRecommendation(band) ? band.artistName : 'Unknown Artist';
  };

  const getVenueId = (event: string | Event | undefined): string | undefined => {
    if (!event || !isEvent(event)) return undefined;
    if (!event.venue) return undefined;
    return typeof event.venue === 'string' ? event.venue : event.venue._id;
  };

  // Data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [performancesRes, strandsRes, venuesRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/performances`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/strands`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/venues`)
        ]);

        setPerformances(performancesRes.data);
        setStrands(strandsRes.data);
        setVenues(venuesRes.data);

        const uniqueEvents = Array.from(new Set(
          performancesRes.data
            .map((p: Performance) => getEventName(p.event))
            .filter((name: string): name is string => name !== 'Not assigned to event')
        )) as string[];
        setEvents(uniqueEvents);
        
        const uniqueBands = Array.from(new Set(
          performancesRes.data
            .map((p: Performance) => getBandName(p.band, p.simpleBandName))
            .filter((name: string): name is string => name !== 'Unknown Artist')
        )) as string[];
        setBands(uniqueBands);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
      }
    };

    fetchData();
  }, []);



  // Filter application
useEffect(() => {
  const applyFilters = () => {
    let filtered = performances;

    // Archive status filter
    filtered = filtered.filter(p => 
      tabValue === 0 ? !p.isArchived : p.isArchived
    );

    // Time period filter
    const now = new Date();
    switch (filters.timeframe) {
      case 'upcoming':
        filtered = filtered.filter(p => new Date(p.startTime) > now);
        break;
      case 'past':
        filtered = filtered.filter(p => new Date(p.endTime) < now);
        break;
      case 'thisMonth':
        const thisMonth = now.getMonth();
        const thisYear = now.getFullYear();
        filtered = filtered.filter(p => {
          const perfDate = new Date(p.startTime);
          return perfDate.getMonth() === thisMonth && 
                 perfDate.getFullYear() === thisYear;
        });
        break;
      case 'thisYear':
        filtered = filtered.filter(p => 
          new Date(p.startTime).getFullYear() === now.getFullYear()
        );
        break;
    }

    // Date range filter
    if (filters.dateRange.start) {
      filtered = filtered.filter(p => 
        new Date(p.startTime) >= filters.dateRange.start!
      );
    }
    if (filters.dateRange.end) {
      filtered = filtered.filter(p => 
        new Date(p.startTime) <= filters.dateRange.end!
      );
    }

    // Other filters
    if (filters.event) {
      filtered = filtered.filter(p => getEventName(p.event) === filters.event);
    }

    if (filters.band) {
      filtered = filtered.filter(p => 
        getBandName(p.band, p.simpleBandName) === filters.band
      );
    }

    if (filters.strand) {
      filtered = filtered.filter(p => {
        if (!p.strandId) return false;
        return isStrand(p.strandId) ? p.strandId._id === filters.strand : p.strandId === filters.strand;
      });
    }

    if (filters.venue) {
      filtered = filtered.filter(p => getVenueId(p.event) === filters.venue);
    }

    if (filters.status) {
      filtered = filtered.filter(p => p.status === filters.status);
    }

    // **Sorting by startTime (chronological order)**
    filtered = filtered.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

    setFilteredPerformances(filtered);

    // Update active filters
    const newActiveFilters = [];
    if (filters.event) newActiveFilters.push(`Event: ${filters.event}`);
    if (filters.band) newActiveFilters.push(`Band: ${filters.band}`);
    if (filters.strand) {
      const strandName = strands.find(s => s._id === filters.strand)?.name;
      newActiveFilters.push(`Strand: ${strandName}`);
    }
    if (filters.venue) {
      const venueName = venues.find(v => v._id === filters.venue)?.name;
      newActiveFilters.push(`Venue: ${venueName}`);
    }
    if (filters.status) newActiveFilters.push(`Status: ${filters.status}`);
    if (filters.timeframe !== 'all') newActiveFilters.push(`Time: ${filters.timeframe}`);
    setActiveFilters(newActiveFilters);
  };

  applyFilters();
}, [performances, filters, tabValue, strands, venues]);

  // Event handlers
  const handleFilterChange = (filterName: string, value: any) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const handleClearFilter = (filterText: string) => {
    const [type] = filterText.split(':');
    const filterName = type.trim().toLowerCase();
    
    setFilters(prev => ({
      ...prev,
      [filterName]: ''
    }));
  };

  const handleArchive = async (performance: Performance) => {
    setSelectedPerformance(performance);
    setArchiveDialogOpen(true);
  };

  const handleConfirmArchive = async () => {
    if (!selectedPerformance) return;

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/performances/${selectedPerformance._id}/archive`,
        { reason: archiveReason, notes: archiveNotes }
      );

      setPerformances(prevPerformances => 
        prevPerformances.map(p => 
          p._id === selectedPerformance._id 
            ? { ...p, isArchived: true, archiveReason, archiveNotes } 
            : p
        )
      );

      setArchiveDialogOpen(false);
      setSelectedPerformance(null);
      setArchiveReason('pastEvent');
      setArchiveNotes('');
    } catch (error) {
      console.error('Error archiving performance:', error);
      setError('Failed to archive performance');
    }
  };

  const handleUnarchive = async (performance: Performance) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/performances/${performance._id}/unarchive`);

      setPerformances(prevPerformances => 
        prevPerformances.map(p => 
          p._id === performance._id 
            ? { ...p, isArchived: false, archiveReason: undefined, archiveNotes: undefined } 
            : p
        )
      );
    } catch (error) {
      console.error('Error unarchiving performance:', error);
      setError('Failed to unarchive performance');
    }
  };

  
  const renderPerformanceCard = (performance: Performance) => {
    const now = new Date();
    const startDate = new Date(performance.startTime);
    const daysRemaining = Math.ceil((startDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
  
    return (
      <Grid item xs={12} sm={6} md={4} key={performance._id}>
      <Card sx={{ position: 'relative' }}> {/* Ensure Card has relative positioning */}
      <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Typography variant="h5" component="h2">
                {getBandName(performance.band, performance.simpleBandName)}
              </Typography>
              <IconButton 
                size="small"
                onClick={() => performance.isArchived 
                  ? handleUnarchive(performance)
                  : handleArchive(performance)
                }
              >
                {performance.isArchived ? <Undo /> : <Archive />}
              </IconButton>
            </Box>
            
            
            {performance.strandId && isStrand(performance.strandId) && (
              <Typography color="text.secondary">
                Strand: {performance.strandId.name} - {performance.strandId.year}
              </Typography>
            )}
            
            <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
              {startDate.toLocaleDateString()} {' '}
              {startDate.toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
              })}
            </Typography>
            
            <Chip 
              label={performance.status}
              color={getStatusColor(performance.status)}
              size="small"
            />
            
            {performance.isArchived && performance.archiveDate && (
              <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                Archived: {new Date(performance.archiveDate).toLocaleDateString()}
                {performance.archiveReason && ` - ${performance.archiveReason}`}
              </Typography>
            )}
          </CardContent>
          
          <CardActions>
            <Button 
              size="small" 
              component={Link} 
              to={`/admin/performances/${performance._id}`}
            >
              View Details
            </Button>
          </CardActions>
          
{/* Days Remaining */}
<Box
  sx={{
    position: 'absolute',
    bottom: 16,
    right: 16,
    backgroundColor: daysRemaining > 0 ? '#1976d2' : '#757575', // Change color for past events
    color: 'white',
    borderRadius: '60%',
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5 rem',
    fontWeight: 'bold',
    flexDirection: 'column',
    textAlign: 'center',
    lineHeight: 1.2
  }}
>
  {daysRemaining > 0 ? (
    <>
      <span>{daysRemaining}</span>
      <span style={{ fontSize: '0.8rem' }}>days</span>
    </>
  ) : daysRemaining < 0 ? (
    <>
      <span>{Math.abs(daysRemaining)}</span>
      <span style={{ fontSize: '0.7rem' }}>days ago</span>
    </>
  ) : (
    <>
      <span>Today</span>
    </>
  )}
</Box>
        </Card>
      </Grid>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1">
            Performances
          </Typography>
          <Button 
            component={Link} 
            to="/admin/performances/new" 
            variant="contained" 
            color="primary"
          >
            Create New Performance
          </Button>
        </Box>

        <Tabs 
          value={tabValue} 
          onChange={(_, newValue) => setTabValue(newValue)}
          sx={{ mb: 3 }}
        >
          <Tab label="Active Performances" />
          <Tab label="Archived Performances" />
        </Tabs>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <PerformanceFilters
          events={events}
          bands={bands}
          strands={strands}
          venues={venues}
          filters={filters}
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          onClearFilter={handleClearFilter}
        />

        <Grid container spacing={3}>
          {filteredPerformances.length > 0 ? (
            filteredPerformances.map(renderPerformanceCard)
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" color="text.secondary">
                No performances found matching the selected filters.
              </Typography>
            </Grid>
          )}
        </Grid>

        <Dialog open={archiveDialogOpen} onClose={() => setArchiveDialogOpen(false)}>
          <DialogTitle>Archive Performance</DialogTitle>
          <DialogContent>
            <TextField
              select
              fullWidth
              label="Archive Reason"
              value={archiveReason}
              onChange={(e) => setArchiveReason(e.target.value as 'pastEvent' | 'cancelled' | 'redundant' | 'other')}
              margin="normal"
            >
              <MenuItem value="pastEvent">Past Event</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="redundant">Redundant</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
            
            <TextField
              fullWidth
              label="Archive Notes"
              value={archiveNotes}
              onChange={(e) => setArchiveNotes(e.target.value)}
              margin="normal"
              multiline
              rows={3}
            />
          </DialogContent>
          
          <DialogActions>
            <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmArchive} color="primary">
              Archive
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default PerformanceList;