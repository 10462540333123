import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  FormHelperText,
  SelectChangeEvent
} from '@mui/material';
import { Venue, Event } from '../../../../types/mongodb';
import { Strand } from '../../../../types/Strand';

interface ProgrammingSectionProps {
  formData: {
    strandId: string;
    eventId: string;
    venue: string;
  };
  strands: Strand[];
  events: Event[];
  venues: Venue[];
  strandCounts: Record<string, {
    totalEvents: number;
    programmedEvents: number;
    remainingEvents: number;
  }>;
  onFieldChange: (e: SelectChangeEvent<string>) => void;
}

export const ProgrammingSection: React.FC<ProgrammingSectionProps> = ({
  formData,
  strands,
  events,
  venues,
  strandCounts,
  onFieldChange,
}) => {
  // Ensure all values are strings
  const strandValue = typeof formData.strandId === 'string' ? formData.strandId : '';
  const eventValue = typeof formData.eventId === 'string' ? formData.eventId : '';
  const venueValue = typeof formData.venue === 'string' ? formData.venue : '';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Programming Association</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Strand</InputLabel>
              <Select
                name="strandId"
                value={strandValue}
                onChange={onFieldChange}
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {strands
                  .filter(strand => Number(strand.year) >= 2025)
                  .map((strand) => (
                    <MenuItem 
                      key={strand._id} 
                      value={strand._id?.toString() || ''}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        width: '100%' 
                      }}>
                        <span>{strand.name} - ({strand.year})</span>
                        <Typography
                          component="span"
                          sx={{
                            ml: 2,
                            color: 'text.secondary',
                            fontSize: '0.9em'
                          }}
                        >
                          {strand._id && (strandCounts[strand._id]?.remainingEvents ?? 0)} of {strand.numberOfProposedEvents} events remaining
                        </Typography>
                      </Box>
                    </MenuItem> 
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {strandValue && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Associated Event (Optional)</InputLabel>
                <Select
                  name="eventId"
                  value={eventValue}
                  onChange={onFieldChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {events.map((event) => (
                    <MenuItem key={event._id} value={event._id?.toString() || ''}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Events are optional and can be added later
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Venue</Typography>
        <FormControl fullWidth>
          <InputLabel>Venue</InputLabel>
          <Select
            name="venue"
            value={venueValue}
            onChange={onFieldChange}
            required
          >
            <MenuItem value="">
              <em>Select a venue</em>
            </MenuItem>
            {venues.map((venue) => (
              <MenuItem key={venue._id} value={venue._id?.toString() || ''}>
                {venue.name} (Capacity: {venue.capacity})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ProgrammingSection;