import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  ButtonGroup,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';
import { Download } from 'lucide-react';
import Papa from 'papaparse';


// Define the location keys type
export type LocationKey = 'BALLINA' | 'SOURCE' | 'GRIANAN' | 'COURTHOUSE' | 'GARTER' | 'RIVERBANK' | 'ANY';

const LOCATIONS: Record<LocationKey, string> = {
  BALLINA: 'Ballina Arts Centre - June-August 2025',
  SOURCE: 'The Source Arts Centre, Thurles - May-June 2025',
  GRIANAN: 'An Grianán Theatre, Letterkenny - June-July 2025',
  COURTHOUSE: 'Courthouse Arts, Tinahely - TBC 2025',
  GARTER: 'Garter Lane, Waterford - TBC 2025',
  RIVERBANK: 'Riverbank Arts Centre, Kildare. - TBC 2025',
  ANY: 'Any of the above! - See Date Options Listed above',
};

// Define the application interface
interface Application {
  _id: string;
  name: string;
  email: string;
  instrument: string[];
  locationDates: string[];
  status: string;
  createdAt: string;
}

interface LocationFilterProps {
  applications: Application[];
  onFilterChange: (location: LocationKey | 'all') => void;
}

const LocationFilter: React.FC<LocationFilterProps> = ({ applications, onFilterChange }) => {
  const [selectedLocation, setSelectedLocation] = useState<LocationKey | 'all'>('all');

  const getApplicationsForLocation = (location: LocationKey): Application[] => {
    // First get applications that specifically selected this location
    const specificApplications = applications.filter(app => 
      app.locationDates.some(loc => loc === LOCATIONS[location])
    );

    // Then get applications that selected "Any"
    const anyLocationApplications = applications.filter(app =>
      app.locationDates.some(loc => loc === LOCATIONS.ANY)
    );

    // Combine them, with specific selections first
    return [...specificApplications, ...anyLocationApplications];
  };

  const handleLocationClick = (location: LocationKey) => {
    const newLocation = selectedLocation === location ? 'all' : location;
    setSelectedLocation(newLocation);
    onFilterChange(newLocation);
  };

  const exportLocationData = (location: LocationKey) => {
    const locationApplications = getApplicationsForLocation(location);
    
    const csvData = locationApplications.map(app => ({
      Name: app.name,
      Email: app.email,
      Instruments: app.instrument.join('; '),
      Status: app.status,
      SubmittedDate: new Date(app.createdAt).toLocaleDateString(),
      LocationPreference: app.locationDates.includes(LOCATIONS[location]) ? 'Specific' : 'Any',
      AllPreferences: app.locationDates.join('; ')
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${location.toLowerCase()}-applications.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const locationButtons = (Object.keys(LOCATIONS) as LocationKey[])
    .filter(loc => loc !== 'ANY')
    .map(location => {
      const locationApps = getApplicationsForLocation(location);
      const specificCount = applications.filter(app => 
        app.locationDates.some(loc => loc === LOCATIONS[location])
      ).length;
      const anyCount = locationApps.length - specificCount;

      return (
        <Box key={location} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            variant={selectedLocation === location ? "contained" : "outlined"}
            onClick={() => handleLocationClick(location)}
            sx={{
              minWidth: '120px',
              bgcolor: selectedLocation === location ? 
                `primary.main` : 
                'background.paper'
            }}
          >
            {location.split('_').join(' ')}
            <Typography
              variant="caption"
              sx={{
                ml: 1,
                bgcolor: alpha('#000', 0.1),
                px: 1,
                borderRadius: 1,
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >
              {specificCount}{anyCount > 0 && `+${anyCount}`}
            </Typography>
          </Button>
          <Tooltip title="Export location applications">
            <Button
              size="small"
              variant="outlined"
              onClick={() => exportLocationData(location)}
              sx={{ minWidth: 'auto', p: 1 }}
            >
              <Download size={16} />
            </Button>
          </Tooltip>
        </Box>
      );
    });

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" gutterBottom>
        Filter by Location
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 1,
        '& > *': { mb: 1 }
      }}>
        {locationButtons}
      </Box>
    </Box>
  );
};

export default LocationFilter;