// src/components/performance/PerformanceCosts.tsx
import React, { useEffect, useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import { Card, CardContent, Typography, TextField, Grid, Button } from '@mui/material';
import { Performance, BandRecommendation, Personnel } from '../../types/mongodb';

interface PerformanceCostsProps {
  performance: Performance;
  onUpdate: (updatedPerformance: Performance) => void;
}

const PerformanceCosts: React.FC<PerformanceCostsProps> = ({ performance, onUpdate }) => {
  const [band, setBand] = useState<BandRecommendation | null>(null);
  const [personnel, setPersonnel] = useState<Personnel[]>([]);

  useEffect(() => {
    // Fetch band details
    if (typeof performance.band === 'string') {
      // Fetch band details using the ID
      // setBand(fetchedBand);
    } else {
      setBand(performance.band);
    }

    // Fetch personnel details
    // setPersonnel(fetchedPersonnel);
  }, [performance.band]);


  const handleCostChange = (category: keyof Performance['costs'], field: string, value: number) => {
    onUpdate({
      ...performance,
      costs: {
        ...performance.costs,
        [category]: typeof performance.costs[category] === 'object'
          ? { ...(performance.costs[category] as object), [field]: value }
          : value,
      }
    });
  };

  const handlePersonnelChange = (personnelId: string, fee: number) => {
    onUpdate({
      ...performance,
      costs: {
        ...performance.costs,
        personnel: {
          ...performance.costs.personnel,
          [personnelId]: fee,
        },
      },
    });
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Performance Costs
        </Typography>

        <Typography variant="h6" gutterBottom>
          Band Fee
        </Typography>
        <TextField
  label="Performance Fee (€)"
  type="text"
  fullWidth
  value={`€ ${performance?.costs?.bandFee ?? 0}`}
  onChange={(e) => {
    // Remove any non-numeric characters except dots
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    handleCostChange("bandFee", "fee", parseFloat(numericValue) || 0);
  }}
  sx={{ mb: 2 }}
/>

        <Typography variant="h6" gutterBottom>
          Travel Costs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Flights (€)"
              type="number"
              fullWidth
              value={performance.costs.travel.flights || 0}
              onChange={(e) => handleCostChange('travel', 'flights', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Ground Transport (€)"
              type="number"
              fullWidth
              value={performance.costs.travel.groundTransport || 0}
              onChange={(e) => handleCostChange('travel', 'groundTransport', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Other Travel (€)"
              type="number"
              fullWidth
              value={performance.costs.travel.other || 0}
              onChange={(e) => handleCostChange('travel', 'other', parseFloat(e.target.value) || 0)}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Accommodation Costs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Single Rooms"
              type="number"
              fullWidth
              value={performance.costs.accommodation.singleRooms || 0}
              onChange={(e) => handleCostChange('accommodation', 'singleRooms', parseInt(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Twin Rooms"
              type="number"
              fullWidth
              value={performance.costs.accommodation.twinRooms || 0}
              onChange={(e) => handleCostChange('accommodation', 'twinRooms', parseInt(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Cost per Room (€)"
              type="number"
              fullWidth
              value={performance.costs.accommodation.costPerRoom || 0}
              onChange={(e) => handleCostChange('accommodation', 'costPerRoom', parseFloat(e.target.value) || 0)}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Per Diem
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Daily Rate (€)"
              type="number"
              fullWidth
              value={performance.costs.perDiem.dailyRate || 0}
              onChange={(e) => handleCostChange('perDiem', 'dailyRate', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Number of Days"
              type="number"
              fullWidth
              value={performance.costs.perDiem.numberOfDays || 0}
              onChange={(e) => handleCostChange('perDiem', 'numberOfDays', parseInt(e.target.value) || 0)}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Equipment Costs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Rental (€)"
              type="number"
              fullWidth
              value={performance.costs.equipment.rental || 0}
              onChange={(e) => handleCostChange('equipment', 'rental', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Shipping (€)"
              type="number"
              fullWidth
              value={performance.costs.equipment.shipping || 0}
              onChange={(e) => handleCostChange('equipment', 'shipping', parseFloat(e.target.value) || 0)}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Personnel Costs
        </Typography>
        {personnel.map((person) => (
          <Grid container spacing={2} key={person._id} sx={{ mb: 1 }}>
            <Grid item xs={8}>
              <Typography>{person.name} - {person.role}</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Fee (€)"
                type="number"
                fullWidth
                value={performance.costs.personnel[person._id] || 0}
                onChange={(e) => handlePersonnelChange(person._id, parseFloat(e.target.value) || 0)}
              />
            </Grid>
          </Grid>
        ))}
        <Button variant="outlined" sx={{ mt: 1 }}>Add Personnel</Button>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Other Costs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Marketing (€)"
              type="number"
              fullWidth
              value={performance.costs.marketing || 0}
              onChange={(e) => handleCostChange('marketing', 'marketing', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Insurance (€)"
              type="number"
              fullWidth
              value={performance.costs.insurance || 0}
              onChange={(e) => handleCostChange('insurance', 'insurance', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Visas (€)"
              type="number"
              fullWidth
              value={performance.costs.visas || 0}
              onChange={(e) => handleCostChange('visas', 'visas', parseFloat(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Other Costs (€)"
              type="number"
              fullWidth
              value={performance.costs.other || 0}
              onChange={(e) => handleCostChange('other', 'other', parseFloat(e.target.value) || 0)}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Contingency
        </Typography>
        <TextField
          label="Contingency Rate (%)"
          type="number"
          fullWidth
          name="contingencyRate"
          value={performance.contingencyRate || 0}
          onChange={(e) => onUpdate({...performance, contingencyRate: parseFloat(e.target.value) || 0})}
          sx={{ mb: 2 }}
        />
      </CardContent>
    </Card>
  );
};

export default PerformanceCosts;
