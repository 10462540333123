import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Button } from '@mui/material';
import axios from 'axios';
import VenueCard from '../components/VenueCard';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';

interface Venue {
  _id: string;
  name: string;
  capacity: number;
  location: string;
}

const VenueListPage: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        setLoading(true);
        const response = await axios.get<Venue[]>(`${process.env.REACT_APP_API_URL}/api/venues`);
        setVenues(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching venues:', error);
        setError('Failed to fetch venues. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVenues();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Venues
      </Typography>
      <Button component={Link} to="/admin/venues/new" variant="contained" color="primary" style={{ marginBottom: '20px' }}>
        Add New Venue
      </Button>
      <Grid container spacing={3}>
        {venues.map((venue) => (
          <Grid item xs={12} sm={6} md={4} key={venue._id}>
            <VenueCard venue={venue} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VenueListPage;