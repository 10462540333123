import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
  Chip,
  Button,
  alpha,
  Stack
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { format, startOfMonth, endOfMonth, eachWeekOfInterval, addDays, isAfter } from 'date-fns';
import { ListFilter, PieChart as PieChartIcon, Download, BarChartIcon, Users } from 'lucide-react';
import { Programming } from '../types/ConcertTypes';

interface ProgramAnalysisProps {
  programs: Programming[];
}

interface FilterState {
  status: string[];
  genre: string[];
  ensembleMakeup: string[];
  expectedDraw: string[];
}

const STATUS_COLORS = {
  BOOKED: '#ef5350',
  PROVISIONAL: '#FFB300',
  CHECKING: '#42a5f5',
  AVAILABLE: '#66bb6a'
} as const;

const GENDER_COLORS = {
  'all-male': '#2196f3',
  'all-female': '#e91e63',
  'mixed': '#9c27b0',
  'predominantly-male': '#90caf9',
  'female-led': '#f06292',
  'predominantly-female': '#f48fb1'
} as const;

const DRAW_COLORS = {
  LOW: '#ffb74d',
  MEDIUM: '#4fc3f7',
  HIGH: '#81c784',
  EXCEL: '#7e57c2'
} as const;

const ProgramAnalysis: React.FC<ProgramAnalysisProps> = ({ programs }) => {
  const [filters, setFilters] = useState<FilterState>({
    status: [],
    genre: [],
    ensembleMakeup: [],
    expectedDraw: []
  });

  // Get all unique genres from programs
  const uniqueGenres = useMemo(() => 
    Array.from(new Set(programs.map(p => p.genre))).sort(),
    [programs]
  );

  const filteredPrograms = useMemo(() => {
    return programs.filter(program => {
      const matchesStatus = filters.status.length === 0 || filters.status.includes(program.status);
      const matchesGenre = filters.genre.length === 0 || filters.genre.includes(program.genre);
      const matchesMakeup = filters.ensembleMakeup.length === 0 || filters.ensembleMakeup.includes(program.ensembleMakeup);
      const matchesDraw = filters.expectedDraw.length === 0 || filters.expectedDraw.includes(program.expectedDraw);
      return matchesStatus && matchesGenre && matchesMakeup && matchesDraw;
    });
  }, [programs, filters]);

  const stats = useMemo(() => {
    // Status breakdown for pie chart
    const statusData = Object.keys(STATUS_COLORS).map(status => ({
      name: status,
      value: filteredPrograms.filter(p => p.status === status).length,
      color: STATUS_COLORS[status as keyof typeof STATUS_COLORS]
    }));

    // Gender balance for pie chart
    const genderData = Object.keys(GENDER_COLORS).map(makeup => ({
      name: makeup.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      value: filteredPrograms.filter(p => p.ensembleMakeup === makeup).length,
      color: GENDER_COLORS[makeup as keyof typeof GENDER_COLORS]
    }));

    // Genre breakdown for bar chart
    const genreData = uniqueGenres.map(genre => ({
      name: genre,
      count: filteredPrograms.filter(p => p.genre === genre).length
    }));

    // Expected draw breakdown for bar chart
    const drawData = Object.keys(DRAW_COLORS).map(draw => ({
      name: draw,
      value: filteredPrograms.filter(p => p.expectedDraw === draw).length,
      color: DRAW_COLORS[draw as keyof typeof DRAW_COLORS]
    }));

    // Available Thursdays calculation
    const now = new Date();
    const monthStart = startOfMonth(now);
    const monthEnd = endOfMonth(now);
    const weeks = eachWeekOfInterval({ start: monthStart, end: monthEnd });
    const thursdays = weeks.map(week => addDays(week, 4))
      .filter(date => isAfter(date, now));
    const availableThursdays = thursdays.length;
    const bookedThursdays = filteredPrograms.filter(p => p.status === 'BOOKED').length;
    const bookingProgress = (bookedThursdays / availableThursdays) * 100;

    return {
      statusData,
      genderData,
      genreData,
      drawData,
      availableThursdays,
      bookedThursdays,
      bookingProgress
    };
  }, [filteredPrograms, uniqueGenres]);

  const handleFilterChange = (type: keyof FilterState) => (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setFilters(prev => ({
      ...prev,
      [type]: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: [],
      genre: [],
      ensembleMakeup: [],
      expectedDraw: []
    });
  };

  const exportData = () => {
    const csv = [
      ['Date', 'Artist', 'Genre', 'Status', 'Ensemble', 'Expected Draw', 'Price', 'Notes'].join(','),
      ...filteredPrograms.map(p => [
        format(new Date(p.date), 'yyyy-MM-dd'),
        `"${p.artistName}"`,
        `"${p.genre}"`,
        p.status,
        p.ensembleMakeup,
        p.expectedDraw,
        p.pricePoint,
        `"${p.notes || ''}"`
      ].join(','))
    ].join('\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `programming-analysis-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filters */}
      <Card sx={{ mb: 3 }}>
        <CardHeader 
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ListFilter size={24} />
              <Typography variant="h6">Filters</Typography>
            </Box>
          }
          action={
            <Button 
              variant="outlined" 
              size="small" 
              onClick={clearFilters}
              disabled={!Object.values(filters).some(f => f.length > 0)}
            >
              Clear Filters
            </Button>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  multiple
                  value={filters.status}
                  onChange={handleFilterChange('status')}
                  label="Status"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value} 
                          size="small"
                          sx={{ 
                            bgcolor: alpha(STATUS_COLORS[value as keyof typeof STATUS_COLORS], 0.1),
                            borderColor: STATUS_COLORS[value as keyof typeof STATUS_COLORS]
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Object.keys(STATUS_COLORS).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Genre</InputLabel>
                <Select
                  multiple
                  value={filters.genre}
                  onChange={handleFilterChange('genre')}
                  label="Genre"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                    </Box>
                  )}
                >
                  {uniqueGenres.map((genre) => (
                    <MenuItem key={genre} value={genre}>
                      {genre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Ensemble Makeup</InputLabel>
                <Select
                  multiple
                  value={filters.ensembleMakeup}
                  onChange={handleFilterChange('ensembleMakeup')}
                  label="Ensemble Makeup"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value.replace(/-/g, ' ')} 
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Object.keys(GENDER_COLORS).map((makeup) => (
                    <MenuItem key={makeup} value={makeup}>
                      {makeup.replace(/-/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Expected Draw</InputLabel>
                <Select
                  multiple
                  value={filters.expectedDraw}
                  onChange={handleFilterChange('expectedDraw')}
                  label="Expected Draw"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value} 
                          size="small"
                          sx={{ 
                            bgcolor: alpha(DRAW_COLORS[value as keyof typeof DRAW_COLORS], 0.1),
                            borderColor: DRAW_COLORS[value as keyof typeof DRAW_COLORS]
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Object.keys(DRAW_COLORS).map((draw) => (
                    <MenuItem key={draw} value={draw}>
                      {draw}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Stats Grid */}
      <Grid container spacing={3}>
        {/* Booking Progress */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                  Programming Progress - {stats.availableThursdays} Thursday{stats.availableThursdays !== 1 ? 's' : ''} Remaining
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Download size={16} />}
                  onClick={exportData}
                >
                  Export Data
                </Button>
              </Stack>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={stats.bookingProgress} 
                  sx={{ 
                    height: 10, 
                    borderRadius: 5,
                    backgroundColor: alpha(STATUS_COLORS.AVAILABLE, 0.2),
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: STATUS_COLORS.BOOKED
                    }
                  }}
                />
              </Box>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {stats.bookedThursdays} of {stats.availableThursdays} Thursdays booked
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Status Breakdown */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader 
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PieChartIcon size={24} />
                  <Typography variant="h6">Status Breakdown</Typography>
                </Box>
              }
            />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={stats.statusData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {stats.statusData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

                {/* Gender Balance */}
                <Grid item xs={12} md={6}>
          <Card>
            <CardHeader 
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Users size={24} />
                  <Typography variant="h6">Gender Balance</Typography>
                </Box>
              }
            />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={stats.genderData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {stats.genderData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Genre Breakdown */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader 
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <BarChartIcon size={24} />
                  <Typography variant="h6">Genre Distribution</Typography>
                </Box>
              }
            />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={stats.genreData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      angle={-45}
                      textAnchor="end"
                      height={70}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8">
                      {stats.genreData.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={`hsl(${(index * 360) / stats.genreData.length}, 70%, 50%)`} 
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Expected Draw */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader 
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <BarChartIcon size={24} />
                  <Typography variant="h6">Expected Draw Distribution</Typography>
                </Box>
              }
            />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={stats.drawData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value">
                      {stats.drawData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgramAnalysis;