import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid
} from '@mui/material';
import { Venue } from '../../../../types/mongodb';
import axiosInstance from '../../../../utils/axiosConfig';

interface RevenueProjectionsProps {
  ticketConfigId: string;
  venue: Venue;
  costs: number;
  contingency: number;
}

export const RevenueProjections: React.FC<RevenueProjectionsProps> = ({
  ticketConfigId,
  venue,
  costs,
  contingency
}) => {
  const [revenue, setRevenue] = useState<{
    gross: number;
    net: number;
    vat: number;
  }>({ gross: 0, net: 0, vat: 0 });

  useEffect(() => {
    const fetchRevenue = async () => {
      try {
        const response = await axiosInstance.get(`/api/ticket-configs/${ticketConfigId}/revenue`);
        setRevenue(response.data);
      } catch (error) {
        console.error('Failed to fetch revenue data:', error);
      }
    };

    if (ticketConfigId) {
      fetchRevenue();
    }
  }, [ticketConfigId]);

  const calculateProjections = () => {
    const capacity = venue.capacity;
    const avgTicketPrice = revenue.gross / capacity;

    const scenarios = {
      worst: Math.floor(capacity * 0.3),
      expected: Math.floor(capacity * 0.6),
      best: Math.floor(capacity * 0.9),
    };

    const totalCosts = costs + contingency;

    return {
      capacity,
      scenarios: Object.entries(scenarios).reduce((acc, [key, attendance]) => ({
        ...acc,
        [key]: {
          attendance,
          revenue: attendance * avgTicketPrice,
          profit: (attendance * avgTicketPrice) - totalCosts
        }
      }), {} as Record<string, { attendance: number; revenue: number; profit: number }>),
      breakeven: {
        tickets: Math.ceil(totalCosts / avgTicketPrice),
        percentage: avgTicketPrice ? Math.ceil((totalCosts / avgTicketPrice) / capacity * 100) : 0
      }
    };
  };

  const projections = calculateProjections();

  return (
    <Box sx={{ 
      bgcolor: 'background.paper', 
      p: 3, 
      borderRadius: 1,
      mt: 3 
    }}>
      <Typography variant="h6" gutterBottom>Revenue Projections</Typography>
      <Typography gutterBottom>
        Venue Capacity: {projections.capacity} people
      </Typography>
      
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {Object.entries(projections.scenarios).map(([scenario, data]) => (
          <Grid item xs={12} md={4} key={scenario}>
            <Box sx={{ 
              p: 2, 
              border: 1, 
              borderColor: 'divider',
              borderRadius: 1
            }}>
              <Typography variant="subtitle1" gutterBottom sx={{ textTransform: 'capitalize' }}>
                {scenario} Case
              </Typography>
              <Typography>Attendance: {data.attendance}</Typography>
              <Typography>Revenue: €{data.revenue.toFixed(2)}</Typography>
              <Typography sx={{ 
                color: data.profit >= 0 ? 'success.main' : 'error.main',
                fontWeight: 'bold'
              }}>
                {data.profit >= 0 ? 'Profit' : 'Subvention Required'}: €{Math.abs(data.profit).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 3, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Break-even Analysis
        </Typography>
        <Typography>
          Need to sell {projections.breakeven.tickets} tickets ({projections.breakeven.percentage}% capacity)
        </Typography>
      </Box>
    </Box>
  );
};

export default RevenueProjections;