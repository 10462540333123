import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Button,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ArrowLeft, Calendar, List, PieChart } from 'lucide-react';
import ProgramScheduler from '../components/QuickProgramInput';
import ScheduleView from '../components/ScheduleView';
import ProgramAnalysis from '../components/ProgramAnalysis';
import { Programming } from '../types/ConcertTypes';
import { Performance } from '../types/mongodb';
import axiosInstance from '../utils/axiosConfig';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ProgramDeleteDialog {
  open: boolean;
  program: Programming | null;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`programming-tabpanel-${index}`}
      aria-labelledby={`programming-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `programming-tab-${index}`,
    'aria-controls': `programming-tabpanel-${index}`,
  };
};

const ProgrammingPage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [editingProgram, setEditingProgram] = useState<Programming | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [programs, setPrograms] = useState<Programming[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<ProgramDeleteDialog>({
    open: false,
    program: null
  });

  // Add this function to fetch programs
const fetchPrograms = async () => {
    try {
      const response = await axiosInstance.get('/api/programmings');
      setPrograms(response.data);
    } catch (err) {
      console.error('Failed to fetch programs:', err);
      setError('Failed to load programming data');
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (!editingProgram) {
      setTabValue(newValue);
    }
  };

  const handleAddNew = (date: Date) => {
    setSelectedDate(date);
    setTabValue(0); // Switch to the programming form tab
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleEdit = (program: Programming) => {
    setEditingProgram(program);
    setTabValue(0); // Switch to ProgramScheduler tab
  };

  const handleCreatePerformance = async (program: Programming) => {
    try {
      const response = await axiosInstance.post(
        `/api/programmings/${program._id}/create-performance`
      );
      
      // Navigate to the new performance
      window.location.href = `/admin/performances/${response.data._id}`;
    } catch (error) {
      console.error('Error creating performance:', error);
      setError('Failed to create performance');
    }
  };

  const handleDelete = (program: Programming) => {
    setDeleteDialog({
      open: true,
      program
    });
  };

  const handleConfirmDelete = async () => {
    if (!deleteDialog.program?._id) return;

    try {
      await axiosInstance.delete(`/api/programmings/${deleteDialog.program._id}`);
      setDeleteDialog({ open: false, program: null });
      // You might want to trigger a refresh here
    } catch (err) {
      console.error('Failed to delete programming:', err);
      setError('Failed to delete programming');
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialog({ open: false, program: null });
  };

  const handleProgramSaved = () => {
    setEditingProgram(null);
    setTabValue(1); // Switch back to schedule view
    fetchPrograms(); // Refresh the data    
  };

  const handleProgramError = (errorMessage: string) => {
    setError(errorMessage);
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Paper 
        square 
        elevation={2} 
        sx={{ 
          position: 'sticky', 
          top: 0, 
          zIndex: 1100,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ 
            py: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton onClick={handleBack} size="small">
                <ArrowLeft />
              </IconButton>
              
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/admin">
                  Admin
                </Link>
                <Typography color="text.primary">Programming</Typography>
              </Breadcrumbs>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button 
                variant="outlined" 
                startIcon={<List size={18} />}
                onClick={() => setTabValue(1)}
              >
                View Schedule
              </Button>
            </Box>
          </Box>

          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            aria-label="programming tabs"
          >
            <Tab 
              icon={<Calendar size={18} />} 
              iconPosition="start" 
              label={editingProgram ? "Edit Program" : "Quick Program"}
              {...a11yProps(0)}
            />
            <Tab 
              icon={<List size={18} />} 
              iconPosition="start" 
              label="Schedule"
              {...a11yProps(1)}
            />
              <Tab 
    icon={<PieChart size={18} />} 
    iconPosition="start" 
    label="Analysis"
    {...a11yProps(2)}
  />
          </Tabs>
        </Container>
      </Paper>

      <Container maxWidth="xl" sx={{ mt: 3, mb: 8 }}>
        {error && (
          <Alert 
            severity="error" 
            onClose={() => setError(null)}
            sx={{ mb: 3 }}
          >
            {error}
          </Alert>
        )}

<TabPanel value={tabValue} index={0}>
  <ProgramScheduler 
    initialData={editingProgram}
    selectedDate={selectedDate}
    onSaved={handleProgramSaved}
    onError={handleProgramError}
  />
</TabPanel>

        <TabPanel value={tabValue} index={1}>
  <Paper sx={{ p: 3 }}>
    <ScheduleView 
      onEdit={handleEdit}
      onDelete={handleDelete}
      onCreatePerformance={handleCreatePerformance}
      onAddNew={() => setTabValue(0)}
    />
  </Paper>
</TabPanel>

<TabPanel value={tabValue} index={2}>
  <Paper sx={{ p: 3 }}>
    <ProgramAnalysis programs={programs} />
  </Paper>
</TabPanel>

      </Container>

      <Dialog
        open={deleteDialog.open}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the programming for{' '}
            {deleteDialog.program?.artistName} on{' '}
            {deleteDialog.program?.date && new Date(deleteDialog.program.date).toLocaleDateString()}?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProgrammingPage;