import React from 'react';
import { useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import IMCContractForm from '../../components/contracts/IMCContractForm';

const NewContractPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const contractData = location.state || {}; // Retrieve passed contract data

  useEffect(() => {
    console.log("🛠️ Loaded Contract Data:", contractData);
}, []);

  const handleSubmit = () => {
    // Handle form submission
    navigate('/admin/contracts');
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          New Contract
        </Typography>
        <IMCContractForm onSubmit={handleSubmit} initialData={contractData} /> {/* ✅ Pass contractData */}
        </Box>
    </Container>
  );
};

export default NewContractPage;