import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  Container, Typography, Paper, Button, Grid, CircularProgress,
  Accordion, AccordionSummary, AccordionDetails, Chip, Box,
  Table, TableBody, TableCell, TableHead, TableRow,
  Divider
} from '@mui/material';
import {
  ExpandMore, Wifi, LocalParking, Shower, Restaurant,
  MeetingRoom, AccessTime, VolumeUp, Lightbulb, 
  LocalBar, AccessibilityNew, LocalShipping, Language,
  Person, WheelchairPickupRounded, Email, Phone, Store, EventSeat, DirectionsRun,
  Elevator, Description, MapOutlined, CalendarToday,
  Timer, Engineering, Euro, SecurityOutlined,
  MeetingRoomOutlined, Weekend, Wc, Checkroom, BreakfastDining,
} from '@mui/icons-material';
import { Venue } from '@/types/mongodb';
import axios from 'axios';

const VenueDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const response = await axios.get<Venue>(`${process.env.REACT_APP_API_URL}/api/venues/${id}`);
        setVenue(response.data);
        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching venue:', error);
        setError(error.response?.data?.message || 'An error occurred while fetching the venue.');
        setLoading(false);
      }
    };

    fetchVenue();
  }, [id]);

  if (loading) {
    return <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}><CircularProgress /></Container>;
  }

  if (error) {
    return <Container><Typography color="error">{error}</Typography></Container>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          {venue?.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <MapOutlined sx={{ mr: 1 }} />
          <Typography variant="h5" color="text.secondary">
            {venue?.location}
          </Typography>
        </Box>
        
        {venue?.description && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Description sx={{ mr: 1 }} />
            <Typography>{venue.description}</Typography>
          </Box>
        )}

        {venue?.website && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Language sx={{ mr: 1 }} />
            <Typography component="a" href={venue.website} target="_blank">
              {venue.website}
            </Typography>
          </Box>
        )}
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EventSeat sx={{ mr: 1 }} />
                <Typography variant="h6">Capacity Details</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <AccessibilityNew sx={{ fontSize: 40, color: 'primary.main' }} />
                    <Typography variant="h4">{venue?.capacityDetails?.standing || 0}</Typography>
                    <Typography>Standing</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Weekend sx={{ fontSize: 40, color: 'primary.main' }} />
                    <Typography variant="h4">{venue?.capacityDetails?.seating || 0}</Typography>
                    <Typography>Seated</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <WheelchairPickupRounded sx={{ fontSize: 40, color: 'primary.main' }} />
                    <Typography variant="h4">{venue?.capacityDetails?.wheelchair || 0}</Typography>
                    <Typography>Wheelchair</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Engineering sx={{ mr: 1 }} />
                <Typography variant="h6">Technical Specifications</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <Weekend sx={{ mr: 1 }} />
                      Stage Dimensions
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>Width: {venue?.technical?.stage?.width}m</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Depth: {venue?.technical?.stage?.depth}m</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Height: {venue?.technical?.stage?.height}m</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <VolumeUp sx={{ mr: 1 }} />
                      Sound System
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {venue?.technical?.sound?.inHousePA && (
                        <Typography>• In-house PA Available</Typography>
                      )}
                      <Typography>• {venue?.technical?.sound?.monitors || 0} Monitors</Typography>
                      <Typography>• {venue?.technical?.sound?.notes}</Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <Lightbulb sx={{ mr: 1 }} />
                      Lighting
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {venue?.technical?.lighting?.basic && (
                        <Typography>• Basic lighting system available</Typography>
                      )}
                      <Typography>• {venue?.technical?.lighting?.notes}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MeetingRoomOutlined sx={{ mr: 1 }} />
                <Typography variant="h6">Facilities</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {venue?.facilities?.greenRoom && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<MeetingRoom />} label="Green Room" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
                {venue?.facilities?.wifi && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<Wifi />} label="WiFi" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
                {venue?.facilities?.shower && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<Shower />} label="Shower" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
                {venue?.facilities?.bathroom && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<Wc />} label="Private Bathroom" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
                {venue?.facilities?.catering && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<Restaurant />} label="Catering" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
                {venue?.facilities?.parking && (
                  <Grid item xs={6} md={4}>
                    <Chip icon={<LocalParking />} label="Parking" color="primary" sx={{ width: '100%' }} />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Store sx={{ mr: 1 }} />
                <Typography variant="h6">Front of House</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {venue?.frontOfHouse?.bar && (
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalBar sx={{ mr: 1 }} />
                        <Typography>Bar Available</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                )}
                {venue?.frontOfHouse?.merchandise?.allowed && (
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Store sx={{ mr: 1 }} />
                        <Typography>Merchandise Sales Allowed</Typography>
                        <Typography sx={{ ml: 1 }}>({venue.frontOfHouse.merchandise.commission}% commission)</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <Person sx={{ mr: 1 }} />
              Contact Information
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ pl: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Person sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                <Typography>{venue?.contactInfo?.name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Email sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                <Typography>{venue?.contactInfo?.email}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Phone sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                <Typography>{venue?.contactInfo?.phone}</Typography>
              </Box>
            </Box>

            {venue?.contactInfo?.technical && (
              <>
                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                  <Engineering sx={{ mr: 1 }} />
                  Technical Contact
                </Typography>
                <Box sx={{ pl: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Person sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                    <Typography>{venue?.contactInfo?.technical.name}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Email sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                    <Typography>{venue?.contactInfo?.technical.email}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Phone sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                    <Typography>{venue?.contactInfo?.technical.phone}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                component={Link}
                to={`/admin/venues/${venue?._id}/edit`}
                variant="contained"
                fullWidth
                startIcon={<Engineering />}
              >
                Edit Venue
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                to="/admin/venues"
                variant="outlined"
                fullWidth
              >
                Back to Venues
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VenueDetail;