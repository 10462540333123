import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Alert,
  Box,
  Chip,
  Divider
} from '@mui/material';
import { 
  CalendarMonth,
  Event,
  Schedule,
  LocationOn,
  Person,
  MusicNote,
  Timer,
  DateRange,
  Groups,
  PendingActions,
  Warning,
  Engineering
} from '@mui/icons-material';
import { Performance, Event as EventType, Venue, BandRecommendation, Staff } from '../../types/mongodb';
import { format } from 'date-fns';


interface BandEventInfoProps {
  performance: Performance;
}

const BandEventInfo: React.FC<BandEventInfoProps> = ({ performance }) => {
  const eventCountdown = () => {
    if (!performance.startTime) return 'Date not set';
    const eventDate = new Date(performance.startTime).getTime();
    const currentDate = new Date().getTime();
    const daysLeft = Math.ceil((eventDate - currentDate) / (1000 * 60 * 60 * 24));
    
    if (daysLeft < 0) {
      return <Chip icon={<Warning />} label="Event has passed" color="error" />;
    }
    return <Chip icon={<Schedule />} label={`${daysLeft} days until event`} color="primary" />;
  };

  const getArtistName = () => {
    try {
      if (performance.simpleBandName) {
        return performance.simpleBandName;
      }
      
      const band = performance.band as BandRecommendation;
      if (!band) return 'Artist TBA';
      return typeof band === 'string' ? 'Unknown Band' : (band.artistName || 'Artist TBA');
    } catch (error) {
      console.error('Error getting artist name:', error);
      return 'Artist TBA';
    }
  };

  const getVenueName = (venue: string | Venue | undefined) => {
    try {
      if (!venue) return 'Venue TBA';
      if (typeof venue === 'string') return 'Venue details pending';
      return venue.name || 'Venue TBA';
    } catch (error) {
      console.error('Error getting venue name:', error);
      return 'Venue TBA';
    }
  };
  
  const getEventInfo = () => {
    try {
      let strandInfo = null;
      let eventInfo = null;

      // Handle Strand Information
      if (performance.strandId) {
        if (typeof performance.strandId === 'string') {
          strandInfo = (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarMonth sx={{ mr: 1, color: 'primary.main' }} />
                Strand ID: {performance.strandId}
              </Typography>
            </Box>
          );
        } else {
          strandInfo = (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarMonth sx={{ mr: 1, color: 'primary.main' }} />
                Strand: {performance.strandId.name || 'Name pending'}
              </Typography>
              {performance.strandId.startDate && performance.strandId.endDate && (
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <DateRange sx={{ mr: 1, color: 'primary.main' }} />
                  Period: {new Date(performance.strandId.startDate).toLocaleDateString()} - 
                        {new Date(performance.strandId.endDate).toLocaleDateString()}
                </Typography>
              )}
            </Box>
          );
        }
      }

      // Handle Event Information
      const event = performance.event as EventType;
      if (event && typeof event !== 'string') {
        eventInfo = (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Event sx={{ mr: 1, color: 'primary.main' }} />
              Event: {event.name || 'Name pending'}
            </Typography>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
              Venue: {getVenueName(event.venue)}
            </Typography>
          </Box>
        );
      }

      // Return combined info
      return (
        <>
          {strandInfo}
          {eventInfo}
          <Box sx={{ mt: 2 }}>
            {eventCountdown()}
          </Box>
        </>
      );
    } catch (error) {
      console.error('Error getting event info:', error);
      return <Alert severity="warning">Event information unavailable</Alert>;
    }
  };

  const formatTime = (time: Date | string | undefined) => {
    try {
      if (!time) return 'TBA';
      const date = time instanceof Date ? time : new Date(time);
      if (isNaN(date.getTime())) return 'Invalid date';
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'TBA';
    }
  };

  const renderStaff = () => {
    try {
      if (!performance.staff || !Array.isArray(performance.staff) || performance.staff.length === 0) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
            <Groups sx={{ mr: 1 }} />
            <Typography>No staff assigned yet</Typography>
          </Box>
        );
      }
  
      return (
        <List>
          {performance.staff.map((staffAssignment: any, index) => {
            try {
              // Handle populated staff data
              const staffData = {
                _id: staffAssignment.staffId?._id || staffAssignment.staffId,
                name: staffAssignment.staffId?.name || 'Name pending',
                role: staffAssignment.assignedRole || 'Role pending'
              };
  
              return (
                <ListItem key={staffData._id.toString()}>
                  <Person sx={{ mr: 1, color: 'primary.main' }} />
                  <ListItemText 
                    primary={staffData.name}
                    secondary={staffData.role}
                    primaryTypographyProps={{
                      variant: 'body1',
                      fontWeight: 'medium'
                    }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                      color: 'text.secondary'
                    }}
                  />
                </ListItem>
              );
            } catch (error) {
              console.error('Error rendering staff member:', error);
              return null;
            }
          }).filter(Boolean)}
        </List>
      );
    } catch (error) {
      console.error('Error rendering staff list:', error);
      return <Alert severity="warning">Unable to display staff information</Alert>;
    }
  };

  if (!performance) {
    return (
      <Card>
        <CardContent>
          <Alert severity="error">No performance data available</Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <MusicNote sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h4">
              {getArtistName()}
            </Typography>
          </Box>
          <Typography 
            variant="h5" 
            color="text.secondary"
            sx={{ ml: 4 }} // This aligns with the artist name after the icon
          >
            {format(new Date(performance.startTime), 'do MMMM yyyy')}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        
        {getEventInfo()}

        <Divider sx={{ my: 2 }} />

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Timer sx={{ mr: 1, color: 'primary.main' }} />
            Start Time: {formatTime(performance.startTime)}
          </Typography>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Timer sx={{ mr: 1, color: 'primary.main' }} />
            End Time: {formatTime(performance.endTime)}
          </Typography>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <PendingActions sx={{ mr: 1, color: 'primary.main' }} />
            Status: {performance.status || 'Status pending'}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />
        
        <Box>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Engineering sx={{ mr: 1, color: 'primary.main' }} />
            Assigned Staff
          </Typography>
          {renderStaff()}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BandEventInfo;