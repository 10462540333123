import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

interface QuoteCardProps {
  quote: {
    quote: string;
    author: string;
  };
  colorIndex?: number;
}

// Array of color themes - each with a background and accent color
const colorThemes = [
  { bg: '#336076', accent: '#FFD700' },   // Original blue with gold
  { bg: '#2D5744', accent: '#FF9B9B' },   // Forest green with coral
  { bg: '#614B79', accent: '#98FF98' },   // Purple with mint
  { bg: '#855A40', accent: '#87CEEB' },   // Warm brown with sky blue
  { bg: '#76424E', accent: '#98FB98' },   // Burgundy with pale green
  { bg: '#364B59', accent: '#FFA07A' },   // Navy with light salmon
  { bg: '#6B4423', accent: '#ADD8E6' },   // Deep brown with light blue
  { bg: '#4A5D46', accent: '#FFB6C1' }    // Olive with light pink
];

const QuoteCard: React.FC<QuoteCardProps> = ({ quote, colorIndex }) => {
  // Use provided index or generate random one
  const themeIndex = colorIndex ?? Math.floor(Math.random() * colorThemes.length);
  const theme = colorThemes[themeIndex];

  return (
    <Card 
      sx={{ 
        height: '95%',
        minHeight: '250px',
        backgroundColor: theme.bg,
        background: `linear-gradient(145deg, ${theme.bg}, ${theme.bg}cc)`,
        backdropFilter: 'blur(10px)',
        color: '#fff',
        padding: 1,
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '0.75rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.bg}66`,
        transition: 'all 0.3s ease'
      }}
    >
      <CardContent sx={{ 
        position: 'relative',
        width: '100%',
        py: 1,
        '&:last-child': { pb: 1 }
      }}>
        <Box sx={{
          position: 'relative',
          px: 4,
          my: 2
        }}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontFamily: 'serif',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              color: theme.accent,
              fontSize: '3.5rem',
              opacity: 0.8,
              textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            "
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              fontStyle: 'italic',
              fontSize: '1.125rem',
              lineHeight: 1.6,
              textAlign: 'center',
              my: 2,
              color: '#fff',
              textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
              px: 2
            }}
          >
            {quote.quote}
          </Typography>
          
          <Typography 
            variant="h4" 
            sx={{ 
              fontFamily: 'serif',
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              color: theme.accent,
              fontSize: '3.5rem',
              opacity: 0.8,
              textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            "
          </Typography>
        </Box>
        
        <Typography 
          variant="subtitle2" 
          sx={{ 
            textAlign: 'right',
            mt: 2,
            fontSize: '0.9rem',
            opacity: 0.9,
            pr: 2,
            color: theme.accent,
            fontWeight: 300,
            textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
          }}
        >
          - {quote.author}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default QuoteCard;