import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Collapse,
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'lucide-react';
import TaskCard from '../core/TaskCard';
import { Task, Subtask } from '../types/taskTypes';

interface ListViewProps {
  tasks: Task[];
  groupBy: 'none' | 'category' | 'assignedTo' | 'priority' | 'status';
  groupedTasks: Record<string, Task[]>;
  expandedGroups: Set<string>;
  toggleGroupExpansion: (groupKey: string) => void;
  onTaskMove: (result: DropResult) => void;
  onEditTask: (taskId: string) => void;
  onDeleteTask: (taskId: string) => void;
  onRefresh?: () => Promise<void>;
  onTaskUpdate: (taskId: string, updates: Partial<Task>) => Promise<Task>;
  onSubtaskUpdate: (taskId: string, subtaskId: string, updates: Partial<Subtask>) => Promise<Task>;
}

const ListView: React.FC<ListViewProps> = ({
  tasks,
  groupBy,
  groupedTasks,
  expandedGroups,
  toggleGroupExpansion,
  onTaskMove,
  onEditTask,
  onDeleteTask,
  onRefresh,
  onTaskUpdate,
  onSubtaskUpdate
}) => {
  if (groupBy === 'none') {
    return (
      <Box>
        <Droppable droppableId="task-list">
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              spacing={2}
            >
              {tasks.length === 0 ? (
                <Box 
                  sx={{ 
                    textAlign: 'center', 
                    py: 8,
                    bgcolor: 'background.paper',
                    borderRadius: 1
                  }}
                >
                  <Typography color="text.secondary">
                    No tasks found. Create a new task to get started.
                  </Typography>
                </Box>
              ) : (
                tasks.map((task, index) => (
                  <TaskCard
                    key={task.id}
                    task={task}
                    index={index}
                    onEdit={() => onEditTask(task.id)}
                    onDelete={() => onDeleteTask(task.id)}
                    onRefresh={onRefresh}
                    onTaskUpdate={onTaskUpdate}
                    onSubtaskUpdate={onSubtaskUpdate}
                  />
                ))
              )}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </Box>
    );
  }

  return (
    <Stack spacing={2}>
      {Object.entries(groupedTasks).map(([groupKey, groupTasks]) => (
        <Box 
          key={groupKey} 
          sx={{ 
            bgcolor: 'background.paper',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          {/* Group Header */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={() => toggleGroupExpansion(groupKey)}
            sx={{
              p: 2,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <IconButton size="small" sx={{ p: 0 }}>
              {expandedGroups.has(groupKey) ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronRight size={20} />
              )}
            </IconButton>
            <Typography variant="h6">
              {groupKey}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ({groupTasks.length})
            </Typography>
          </Stack>

          {/* Group Content */}
          <Collapse in={expandedGroups.has(groupKey)}>
            <Box sx={{ px: 2, pb: 2 }}>
              <Droppable droppableId={`group-${groupKey}`}>
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    spacing={2}
                  >
                    {groupTasks.map((task, index) => (
                      <TaskCard
                        key={task.id}
                        task={task}
                        index={index}
                        onEdit={() => onEditTask(task.id)}
                        onDelete={() => onDeleteTask(task.id)}
                        onRefresh={onRefresh}
                        onTaskUpdate={onTaskUpdate}
                        onSubtaskUpdate={onSubtaskUpdate}
                      />
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </Box>
          </Collapse>
        </Box>
      ))}
    </Stack>
  );
};

export default ListView;